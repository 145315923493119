import { Listener } from '@bingads-webui/listener';

let logCount = 0;
/**
 * Local storage listener class. This logs to Local Storage using
 * the Storage API.
 */

export class LocalStorageListener extends Listener {
  constructor(options = {}) {
    super(options);

    this.formatters = options.formatters || [];
    this.filters = options.filters || [];
    this.uniquePageId = options.uniquePageId;
    this.storage = window.localStorage;
  }

  init() {
    if (this.storage && this.uniquePageId) {
      this.initialized = true;
    }
  }

  write(logMessage) {
    if (!this.initialized || this.filterLogs(logMessage)) {
      return;
    }

    const formattedMsg = this.formatLogs(logMessage);

    const key = this.generateKey();

    try {
      this.storage.setItem(key, JSON.stringify(formattedMsg));
      logCount += 1;
    } catch (e) {
      this.logError(e);
    }
  }

  /**
   * Generate key to use when putting log in local storage.
   * It uses the string unique page Id and total count of logs
   * written already to determine the key
   * @private
   * @returns {undefined} - no returns
   */
  generateKey() {
    return `localStorageListener.${this.uniquePageId}.${logCount}`;
  }

  logError(e) {
    console.error(e); // eslint-disable-line no-console
  }
}
