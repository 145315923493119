
import _ from 'underscore';

const functionWithPerfMarker = function (perfMarker, func, context) {
  if (!_.isFunction(func)) {
    return null;
  }

  const newFunc = function (...args) {
    perfMarker.willInit();

    const result = func.apply(context || this, args);

    perfMarker.done();

    return result;
  };

  return newFunc;
};

const asyncFunctionWithPerfMarker = function (perfMarker, asyncFunc, context) {
  if (!_.isFunction(asyncFunc)) {
    return null;
  }

  const newAsyncFunc = function (...args) {
    perfMarker.willInit();

    return asyncFunc.apply(context || this, args).then((result) => {
      perfMarker.done();

      return result;
    });
  };

  return newAsyncFunc;
};

export { functionWithPerfMarker, asyncFunctionWithPerfMarker };
