import { TraceLogger } from './loggers/trace-logger';
import { PerfLogger } from './loggers/perf-logger';
import { ErrorLogger } from './loggers/error-logger';
import { AjaxLogger } from './loggers/ajax-logger';
import { ScenarioProcessor } from './processors/scenario-processor';
import { SubstrateLogger } from './loggers/substrate-logger';

export function getDefaultConfig() {
  // default loggers
  const traceLogger = new TraceLogger();
  const perfLogger = new PerfLogger();
  const errorLogger = new ErrorLogger();
  const ajaxLogger = new AjaxLogger();
  const substrateLogger = new SubstrateLogger();

  // default processors
  const scenarioProcessor = new ScenarioProcessor();

  return {
    isDebugMode: false,
    loggers: {
      traceLogger, errorLogger, perfLogger, ajaxLogger, substrateLogger,
    },
    processors: { scenarioProcessor },
    listeners: {},
  };
}

