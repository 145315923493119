import _ from 'underscore';
import { Formatter } from '@bingads-webui/listener';
import { getParameter, updateParameter } from '@bingads-webui/url-util';
import { getUtcTime } from '@bingads-webui-universal/primitive-utilities';
import { safeParseInteger } from './format-utilities';

export class BasicFormatter extends Formatter {
  constructor(options = {}) {
    super();
    this.applicationSystem = options.applicationSystem;
    this.applicationSubSystem = options.applicationSubSystem;
  }

  getPFlagsProps(rawLog) {
    let pflags = '';

    if (_.isString(rawLog.api)) {
      let apiString = rawLog.api.trim();
      // hide all token / code related values due to privacy issue.
      if (getParameter('authCode', apiString)) {
        apiString = updateParameter('authCode', 'authCodePlaceholder', apiString);
      } else if (getParameter('accessToken', apiString)) {
        apiString = updateParameter('accessToken', 'accessTokenPlaceholder', apiString);
      }
      pflags += `rUrl:${apiString};`;
    }
    if (rawLog.parentScenarioName && rawLog.parentScenarioId) {
      pflags += `pScenario:${rawLog.parentScenarioName};pScenarioId:${rawLog.parentScenarioId};`;
    }
    return pflags;
  }

  getEventType(rawLog) {
    if (rawLog.message && JSON.stringify(rawLog.message).indexOf('perf-marker@') !== -1) {
      return 'perfmarker';
    }
    return rawLog.entryType;
  }

  format(rawLog) {
    const log = {
      Sn: rawLog.scenarioName || '',
      SceID: rawLog.scenarioId || '',
      RID: rawLog.requestId || '',
      Api: _.isString(rawLog.api) ? rawLog.api.trim().toLowerCase() : '',
      Pass: _.isUndefined(rawLog.pass) ? '' : rawLog.pass,
      Dur: safeParseInteger(rawLog.timeTaken),
      Ime: _.isUndefined(rawLog.isMethodEnter) ? '' : rawLog.isMethodEnter,
      Hm: rawLog.httpMethod || '',
      Timestamp: rawLog.timeStamp || getUtcTime(),
      SesID: rawLog.sessionId || '',
      Url: (window.location.pathname || '') + (window.location.search || '') + (window.location.hash || ''),
      UID: rawLog.userId || '',
      CID: rawLog.customerId || '',
      AID: rawLog.accountId || '',
      LCID: rawLog.lcid || '',
      Mes: JSON.stringify(rawLog.message),
      Lc: _.isArray(rawLog.logCategory) ?
        rawLog.logCategory.join(',') : rawLog.logCategory,
      Ll: rawLog.logLevel ? rawLog.logLevel.name : '',
      Env: window.location.hostname,
      As: this.applicationSystem || '',
      Asub: this.applicationSubSystem || '',
      An: rawLog.activityName || '',
      ActID: rawLog.activityId || '',
      Et: this.getEventType(rawLog) || '',
      Loc: rawLog.market || '',
      Role: rawLog.userRole || '',
      MgdBy: rawLog.managedBy || '',
      PFlags: this.getPFlagsProps(rawLog) || '',
      Iu: rawLog.impactUser || null,
      Ec: safeParseInteger(rawLog.entityCount),
      UserAgent: (window.navigator || {}).userAgent || '',
      PgID: rawLog.pageTrackingId || '',
      PgVer: rawLog.pageVersion || '',
      DbgMode: rawLog.isDebugMode || false,
    };

    return log;
  }
}
