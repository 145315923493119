export const InlineEditorStyle = () => ({
  '.custom-parameters-inline-edit-popup': {
    width: 456,
    minWidth: 456,
  },

  '.grid-popupeditor .form-inline .form-group': {
    display: 'block',
  },
});
