import _ from 'underscore';
import { AjaxInterceptor } from './ajax-interceptor';
import { defaultConfig } from './default-config';
import { schema } from './ajax-stub-schema';

/**
 * AjaxStub class - Creates an ajax stub that can be used by Instrumentation to stub ajax calls
 */
export class AjaxStub {
  /**
   * @param {object} options - contains instrumentation and optionally stubOptions, AjaxEventhandler
   */
  constructor(options = {}) {
    this.stubOptions = _.defaults(options.stubOptions || {}, defaultConfig.stubOptions);
    this.handler = options.eventHandler || defaultConfig.eventHandler;

    // create scenario for logging
    this.setupScenario(options.instrumentation);

    this.interceptor = new AjaxInterceptor(this.stubOptions, this.handler, this.ajaxStubScenario);
  }

  init() {
    _.each(this.stubOptions.objectsToStub, (objectToStub) => {
      this.interceptor.intercept(objectToStub);
    });
  }

  setupScenario(instr) {
    instr.addScenario(schema);
    this.ajaxStubScenario = instr.ajaxStubScenario.create(instr.ScenarioContext);
  }
}
