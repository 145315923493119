/**
 * @file
 * AdInsight Opportunity Log Spec:
 * https://microsoft.sharepoint.com/:w:/r/teams/stcaadinsights/Shared%20Documents/AdvertiserIntelligence/Log%20Requirements.docx?d=w0e50147bfce943a6a1325bfb995c9902&csf=1&e=aZkNhs
 */

import _ from 'underscore';
import { Formatter } from '@bingads-webui/listener';

export class AdInsightOpportunityLogFormatter extends Formatter {
  format(rawLog) {
    let clientName = 'CampaignWebUI';
    let environment;
    let Action;
    const logApi = _.result(rawLog, 'api');
    const channel = _.result(rawLog.message, 'channel');
    const logApiClientNameMapper = {
      OverviewOpportunityTile: 'Opportunities[Overview]',
      MCCOverviewOpportunityTile: 'bingadsweb[mccoverviewpage]',
    };

    if (_.has(logApiClientNameMapper, logApi)) {
      clientName = _.result(logApiClientNameMapper, logApi);
    } else if (!_.isUndefined(channel)) {
      clientName = `bingadsweb[${channel}]`;
    }

    if (document.domain === 'ui.bingads.microsoft.com' || document.domain === 'ui.ads.microsoft.com') {
      environment = 'Production';
    } else if (document.domain === 'ui.si.bingads.microsoft.com' || document.domain === 'ui.ads-int.microsoft.com') {
      environment = 'SI';
    } else {
      environment = document.domain;
    }

    if (rawLog.message.action) {
      Action = rawLog.message.action;
    } else {
      Action = rawLog.message.isClick ? 'Click' : 'View';
    }

    const isMCC = clientName === 'bingadsweb[mccrecommendationpage]';

    return {
      RequestId: rawLog.requestId,
      OpportunityId: rawLog.message.id,
      OpportunityType: rawLog.message.type,
      OpportunityTypeId: rawLog.message.typeId,
      Action,
      UserId: rawLog.userId,
      CustomerId: rawLog.customerId,
      AccountId: isMCC
        ? rawLog.message.accountId || null
        : rawLog.accountId,
      CampaignId: rawLog.message.campaignId,
      ClientName: clientName,
      Environment: environment,
      Source: document.URL,
      GUID: rawLog.message.guid,
      Position: rawLog.message.position,
      UserFeedback: rawLog.message.input,
      Context: rawLog.message.context,
    };
  }
}
