export const InstrumentationSchema = {
  scenario: [
    {
      name: 'appScenario',
      value: 'app scenario',
      activity: [
        {
          name: 'appActivity',
          value: 'app Activity',
        },
      ],
    },
    {
      name: 'preInstrumentationInitializeScenario',
      value: 'pre instrumentation start',
      activity: [
        {
          name: 'perfActivity',
          value: 'perf activity',
        },
      ],
    },
    {
      name: 'globalSearchScenario',
      value: 'global search scenario',
      activity: [
        {
          name: 'searchActivity',
          value: 'search Activity',
        },
      ],
    },
    {
      name: 'datePicker',
      value: 'datePicker',
      activity: [
        {
          name: 'dateRangePicked',
          value: 'dateRangePicked',
        },
      ],
    },
    {
      name: 'mainMenuScenario',
      value: 'main menu scenario',
      activity: [
        {
          name: 'changeScopeActivity',
          value: 'change scope Activity',
        },
        {
          name: 'toggleMainMenuActivity',
          value: 'toggle mainMenu Activity',
        },
      ],
    },
    {
      name: 'summaryBarScenario',
      value: 'summary bar scenario',
      activity: [
        {
          name: 'changeScopeActivity',
          value: 'change scope Activity',
        },
        {
          name: 'summaryBarBudgetActivity',
          value: 'update campaign summary bar budget Activity',
        },
        {
          name: 'summaryBarBidActivity',
          value: 'update ad group summary bar bid Activity',
        },
        {
          name: 'summaryBarExperimentCampaignActivity',
          value: 'update experiment campaign summary bar items Activity',
        },
      ],
    },
    {
      name: 'globalMenuEntityBarScenario',
      value: 'global menu entity bar scenario',
      activity: [
        {
          name: 'changeScopeActivity',
          value: 'change scope Activity',
        },
      ],
    },
    {
      name: 'newUINavigation',
      value: 'new UI navigation',
      activity: [
        'preferenceSet',
      ],
    },
    {
      name: 'urlChange',
      value: 'url change',
      activity: [
        'routeChange',
        'paramChange',
      ],
    },
    {
      name: 'feedbackScenario',
      value: 'feedback scenario',
      activity: [
        'feedbackActivity',
      ],
    },
    {
      name: 'globalMenuScenario',
      value: 'global menu scenario',
      activity: [
        'globalMenuActivity',
      ],
    },
    {
      name: 'newUIShortcuts',
      value: 'new UI shortcuts',
      activity: [
        'shortcutSet',
      ],
    },
    {
      name: 'nkllPage',
      value: 'nkllPage',
      activity: [
        'nkllPageLifeCycle',
      ],
    },
    {
      name: 'newUISmartShoppingCampaignCreationScenario',
      value: 'newUISmartShoppingCampaignCreationScenario',
      activity: [
        'SSCCreationActivity',
      ],
    },
    {
      name: 'newUICreateCampaignImportIntegration',
      value: 'newUICreateCampaignImportIntegration',
      activity: [
        'createCampaign',
        'importIntegration',
      ],
    },
  ],
};
