import _ from 'underscore';
import { merge, cloneDeep } from '@bingads-webui-universal/primitive-utilities';
import React from 'react';
import PropTypes from 'prop-types';
import { layoutConfigs } from './layout-configs';
import { LayoutContext } from './layout-context';
import { StylesContainer } from './styles-container';
import { names } from './components';
import { Mode } from './mode';

export class LayoutContainer extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    persistConfig: PropTypes.objectOf(PropTypes.shape({
      mode: PropTypes.oneOf(_.values(Mode)),
    })),
    pageConfig: PropTypes.objectOf(PropTypes.shape({
      mode: PropTypes.oneOf(_.values(Mode)),
    })),
    controlledByProps: PropTypes.bool,
  };

  static defaultProps = {
    persistConfig: layoutConfigs.default,
    pageConfig: layoutConfigs.default,
    controlledByProps: false,
  };

  constructor(props) {
    super(props);

    this.stylesContainer = new StylesContainer(props.persistConfig);
    this.state = {
      persistConfig: props.persistConfig,
      pageConfig: props.pageConfig,
      controlledByProps: props.controlledByProps,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.controlledByProps) {
      return null;
    }
    if (prevState.ownUpdate) {
      // getDerivedStateFromProps is triggered before each render.
      // need to ensure that we dont override changes from its own update
      return { ownUpdate: false };
    } else if (!_.isEqual(nextProps.pageConfig, prevState.pageConfig)) {
      return { pageConfig: nextProps.pageConfig };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.state.pageConfig, prevState.pageConfig)) {
      this.updatePage();
    }
  }

  changeLayout = (
    updates,
    isPageConfig,
    isPageConfigPartialUpdate = false,
    afterChangeLayout = _.noop
  ) => {
    if (_.find(updates, (item, key) => !_.has(names, key))) {
      throw new Error('Invalid layout configs');
    }

    if (isPageConfig) {
      this.setState((state) => {
        const pageConfig = isPageConfigPartialUpdate ?
          merge(cloneDeep(state.pageConfig), updates) :
          merge(cloneDeep(state.persistConfig), updates);
        return {
          pageConfig: _.isEqual(pageConfig, state.pageConfig) ? state.pageConfig : pageConfig,
          ownUpdate: true,
        };
      }, () => {
        this.updatePage(afterChangeLayout);
      });
    } else {
      this.setState((state) => {
        const persistConfig = merge(cloneDeep(state.persistConfig), updates);
        const pageConfig = merge(cloneDeep(state.pageConfig), updates);
        return {
          persistConfig: _.isEqual(persistConfig, state.persistConfig) ?
            state.persistConfig :
            persistConfig,
          pageConfig: _.isEqual(pageConfig, state.pageConfig) ? state.pageConfig : pageConfig,
          ownUpdate: true,
        };
      }, () => {
        this.updatePage(afterChangeLayout);
      });
    }
  };

  updatePage(afterChangeLayout = _.noop) {
    this.stylesContainer.update(this.state.pageConfig);
    afterChangeLayout();
  }

  render() {
    return (
      <LayoutContext.Provider value={{
        config: this.state.pageConfig,
        styles: this.stylesContainer.styles,
        change: this.changeLayout,
      }}
      >
        {this.props.children}
      </LayoutContext.Provider>
    );
  }
}
