import _ from 'underscore';
import Globalize from 'globalize';
import core from '@bingads-webui-universal/i18n-cldr-data-modern/core.json';
import enLocale from '@bingads-webui-universal/i18n-cldr-data-modern/locales/en.json';
import { I18nModel } from '@bingads-webui-universal/i18n-model';
import { ModuleI18nShim } from './shims/module-i18n-shim';
import { ComponentI18nShim } from './shims/component-i18n-shim';

const currency = 'USD';
const locale = 'en-US';
I18nModel.setGlobalLocale(locale);

Globalize.load(core, enLocale);
// eslint-disable-next-line
Globalize.loadTimeZone(require('iana-tz-data')); // JSON data

export const modelI18nMock = ({ dictionary = {}, options = {} } = {}) =>
  // Initializeing the I18nModel in the synchronous mode
  new I18nModel(_.extendOwn({
    locale,
    loadCLDRData: () => locale,
    loadTranslationData: () => dictionary,
    currency,
  }, options));

// mocks 'component/i18n/loader'
export const modelComponentI18nMock = ({ dictionary = {}, options = {} } = {}) =>
  new ComponentI18nShim(modelI18nMock({ dictionary, options }));

// mocks 'module/extension/requirejs/i18n'
export const modelModuleI18nMock = ({ dictionary = {}, options = {} } = {}) =>
  new ModuleI18nShim(modelI18nMock({ dictionary, options }));

// Generates the object to mock such calls:
// var i18n = window.globalLegacyI18n.get('component/i18n/loader!component/upgraded-url');
//
// Usage example:
// const globalLegacyI18n = modelGlobalLegacyI18n({ options: { failFast: 'SAFE' }, dictionary: strings });
// window.globalLegacyI18n = globalLegacyI18n;
// ...
// export const StyledApp = withI18n(withDefaultStyles(App, getAppStyles), globalLegacyI18n.main);
export const modelGlobalLegacyI18n = ({ dictionary = {}, options = {} } = {}) => (modelI18n => ({
  get(i18nRequireRequest) {
    const loaderName = i18nRequireRequest.split('!')[0];
    if (loaderName === 'component/i18n/loader') {
      return new ComponentI18nShim(modelI18n);
    } else if (loaderName === 'module/extension/requirejs/i18n') {
      return new ModuleI18nShim(modelI18n);
    }
    return modelI18n;
  },

  main: modelI18n,
}))(modelI18nMock({ dictionary, options }));
