import _ from 'underscore';
import { Filter } from '@bingads-webui/listener';

const campaignEnvs = [
  'ui.bingads.microsoft.com',
  'ui.tip.bingads.microsoft.com',
  'ui.si.bingads.microsoft.com',
  'ui.ads.microsoft.com',
  'ui.tip.ads.microsoft.com',
  'ui.ads-int.microsoft.com',
  'adlibraryui-test.azureedge.net',
  'adlibrary.ads.microsoft.com',
];

const signupEnvs = [
  'bingads.microsoft.com',
  'tip.bingads.microsoft.com',
  'si.bingads.microsoft.com',
  'ads.microsoft.com',
  'tip.ads.microsoft.com',
  'ads-int.microsoft.com',
];

const allowedEnvs = [...campaignEnvs, ...signupEnvs];

const exlcudeResources = [
  '/campaign/API/Updates/get',
  '/root/keepalive',
  'aria.microsoft.com',
  'liveperson.net',
];

export class PerformanceLogsFilter extends Filter {
  check(log) {
    if (!_.contains(allowedEnvs, document.domain) || log.isDebugMode) {
      return true;
    }

    if (log.isPageNavigationLog) {
      return false;
    }

    const resourceName = log.name;

    return _.any(exlcudeResources, excludedResource => resourceName && resourceName.search(new RegExp(excludedResource, 'i')) > 0);
  }
}
