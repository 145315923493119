import { createTheme, Depths } from '@fluentui/react';

export const DarkTheme = createTheme({
  palette: {
    themePrimary: '#88b0cf',
    themeLighterAlt: '#050708',
    themeLighter: '#161c21',
    themeLight: '#29353e',
    themeTertiary: '#526a7c',
    themeSecondary: '#789bb6',
    themeDarkAlt: '#93b7d3',
    themeDark: '#a2c2da',
    themeDarker: '#b8d1e4',
    neutralLighterAlt: '#201f1e',
    neutralLighter: '#2A2A29',
    neutralLight: '#30302F',
    neutralQuaternaryAlt: '#1c1b1b',
    neutralQuaternary: '#1b1a19',
    neutralTertiaryAlt: '#1a1918',
    neutralTertiary: '#f5f9fd',
    neutralSecondary: '#f6fafe',
    neutralPrimaryAlt: '#f8fbfe',
    neutralPrimary: '#eff6fc',
    neutralDark: '#fbfdfe',
    black: '#fdfeff',
    white: '#201f1e',
  },

  effects: {
    roundedCorner2: '2px',
    elevation4: Depths.depth4,
    elevation8: Depths.depth8,
    elevation16: Depths.depth16,
    elevation64: Depths.depth64,
  },
});

DarkTheme.iconFontFamily = 'MsAds MDL2 Assets';
