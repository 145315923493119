import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { UiBlocker } from '@bingads-webui-react/ui-blocker';
import { I18nContext } from '@bingads-webui-react/i18n-context';

import { useUpdateChildPerfMarker } from './hooks';

export const PerfUiBlocker = (props) => {
  const {
    perfMarker,
    isReady,
  } = props;
  const i18n = useContext(I18nContext);
  const isLoading = !isReady;
  useUpdateChildPerfMarker(perfMarker, isLoading);

  return (
    <UiBlocker
      blocking={isLoading}
      loadingText={i18n.getString(_TL_('Loading...'))}
    >
      {(isLoading && props.hideChildren) ?
        <div style={{
          // This is only for legacy UI, so the UI blocker has space to render
          height: 200,
        }}
        /> : props.children
      }
    </UiBlocker>
  );
};

PerfUiBlocker.propTypes = {
  perfMarker: PropTypes.shape({
    done: PropTypes.func.isRequired,
  }).isRequired,
  isReady: PropTypes.bool.isRequired,
  hideChildren: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

PerfUiBlocker.defaultProps = {
  hideChildren: false,
};
