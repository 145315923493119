/**
 * Base Logger
 */
export class Logger {
  /**
   * log - transmits logs to the log transmitter
   * @param {object} rawLogs - raw logs to be transmitted
   * @returns {undefined} - no returns
   */
  log(rawLogs) {
    this.transmitter.transmit(rawLogs);
  }

  getLogMethods() {
    throw new Error('Not Implemented');
  }

  set logTransmitter(logTransmitter) {
    this.transmitter = logTransmitter;
  }
}
