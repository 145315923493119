import _ from 'underscore';

export function checkKey(key) {
  return !_.isString(key);
}

export const safeGetString = (realI18n, key) => {
  if (key === '') {
    return '';
  }

  try {
    return realI18n.getString(key);
  } catch (e) {
    if (e.name === 'RangeError') {
      return undefined;
    }
    throw e;
  }
};
