/* eslint-disable function-paren-newline */
import 'bootstrap-webpack';
import '@bingads-webui/theme-2018/less/core/index.less';
import React, { lazy } from 'react';
import ReactDOM from 'react-dom';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import './assets/index.css';
import App from './App';

const StyledHomePage = lazy(() => import('./pages/home-page')
  .then(module => ({
    default: module.StyledHomePage,
  })));

const APIDocumentPage = lazy(() => import('@bingads-webui-adlibrary/api-document-page')
  .then(module => ({
    default: module.APIDocumentPage,
  })));

const StyledAdDetailsPage = lazy(() => import('./pages/ad-details-page')
  .then(module => ({
    default: module.StyledAdDetailsPage,
  })));

const StyledAdvertiserAdsPage = lazy(() => import('./pages/advertiser-ads-page')
  .then(module => ({
    default: module.StyledAdvertiserAdsPage,
  })));

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <StyledHomePage />,
      },
      {
        path: '/api',
        element: <APIDocumentPage />,
      },
      {
        path: '/ad/:id',
        element: <StyledAdDetailsPage />,
      },
      {
        path: '/advertiser/:id',
        element: <StyledAdvertiserAdsPage />,
      },
    ],
  },
]);

ReactDOM.render(
  <RouterProvider router={router} />,
  document.getElementById('root')
);
