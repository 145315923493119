import {
  ContextProcessor,
  DebugProcessor,
  PerfTimingProcessor
} from '@bingads-webui-campaign/instrumentation';

export function getProcessors({
  isInDevelopmentMode,
  pageTrackingId,
  userContext,
  serverContext,
}) {
  const pageContext = serverContext || Object.assign({}, userContext, {
    Version: '0.0.0',
    PageTrackingId: pageTrackingId,
  });
  const contextProcessor = new ContextProcessor({ pageContext });
  const debugProcessor = new DebugProcessor({
    isDebugMode: isInDevelopmentMode,
  });
  const perfTimingProcessor = new PerfTimingProcessor({
    landingPageUrl: window.location.href,
  });

  const processors = {
    contextProcessor,
    debugProcessor,
    perfTimingProcessor,
  };

  return processors;
}
