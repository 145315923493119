/* eslint-disable no-param-reassign */
import { Formatter } from '@bingads-webui/listener';

export class ImportApiFormatter extends Formatter {
  format(log) {
    if (log.Api.indexOf('api/v1/bingads/hasplaceholderstores') !== -1) {
      // remove extra customer id appended after this api to reduce false alarm for api availability
      log.Api = log.Api.substring(0, log.Api.indexOf('hasplaceholderstores') + 20);
    }

    return log;
  }
}
