import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { getDisplayName } from '@bingads-webui-react/hoc-utils';

export const PerformanceMarkerContext = React.createContext(null);

export const withPerformanceMarker = Component =>
  hoistNonReactStatics(class extends React.PureComponent {
    static displayName = `withPerformanceMarker(${getDisplayName(Component)})`;

    render() {
      return (
        <PerformanceMarkerContext.Consumer>
          {context => <Component perfMarker={context} {...this.props} />}
        </PerformanceMarkerContext.Consumer>
      );
    }
  }, Component);
