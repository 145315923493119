import _ from 'underscore';
import { AriaListener } from '@bingads-webui/aria-listener';
import { ConsoleListener } from '@bingads-webui/console-listener';
import { LocalStorageListener } from '@bingads-webui/local-storage-listener';
import { InMemoryListener, InMemorySink } from '@bingads-webui/in-memory-listener';
import {
  getAriaConfig,
  campaignLogTableConfig,
  adInsightLogTableConfig,
  BasicFormatter,
  ApiFormatter,
  AdInsightOpportunityLogFormatter,
  ReportingApiFormatter,
  ConsoleFormatter,
  LogLevelFilter,
  ApiFilter,
  AdInsightOpportunityApiFilter,
  ConsoleFilter,
  UnloadLogFilter,
  isTestEnvironment,
} from '@bingads-webui-campaign/instrumentation';

export function getListeners({
  pageTrackingId,
  applicationName,
  ariaConfig: applicationAriaConfig = {},
}) {
  const currentEnv = document.domain;
  const ariaConfig = {
    ...getAriaConfig(currentEnv),
    ...applicationAriaConfig,
  };

  const applicationSystem = applicationName && applicationName.systemName ?
    applicationName.systemName : 'campaign';
  const applicationSubSystem = applicationName && applicationName.subSystemName ?
    applicationName.subSystemName : 'webui';

  // init log formatters
  const basicFormatter = new BasicFormatter({
    applicationSystem,
    applicationSubSystem,
  });
  const apiFormatter = new ApiFormatter();
  const reportingApiFormatter = new ReportingApiFormatter();
  const consoleFormatter = new ConsoleFormatter();
  // init log filters
  const logLevelFilter = new LogLevelFilter();
  const apiFilter = new ApiFilter();
  const consoleFilter = new ConsoleFilter();
  const unloadLogFilter = new UnloadLogFilter();

  const localStorageListener = new LocalStorageListener({
    formatters: {},
    filters: {
      unloadLogFilter,
    },
    uniquePageId: pageTrackingId,
  });

  localStorageListener.init();

  const campaignAriaListener = new AriaListener({
    tenantToken: ariaConfig.token,
    flushInterval: ariaConfig.flushInterval,
    batchSize: ariaConfig.batchSize,
    logTableNames: campaignLogTableConfig.logTableNames,
    defaultLogCategory: campaignLogTableConfig.defaultLogCategory,
    formatters: {
      basicFormatter,
      apiFormatter,
      reportingApiFormatter,
    },
    filters: {
      logLevelFilter,
      apiFilter,
    },
  });

  campaignAriaListener.init();
  campaignAriaListener.registerForTeardown();

  const adInsightLogFormatter = new AdInsightOpportunityLogFormatter();
  const adInsightApiFilter = new AdInsightOpportunityApiFilter();

  const adInsightAriaListener = new AriaListener({
    tenantToken: ariaConfig.tokens.adInsightOppTracking,
    flushInterval: ariaConfig.flushInterval,
    batchSize: ariaConfig.batchSize,
    logTableNames: adInsightLogTableConfig.logTableNames,
    defaultLogCategory: adInsightLogTableConfig.defaultLogCategory,
    formatters: {
      adInsightLogFormatter,
    },
    filters: {
      adInsightApiFilter,
    },
  });

  adInsightAriaListener.init();
  adInsightAriaListener.registerForTeardown();

  const consoleListener = new ConsoleListener({
    filters: {
      consoleFilter,
    },
    formatters: {
      consoleFormatter,
    },
  });

  consoleListener.init();

  const listeners = {
    localStorageListener,
    campaignAriaListener,
    adInsightAriaListener,
    consoleListener,
  };

  if (isTestEnvironment(currentEnv)) {
    window.seleniumLogsSink = new InMemorySink({ maxSize: 1000 });
    const inMemoryListener = new InMemoryListener({
      sink: window.seleniumLogsSink,
      formatters: {
        basicFormatter,
        apiFormatter,
      },
      filters: {
        logLevelFilter,
        apiFilter,
      },
    });

    inMemoryListener.init();

    _.extend(listeners, { inMemoryListener });
  }

  return listeners;
}
