/**
 * Sink that is compatible with InMemoryListener.
 * Can be passed in to the listener to receive logs.
 */
export class InMemorySink {
  /**
   * Creates an instance of InMemorySink.
   * @param {Number} [maxSize=2000] - Maximum size of logs array.
   * Pass in -1 to have unbounded array.
   */
  constructor({ maxSize = 2000 } = {}) {
    this.logs = [];
    this.maxSize = maxSize;
  }

  /**
   * Write log to the sink
   * @param {Object} log - Log object to write
   * @returns {undefined} - no returns
   */
  write(log) {
    this.logs.push(log);

    while (this.maxSize > -1 && this.logs.length > this.maxSize) {
      this.logs.shift();
    }
  }

  /**
   * Getter for logs written to sink. Returns the
   * logs array.
   * @readonly
  */
  get items() {
    return this.logs;
  }
}
