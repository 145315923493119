/* eslint-disable no-param-reassign */
import { constants } from './../constants';
import { Logger } from './logger';

/**
 * Substrate Logger to log events for user behavior analysis
 * Key data entries include
 * Sn: scenario name
 * SceId: scenario id, this is re-generated whenever a scenario is created
 * An: activity name
 * ActId: activity id, this is re-generated when an activity is created
 * Mes: a custom message for the signal
 * Dur: time taken for the signal
 */
export class SubstrateLogger extends Logger {
  /**
   * startSignal - used to measure start of a signal
   * @param {object} scenario - scenario that defines the activity
   * @param {object} activity - activity that invoked the log method
   * @returns {undefined} - no returns
   */
  startSignal(scenario, activity) {
    if (!scenario || !activity) {
      return;
    }
    activity.startTime = new Date();
  }

  /**
   * stopSignal - used to measure stop of an activity
   * @param {object} scenario - scenario of activity that invoked log method
   * @param {object} activity - activity that invoked the log method
   * @param {object} parentScenario - parent of the scenario
   * @param {boolean} trackDuration - indicate if the signal needs to track time taken
   * @param {object} message - a custom message for the activity itself
   * @returns {undefined} - no returns
   */
  stopSignal(scenario, activity, parentScenario, [message, api, trackDuration = true]) {
    if (!scenario || !activity || (trackDuration && !activity.startTime)) {
      return;
    }

    const endTime = new Date();
    super.log({
      timeStamp: trackDuration ? activity.startTime.toJSON() : endTime.toJSON(),
      timeTaken: trackDuration ? (endTime - activity.startTime) : 0,
      logCategory: constants.LOGCATEGORY.SUBSTRATE,
      logLevel: constants.LOGLEVEL.INFO,
      entryType: constants.ENTRYTYPE.ACTIVITY,
      activity,
      scenario,
      parentScenario,
      message,
      api,
    });
  }

  /**
   * signal - an atomic signal that has start and stop paired automatically
   * @param {object} scenario - scenario of activity that invoked log method
   * @param {object} activity - activity that invoked the log method
   * @param {object} parentScenario - parent of the scenario
   * @param {object} message - a custom message for the activity itself
   * @returns {undefined} - no returns
   */
  signal(scenario, activity, parentScenario, [message, api]) {
    this.stopSignal(scenario, activity, parentScenario, [message, api, false]);
  }

  /**
   * withSignalSync - caller provides a function
   * start and stop are measure at start and stop of the function
   * @param {object} scenario - scenario of activity that invoked log method
   * @param {object} activity - activity that invoked the log method
   * @param {object} parentScenario - parent of the scenario
   * @param {object} action - caller provided function as the signal
   * action may return message as custom message
   * @returns {undefined} - no returns
   */
  withSignalSync(scenario, activity, parentScenario, [action]) {
    this.startSignal(scenario, activity);
    const actionReturn = action();
    this.stopSignal(
      scenario,
      activity,
      parentScenario,
      actionReturn ? [actionReturn.message, actionReturn.api] : []
    );
    return actionReturn;
  }

  /**
   * withSignalAsync - async version of withSignalAsync, caller provides a promise
   * start and stop are measure at start and stop of the prmoise
   * @param {object} scenario - scenario of activity that invoked log method
   * @param {object} activity - activity that invoked the log method
   * @param {object} parentScenario - parent of the scenario
   * @param {object} prmoise - caller provided promise as the signal
   * @returns {undefined} - no returns
   */
  withSignalAsync(scenario, activity, parentScenario, [promise]) {
    this.startSignal(scenario, activity);
    const onPromiseDone = (promiseReturn) => {
      this.stopSignal(
        scenario,
        activity,
        parentScenario,
        promiseReturn ? [promiseReturn.message, promiseReturn.api] : []
      );
    };

    promise.then(onPromiseDone, onPromiseDone);
    return promise;
  }

  getLogMethods() {
    return ['startSignal', 'stopSignal', 'signal', 'withSignalSync', 'withSignalAsync'];
  }
}
