import { gridPaddingComponsation } from './variables';

export const getSharedFooterStyles = () => ({
  '& tr.noborder': {
    '& td': {
      border: 'none',
      paddingLeft: '0px',
      paddingTop: '24px',
      '& .pagination-control': {
        display: 'flex',
        alignItems: 'center',
        marginTop: '5px',
        marginBottom: '5px',
        marginLeft: gridPaddingComponsation,

        '& .form-control.page-size-selector': {
          marginLeft: '5px',
          minWidth: '60px',
        },
        '& .pager': {
          display: 'flex',
          alignItems: 'center',
          marginTop: '0',
          marginBottom: '0',
          marginLeft: '20px',
          '& .nav-button': {
            border: 'none',
            padding: '0',
            minWidth: '20px',
            display: 'flex',
            alignItems: 'center',
            '&.prev': {
              marginRight: '10px',
              '& + .text': {
                display: 'none', // to hide text "Page:"
              },
            },
            '&.next': {
              marginLeft: '10px',
            },
            '&:disabled': {
              backgroundColor: '#fff',
            },
          },
          '& .page-number-input': {
            marginRight: '5px',
            width: '75px',
          },
        },
      },
    },
  },
});
