import _ from 'underscore';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LayoutContext } from './layout-context';

export const Layout = (props) => {
  const Component = props.component;
  const itemName = props.item;
  const {
    config, styles, change: changeLayout,
  } = useContext(LayoutContext);

  const componentProps = { ...(_.result(config, itemName)), changeLayout };
  const { classes } = _.result(styles, itemName);

  return (
    <div className={`app-shell-${itemName} ${_.result(classes, itemName)}`}>
      <Component {..._.defaults(componentProps, props)} />
    </div>
  );
};

Layout.propTypes = {
  component: PropTypes.elementType.isRequired,
  item: PropTypes.string.isRequired,
};
