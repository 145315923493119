import { AriaListener } from '@bingads-webui/aria-listener';
import {
  getAriaConfig,
  PerformanceLogsFormatter,
  PerformanceLogsFilter,
} from '@bingads-webui-campaign/instrumentation';

export default function getPerfLogListeners(props) {
  const { ariaConfig: applicationAriaConfig = {} } = props || {};
  const currentEnv = document.domain;
  const ariaConfig = {
    ...getAriaConfig(currentEnv),
    ...applicationAriaConfig,
  };

  // init log formatters
  const performanceLogsFormatter = new PerformanceLogsFormatter();

  // init log filters
  const performanceLogsFilter = new PerformanceLogsFilter();

  const ariaListener = new AriaListener({
    tenantToken: ariaConfig.token,
    flushInterval: ariaConfig.flushInterval,
    batchSize: ariaConfig.batchSize,
    logTableNames: {
      resourcetiming: {
        name: 'resourcetiming',
      },
    },
    defaultLogCategory: 'resourcetiming',
    formatters: {
      performanceLogsFormatter,
    },
    filters: {
      performanceLogsFilter,
    },
  });

  ariaListener.init();
  ariaListener.registerForTeardown();

  const listeners = {
    ariaListener,
  };

  return listeners;
}
