import React from 'react';
import { compose } from 'underscore';
import { withDefaultStyles } from '@bingads-webui-react/with-default-styles';
import { Image, Text } from '@fluentui/react-components';
import RestrictedPlaceholder from '../../assets/restricted.svg';

const getStyles = () => ({
  errorPageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noResultWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '100px',
    '& .first-row': {
      marginTop: '24px',
      fontWeight: '400',
      fontSize: '24px',
      lineHeight: '36px',
      '& b': {
        fontWeight: '600',
      },
    },
    '& .second-row': {
      marginTop: '8px',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
});

const ErrorPage = ({ classes }) => (
  <div className={classes.errorPageContainer}>
    <div className={classes.noResultWrapper}>
      <Image src={RestrictedPlaceholder} height="200" width="222" />
      <Text className="first-row">Something went wrong. Please refresh the page and try again.</Text>
    </div>
  </div>
);

export const StyledErrorPage = compose(WrappedComponent => withDefaultStyles(WrappedComponent, getStyles))(ErrorPage);
