import React, { Suspense, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { LayoutContainer, Layout, layoutConfigs } from '@bingads-webui/adlibrary-layout-container';
import { Header, PageMenu, PageFooter } from '@bingads-webui/adlibrary-layout-elements';
import { getInstrumentation, getInstrumentationService, InstrumentationServiceContext } from '@bingads-webui/adlibrary-instrumentation';
import { FetchProvider } from '@bingads-webui/adlibrary-fetch-layer';
import { PerformanceMarkerProvider } from '@bingads-webui-campaign-react/performance-marker-provider';
import { I18nContext } from '@bingads-webui-react/i18n-context';
import { staticI18nMock } from '@bingads-webui-test/i18n-mock'; // modelI18nMock
import { FluentProvider, webLightTheme, Spinner } from '@fluentui/react-components';

import ErrorBoundary from './error';

const FallbackSpinner = () => (
  <div style={{
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Spinner size="extra-large" />
  </div>
);

function App() {
  const serverContext = {
    DynamicPermissions: {
      EnableLoggingForUnhandledPromiseRejections: true,
    },
    PageTrackingId: 1,
    CurrentCustomer: {},
    CurrentAccount: {},
    CurrentUser: {},
  };

  const instrumentation = useMemo(() => getInstrumentation({
    serverContext,
  }), [serverContext]);

  const instrumentationService = useMemo(() => getInstrumentationService({
    instrumentation,
    serverContext,
  }), [instrumentation, serverContext]);

  return (
    <FluentProvider theme={webLightTheme}>
      <I18nContext.Provider value={staticI18nMock} >
        <InstrumentationServiceContext.Provider value={instrumentationService}>
          <PerformanceMarkerProvider
            name="perf_marker_ad_library"
            instrumentation={instrumentation}
            immediateDone
          >
            <FetchProvider instrumentation={instrumentation}>
              <LayoutContainer
                pageConfig={layoutConfigs.default}
              >
                <div className="nav-skip" role="navigation">
                  <a href="#main">{_TL_('Skip to main content')}</a>
                </div>
                <Layout item="header" component={Header} />
                <Layout item="nav" component={PageMenu} />
                <main id="main">
                  <Layout
                    item="content"
                    component={() =>
                    (
                      <ErrorBoundary>
                        <Suspense fallback={<FallbackSpinner />}>
                          <Outlet />
                          <PageFooter />
                        </Suspense>
                      </ErrorBoundary>
                    )
                  }
                  />
                </main>
              </LayoutContainer>
            </FetchProvider>
          </PerformanceMarkerProvider>
        </InstrumentationServiceContext.Provider>
      </I18nContext.Provider>
    </FluentProvider>
  );
}

export default App;
