export const paginationStyle = ({ palette = {}, iconFontFamily = 'MsAds MDL2 Assets' }) => ({
  '& .ba-pagination, .dlb-pager-container, .pagination-control': {
    height: 'auto',
    padding: '6px 0',

    '& > .text': {
      display: 'inline-block',
      verticalAlign: 'middle',
      padding: '0px 0px 8px 5px',
      '& > span': {
        marginRight: '5px',
      },
    },
    '& button': {
      border: 0,
      background: 'transparent',
      minWidth: '1px',
      padding: 0,
      color: palette.neutralSecondary,
    },

    '& button:hover': {
      borderRadius: 2,
      background: palette.neutralLighter,
    },

    '& button:disabled': {
      background: 'transparent',
      color: '#BEBBB8',
    },

    '& .prev + .text, .pager-previous + .text': {
      display: 'none',
    },

    '& .pager-input': {
      border: `1px solid ${palette.neutralSecondary}`,
      padding: '6px 8px',
      height: 32,
      textAlign: 'center',
      margin: '0 12px',
      width: 70,
      display: 'inline-block',
      borderRadius: 2,
      boxSizing: 'border-box',
      color: palette.neutralPrimary,

      '&:active': {
        borderColor: palette.themePrimary,
      },

      '&:focus': {
        borderColor: palette.themePrimary,
        outline: 0,
      },

      '&:hover': {
        borderColor: palette.neutralPrimary,
      },
    },

    '& .glyphicon-triangle-left, & .glyphicon-triangle-right': {
      display: 'inline-block',
      fontFamily: iconFontFamily,
      color: 'inherit',
    },

    '& .glyphicon-triangle-left:before': {
      content: '"\\E76B"',
    },

    '& .glyphicon-triangle-right:before': {
      content: '"\\E76C"',
    },
  },

  '& .pager, .location-bid-pager-view': {
    height: 32,
    padding: 0,
    margin: 0,

    '& .pager-input, .page-number-input, .grid-pager-input': {
      border: `1px solid ${palette.neutralSecondary}`,
      padding: '6px 8px',
      height: 32,
      textAlign: 'center',
      margin: '0 12px',
      width: '70px !important',
      display: 'inline-block',
      borderRadius: 2,
      boxSizing: 'border-box',
      color: palette.neutralPrimary,
      background: palette.white,
    },

    '& .pager-input[disabled], & .page-number-input[disabled], & .grid-pager-input[disabled]': {
      backgroundColor: palette.neutralLighter,
    },

    '& .pager-previous, & .pager-next, & .glyphicon-triangle-left, & .glyphicon-triangle-right': {
      fontFamily: iconFontFamily,
      color: palette.neutralSecondary,
      background: 'transparent',
      display: 'inline-block',
      verticalAlign: 'middle',
      padding: '2px 5px',
      height: 32,
      lineHeight: '32px',
      marginTop: '-7px',
      outline: 'none',
    },

    '& .pager-previous, & .pager-next': {
      '&:focus': {
        outline: `1px solid ${palette.neutralSecondary}`,
        outlineOffset: '-2px',
      },
    },

    '& .pager-previous:hover, & .pager-next:hover': {
      borderRadius: 2,
      background: palette.neutralLighter,
    },

    '& .pager-previous-disabled, & .pager-next-disabled': {
      fontFamily: iconFontFamily,
      color: '#BEBBB8',
      background: 'transparent',
      display: 'inline-block',
      verticalAlign: 'middle',
      padding: '2px 5px',
      height: 32,
      lineHeight: '32px',
      marginTop: '-7px',
      outline: 'none',
    },

    '& .pager-previous-disabled:before, & .pager-previous:before': {
      content: '"\\E76B"',
    },

    '& .pager-next-disabled:before, & .pager-next:before': {
      content: '"\\E76C"',
    },

    '& .pager-totalCount': {
      verticalAlign: 'unset',
      marginLeft: 4,
    },
  },

  '& .pagination-control': {
    textAlign: 'left',

    '& .text': {
      display: 'inline-block',
      width: 'auto',
    },

    '& .page-size-selector': {
      height: 32,
      textAlign: 'center',
      margin: '0 12px',
      width: '70px !important',
      display: 'inline-block',
      borderRadius: 2,
      boxSizing: 'border-box',
      color: palette.neutralPrimary,
    },

    '& .pager': {
      display: 'inline-block',
    },
  },

});

