import _ from 'underscore';

/**
 * UrlErrorMapper - Maps ajax errors from known invalid domains as user errors
 */
export class UrlErrorMapper {
  /**
   * @param {object} domains - known invalid domains
   */
  constructor(domains) {
    this.domains = domains || [];
  }

  /**
   * Checks if the url is of a known invalid domain
   * @param {object} ajaxData - data related to the current ajax call
   * @returns {bool} - if the url maps to a known invalid domain or not
   */
  check(ajaxData) {
    const ajaxUrlString = ajaxData.url;

    return _.isString(ajaxUrlString) &&
      _.any(this.domains, domain => ajaxUrlString.toUpperCase().indexOf(domain.toUpperCase()) >= 0);
  }
}
