import _ from 'underscore';
import urijs from 'urijs';
import { generateGuid } from '@bingads-webui-universal/primitive-utilities';
import { AjaxEventInterceptor } from './ajax-event-interceptor';

// We only want one ajax intercept, however instrumentation can be created a second time
// e.g. after creating a CID during signup
let globalInterceptInstance = null;

/**
 * AjaxInterceptor - Provides functionality to intercept ajax calls
 */
export class AjaxInterceptor {
  /**
   * @param {object} stubOptions - stub options to be used in ajax logging
   * @param {object} eventHandler - handler for ajax events
   * @param {object} scenario - scenario to be used by the stub
   * @param {function} generateRid - optional: overwrites the function that generates an RID
   */
  constructor(stubOptions, eventHandler, scenario, generateRid) {
    this.stubOptions = stubOptions;
    this.eventHandler = eventHandler;
    this.scenario = scenario;
    this.generateRid = generateRid || generateGuid;
  }

  /* eslint-disable no-param-reassign */
  intercept(jQuery) {
    const originalAjax = jQuery.ajax;
    const intercept = (url, options) => {
      options = options || {};

      if (_.isObject(url)) {
        options = url;
      } else if (_.isString(url)) {
        options.url = url;
      }

      // add requestId to url
      const requestId = this.generateRid();
      if (this.shouldAddRequestId(options.dataType, options.url)) {
        options.url = urijs(options.url).setQuery('ReqId', requestId).toString();
      }

      const activity = this.scenario.ajaxStubActivity.create();
      const ajaxEventInterceptor = new AjaxEventInterceptor(
        options,
        requestId,
        this.stubOptions,
        this.eventHandler,
        activity
      );
      ajaxEventInterceptor.triggerEventInterceptors();

      return originalAjax.call(jQuery, options);
    };

    globalInterceptInstance = intercept;

    jQuery.ajax = (url, options) => (globalInterceptInstance === intercept ?
      intercept(url, options) :
      originalAjax.call(jQuery, url, options));
  }

  shouldAddRequestId(dataType, url) {
    const urlInLowerCase = _.isString(url) ? url.toLowerCase() : '';

    return _.isString(url) &&
      urlInLowerCase.indexOf('reqid') === -1 &&
      (_.isUndefined(dataType) || dataType !== 'script') &&
      urlInLowerCase.indexOf('scripts/') === -1;
  }
}
