export const constants = {
  IMPACTUSER: {
    True: true,
    False: false,
    NA: null,
  },
  LOGLEVEL: {
    INFO: {
      name: 'Info',
      value: 0,
    },
    DEBUG: {
      name: 'Debug',
      value: 1,
    },
    VERBOSE: {
      name: 'Verbose',
      value: 2,
    },
  },
  LOGCATEGORY: {
    PERFORMANCE: 'ClientPerf',
    ERROR: 'ApplicationErrorClientScript',
    TRACE: 'ClientTrace',
    SUBSTRATE: 'Substrate',
  },
  ENTRYTYPE: {
    SCENARIO: 'Scenario',
    ACTIVITY: 'Activity',
    AJAX: 'Ajax',
    AJAXRENDER: 'AjaxRender',
    METHOD: 'Method',
    MESSAGE: 'Message',
  },
};
