import _ from 'underscore';
import { useMemo, useRef } from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const emptyTheme = {};
const defaultProps = {};
const defaultGetStyles = () => ({});


function useDeepMemo(value) {
  const ref = useRef(null);
  if (!_.isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDefaultStyles(getStyles = defaultGetStyles, props = defaultProps) {
  const theme = useTheme();

  const useStyles = useMemo(
    () => {
      const { components = {}, core = {} } = theme || emptyTheme;
      return createUseStyles(getStyles(components, core));
    },
    [getStyles, theme]
  );

  const classes = useStyles(props);

  // useDeepMemo to prevent hooks from re-running if the classes object changes reference
  // however the content doesn't change
  return useDeepMemo(classes);
}
