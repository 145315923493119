/**
 * Pre-defined schema used for Error Stub
 */
export const schema = {
  scenario: [{
    name: 'errorStubScenario',
    value: 'Error Stub Scenario',
    setting: {
      hidden: true,
    },
    activity: [
      {
        name: 'errorStubActivity',
        value: 'Error Stub Activity',
        setting: {},
      },
    ],
  }],
};
