import _ from 'underscore';
import { safeGetString } from './string-utils';

/* eslint-disable no-throw-literal, prefer-rest-params, no-plusplus, prefer-spread */
export class ModuleI18nShim {
  constructor(realI18n) {
    this.realI18n = realI18n;
  }

  getString(key) {
    if (!_.isString(key)) {
      throw 'Localization key was not a String';
    }

    let res = safeGetString(this.realI18n, key) || '';

    if (res) {
      if (arguments.length > 1) {
        for (let i = 1; i < arguments.length; i++) {
          const regex = new RegExp(`\\{${i - 1}\\}`, 'gi');

          res = res.replace(regex, arguments[i]);
        }
      }
    }

    return res || '';
  }

  getStringOrDefault(key, defValue) {
    if (!_.isString(key)) {
      throw 'Localization key was not a String';
    }

    if (!_.isString(defValue)) {
      throw 'The default value must be specified and must be a String';
    }

    const args = _.toArray(arguments);
    args.splice(1, 1);

    return this.getString.apply(this, args) || defValue;
  }
}
