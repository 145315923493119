import { PagePadding } from '../../constants/pagePadding';

export const headerRowBackgroundColor = '#FFFFFF';
export const headerRowBorderTop = '2px solid #81868B';
export const headerRowBorderBottom = '2px solid #81868B';
export const totalRowBackgroundColor = '#FFFFFF';
export const totalRowBorderTop = 'none'; // `1px solid ${palette.neutralLighter}`
export const tableBorder = '1px solid #EDEBE9';

export const gridPaddingLeft = PagePadding.mainSectionLeftPadding;
export const gridPaddingComponsation = 0; // These two should add up to 32px
