/* eslint-disable max-len */
import { tableBorder } from './variables';

export const getSharedColumnsStyles = () => ({
  '&': {
    padding: '6px 8px',
    verticalAlign: 'middle',
    border: tableBorder, // NOTE: updated from 'palette.neutralLighter' for fit and finish experiments
    fontSize: '12px',
    overflowWrap: 'break-word',
  },

  '&:first-child': {
    paddingLeft: '12px',
  },

  '& span': {
    lineHeight: '16px',
  },
});
