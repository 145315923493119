import { constants } from '@bingads-webui/instrumentation';
import { Filter } from '@bingads-webui/listener';

export class ConsoleFilter extends Filter {
  check(logMessage) {
    const category = logMessage.logCategory || '';
    const api = logMessage.api || '';

    // log only error logs from window.onerror
    if (api === 'onerror' && category === constants.LOGCATEGORY.ERROR) {
      return false;
    }

    return true;
  }
}
