import _ from 'underscore';
import Promise from 'bluebird';
import Globalize from 'globalize';
import { LocaleManager } from './locale';
import { Resource } from './resource';

const localeManager = new LocaleManager();

const dynamicLocaleInstances = [];

/**
 * @this I18nModel
 * @param {String} key localization key
 * @returns {void}
 * @private
 */
function assertReady(key) {
  if (!this.isReady()) {
    const debugInfo = `name-${this.name},culture-${this.locale},key-${key}`;

    throw new Error(`The resource is not yet ready(${debugInfo}). Please either call sync APIs only when the promise is fulfilled, or switch to use async APIs.`);
  }
}

/**
 * @this I18nModel
 * @param {String} key localization key
 * @returns {Resource} Current synchronous resource
 * @private
 */
function synchronousResource(key) {
  assertReady.call(this, key);
  return this.p$data.value().resource;
}

/**
 * @this I18nModel
 * @returns {Globalize} Current synchronous globalize
 * @private
 */
function synchronousGlobalize() {
  assertReady.call(this);
  return this.p$data.value().globalize;
}

/**
 * If the given object is thenable
 * @param {any} object - The object to be tested
 * @returns {bool} Current synchronous globalize
 * @private
 */
function isThenable(object) {
  return object && _.isFunction(object.then);
}

/**
 * @this I18nModel
 * @param {string} locale - the locale used to load data
 * @returns {Promise} Promise of translation resource and globalize
 * @private
 */
function loadData(locale) {
  const translationData = this.loadTranslationData(locale);
  const cldrData = this.loadCLDRData(locale);

  if (isThenable(translationData) || isThenable(cldrData)) {
    return Promise.props({
      locale,
      resource: Promise.resolve(translationData).then(data => new Resource({ data, failFast: this.failFast })),
      globalize: Promise.resolve(cldrData).then(cldr => new Globalize(cldr)),
    });
  }

  return Promise.resolve({
    locale,
    resource: new Resource({
      data: translationData,
      failFast: this.failFast,
    }),
    globalize: new Globalize(cldrData),
  });
}

/* @this I18nModel */
function dateMethodOptions({
  skeleton,
  date,
  time,
  datetime,
  raw,
  timeZone = this.timeZone,
} = {}) {
  return _.pick({
    skeleton,
    date,
    time,
    datetime,
    raw,
    timeZone,
  }, Boolean);
}

/* @this I18nModel */
function withSignWrapper(withSign, method) {
  switch (withSign) {
    case 'auto':
      return value => method(value);
    case 'never':
      return value => method(Math.abs(value));
    case 'always': {
      const cldr = this.getCldr();
      const minusSign = cldr.main('numbers/symbols-numberSystem-latn/minusSign');
      const plusSign = cldr.main('numbers/symbols-numberSystem-latn/plusSign');

      return (value) => {
        if (value < 0) {
          return method(value);
        }
        // Same as described in CLDR ticket
        // see: http://unicode.org/cldr/trac/ticket/8732
        // Note: 0 will have no sign
        return method(-value).replace(
          // only support latn for now
          minusSign,
          plusSign
        );
      };
    }
    default:
      throw new RangeError(`'withSign' option should be one of 'auto', 'never' or 'always', but being ${withSign}`);
  }
}

/**
 * Return a function synchronously format a number with given options.
 * @param {string} [withSign=auto] - Show positive/negative sign or not.
 *    Could be 'always', 'auto' or 'never'. 'auto' means show sign for negative but not positive.
 * @param {Object} [options] - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/number/number-formatter.md}
 * @return {Function} The function formatting number
 * @private
 */
function numberFormatterWithSign(withSign, options) {
  const globalizeFormatter = synchronousGlobalize.call(this).numberFormatter(options);
  // SignificantDigits related param override IntegerDigits/FractionDigits related param
  // pick Boolean to remove them if they're not passed in
  return withSignWrapper.call(this, withSign, value => globalizeFormatter(value));
}

/**
 * Return a function synchronously format a currency with given options.
 * @param {string} currency - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/currency/currency-formatter.md}
 * @param {string} [withSign=auto] - Show positive/negative sign or not.
 *    Could be 'always', 'auto' or 'never'. 'auto' means show sign for negative but not positive.
 * @param {Object} [options] - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/currency/currency-formatter.md}
 * @return {Function} The function formatting number
 * @private
 */
function currencyFormatterWithSign(currency, withSign = 'auto', options) {
  if (!currency) {
    return () => '0';
  }

  const globalizeFormatter = synchronousGlobalize.call(this).currencyFormatter(currency, options);
  // SignificantDigits related param override IntegerDigits/FractionDigits related param
  // pick Boolean to remove them if they're not passed in
  return withSignWrapper.call(this, withSign, value => globalizeFormatter(value));
}

function currencyFormatterWithSymbol(currency, withSign = 'auto', withSymbol = true, options) {
  if (!currency) {
    return () => '0';
  }

  if (withSymbol) {
    return currencyFormatterWithSign.call(this, currency, withSign, options);
  }

  const globalizeFormatter = synchronousGlobalize.call(this)
    .currencyToPartsFormatter(currency, options);
  const formatter = (value) => {
    const parts = globalizeFormatter(value);
    const newParts = _.map(parts, (part) => {
      if (part.type === 'integer'
        || part.type === 'group'
        || part.type === 'decimal'
        || part.type === 'fraction'
      ) {
        return part.value;
      }
      return '';
    });
    const finalValue = newParts.join('');

    return finalValue;
  };


  return withSignWrapper.call(this, withSign, formatter);
}

function withGlobalizeCache(fnName, i18nModel) {
  const realMethod = i18nModel[fnName];

  function createCache() {
    const cache = {};
    cache.size = 0;

    return cache;
  }

  return function wrappedGlobalizeMethod(...args) {
    const cache = i18nModel.parserFormatterCache;
    if (!cache[fnName]) {
      cache[fnName] = createCache();
    }

    let specificMethodCache = cache[fnName];

    const key = JSON.stringify(args);
    if (specificMethodCache[key]) {
      return specificMethodCache[key];
    }

    /* Just a precaution, don't expect cache to grow this large */
    if (specificMethodCache.size > 100) {
      cache[fnName] = createCache();
      specificMethodCache = cache[fnName];
    }

    specificMethodCache[key] = realMethod.apply(this, args);
    specificMethodCache.size += 1;

    return specificMethodCache[key];
  };
}

/**
 * I18n Model.
 */
export class I18nModel {
  static defaultFailFast = false;

  /**
   * Set global locale used for all i18n model as default locale.
   * @param {string} locale - should be in format of IETF language tag {@link http://www.langtag.net/}.
   * @returns {Promise.<I18nModel[]>} resolved when all dynamic locale i18n models are ready
   */
  static setGlobalLocale(locale) {
    localeManager.setGlobalLocale(locale);
    return Promise.all(_.map(dynamicLocaleInstances, instance => instance.setLocale(locale)));
  }

  /**
   * Load CLDR data for Globalize.js.
   * Helper function which makes Globalize transparent to users.
   * Then user don't need to import it explictly.
   * @param {JSON[]} data - CLDR data.
   * @returns {void}
   */
  static loadCLDRData(...data) {
    Globalize.load(...data);
  }

  /**
   * Load time zone data for Globalize.js.
   * Helper function which makes Globalize transparent to users.
   * Then user don't need to import it explictly.
   * @param {JSON} data - Time zone data.
   * @returns {void}
   */
  static loadTimeZoneData(data) {
    Globalize.loadTimeZone(data);
  }

  /**
   * Sets the default failfast value for every i18nmodel
   *
   * @returns {void}
   * @param {string|bool} failfast  when key missing
   *     true   - i18n throws error and crashes current execution
   *    "SAFE"  - i18n throws error asyncrounously but continues execution
   */
  static setDefaultFailFast(failfast) {
    I18nModel.defaultFailFast = failfast;
  }

  /**
   * @callback loadCLDRData
   * @param {string} locale - the locale used to load CLDR data
   * @returns {Promise.<CldrJs>|Promise.<string>|CldrJs|string}
   *    The CLDR data or the locale name linked to already loaded CLDR data
   */

  /**
   * @callback loadTranslationData
   * @param {string} locale - the locale used to load translation data
   * @returns {Promise.<Object.<string, string>>|Object.<string, string>}
   *    key value pairs as tranlation data
   */
  /**
   * Create a model.
   * When both options.loadCLDRData and options.loadTranslationData returns raw data instead
   * of Promise, and locale is neither 'current' nor 'dynamic', the model created is surely
   * ready for use synchronously.
   * @param {Object} options - The initialization options
   * @param {string} [options.name] - The name of the i18n package
   * @param {string} [options.locale=current] - The locale of this i18n model; could be a
   *   specific locale (e.g. 'en-us'), 'dynamic' or 'current' (default)
   * @param {string} [options.timeZone] - The default timeZone used by formatDate
   * @param {string} [options.currency] - The default currency used by formatCurrency
   * @param {loadCLDRData} options.loadCLDRData - a function which accepts locale as param and
   *   returns a promise which resolves after coresponding CLDR data loaded, and with value
   *   the CLDR.js instance or the locale name as [Globalize constructor]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/core/constructor.md} requires
   * @param {loadTranslationData} options.loadTranslationData - a function which accepts locale
   *   as param and returns a promise which resolves with a translation data object
   * @param {string|bool} options.failFast - when key missing
   *     true   - i18n throws error and crashes current execution
   *    "SAFE"  - i18n throws error asyncrounously but continues execution
   *
   * @constructs I18nModel
   */
  constructor({
    name,
    locale = 'current',
    timeZone,
    currency,
    loadCLDRData,
    loadTranslationData,
    failFast = I18nModel.defaultFailFast,
  }) {
    this.locale = locale;
    this.name = name;
    this.timeZone = timeZone;
    this.currency = currency;

    this.loadCLDRData = loadCLDRData;
    this.loadTranslationData = loadTranslationData;
    this.missingKeys = {};
    this.failFast = failFast;
    this.parserFormatterCache = {};

    /* These methods return parsers and formatters that can be safely cached.
    (they don't have any functions as a parameter). Globalize recommends caching
    parsers and formatters as generating them can be very expensive */
    ['decimalParser',
      'percentParser',
      'dateFormatter',
      'dateToPartsFormatter',
      'dateParser',
    ].forEach((fnName) => {
      this[fnName] = withGlobalizeCache(fnName, this);
    });

    if (locale === 'dynamic') {
      // If dynamic, reload resource on locale change
      dynamicLocaleInstances.push(this);
    }

    if (locale === 'current' || locale === 'dynamic') {
      // Wait for initial locale to be set and then load the resource
      const p$globalLocale = localeManager.p$globalLocale();

      // when global locale is synced ready, make p$data synced ready too
      if (p$globalLocale.isFulfilled()) {
        this.p$data = loadData.call(this, p$globalLocale.value());
      } else {
        this.p$data = localeManager.p$globalLocale()
          .then(globalLocale => loadData.call(this, globalLocale));
      }
    } else {
      this.setLocale(locale);
    }
  }

  /**
   * Set locale used for this model, will reuse other data.
   * @param {string} locale - should be in format of IETF language tag {@link http://www.langtag.net/}.
   * @returns {Promise.<I18nModel>} resolved when all resource for this model loaded
   */
  setLocale(locale) {
    this.p$data = loadData.call(this, locale);
    this.parserFormatterCache = {};

    return this.ready();
  }

  /**
   * Get a promise that resolves to this object when the resource is ready.
   * @return {Promise.<I18nModel>} This object
   */
  ready() {
    return this.p$data.return(this);
  }

  /**
   * Check whether the resource is ready.
   * @return {boolean} Whether the resource is ready
   */
  isReady() {
    return this.p$data.isFulfilled();
  }

  // Sync API
  // All these methods only work when isReady().
  // If isReady() === false, these methods would throw error.

  /**
   * Synchronously get locale used for this model.
   * @returns {string} locale used for this model.
   */
  getLocale() {
    assertReady.call(this);
    return this.p$data.value().locale;
  }

  /**
   * Synchronously get Cldrjs instance.
   * @return {Cldrjs} The Cldrjs instance.
   * @see [cldr.js - Simple CLDR traverser]{@link https://github.com/rxaviers/cldrjs}
   */
  getCldr() {
    return synchronousGlobalize.call(this).cldr;
  }

  /**
   * Synchronously get first day of week.
   * @param {Object} [options] - options
   * @param {String} [options.alt] - undefined or 'variant'.
   * @return {string} The weekday as first day of week. One of 'mon', 'fri', 'sat', sun'.
   */
  getFirstDayOfWeek({
    alt,
  } = {}) {
    const cldr = this.getCldr();

    const defaultRegionKey = cldr.attributes.region;
    const key = alt ? `${defaultRegionKey}-alt-${alt}` : defaultRegionKey;

    return cldr.supplemental(`weekData/firstDay/${key}`) ||
      cldr.supplemental(`weekData/firstDay/${defaultRegionKey}`) ||
      // 001 means the world
      // see: http://cldr.unicode.org/index/cldr-spec/reasons-for-decisions
      cldr.supplemental('weekData/firstDay/001');
  }

  /**
   * Synchronously get today as CivilDate.
   * Note: for not introducing temporal for now,
   *  this method only returns an object with same structure instead of a real CivilDate.
   * @param {Object} [options] - options
   * @param {String} [options.timeZone] - Time zone name under tz database format.
   * @return {CivilDate} Today as CivilDate.
   * @see [List of tz database time zones]{@link https://en.wikipedia.org/wiki/List_of_tz_database_time_zones}
   */
  getToday({
    timeZone = this.timeZone,
  } = {}) {
    const [
      { value: year },
      { value: month },
      { value: day },
    ] = this.formatDateToParts(new Date(), {
      raw: 'yyyyMMdd',
      timeZone,
    });

    return {
      year: Number(year),
      month: Number(month),
      day: Number(day),
      toString() { return `${year}-${month}-${day}`; },
    };
  }

  /**
   * Synchronously get and evaluate a localized string template (or the string as-is if it's
   *   not a template).
   * If key not found, "undefined" will be returned, client code should check this for themselves.
   * @param {string} key - The key of the string resource
   * @param {Object} [model] - The model for the string template (if needed)
   * @return {string} The template evaluation result
   */
  getString(key, ...args) {
    let str = synchronousResource.call(this, key).getString(key, ...args);
    if (_.isUndefined(str)) { // key is not surrounded by fixture _TL_
      this.missingKeys[key] = true;
      if (this.failFast) {
        try {
          throw new RangeError(`i18n.getString: key is missing _TL_ fixture - "${key}"`);
        } catch (error) {
          if (this.failFast !== 'SAFE') {
            throw error;
          } else {
            _.defer(() => { throw error; });
            str = key;
          }
        }
      }
    }
    return str;
  }

  /**
   * Synchronously get all the localized string data object passed to Resource from onload.
   * @return {Object} An object of i18n key-localized value pair
   */
  getData() {
    return {
      ...synchronousResource.call(this, 'getData').data,
    };
  }

  /**
   * Synchronously get quarter's translation.
   * @param {Number} quarter - An integer from 1 to 4 which matches with 1st to 4th quarter
   * @param {Object} [options] - options
   * @param {String} [options.style=wide] - 'abbreviated', 'narrow' or 'wide'
   * @return {String} The quarter's translation
   */
  getQuarter(quarter, {
    style = 'wide',
  } = {}) {
    return this.getCldr().main(`dates/calendars/gregorian/quarters/stand-alone/${style}/${quarter}`);
  }

  /**
   * Synchronously get month's translation.
   * @param {Number} month - An integer from 1 to 12 which matches with January to December
   * @param {Object} [options] - options
   * @param {String} [options.style=wide] - 'abbreviated', 'narrow' or 'wide'
   * @return {String} The month's translation
   */
  getMonth(month, {
    style = 'wide',
  } = {}) {
    return this.getCldr().main(`dates/calendars/gregorian/months/stand-alone/${style}/${month}`);
  }

  /**
   * Synchronously get day's translation.
   * @param {String} day - 'sun', 'mon', 'tue', 'wed', 'thu', 'fri' or 'sat'
   * @param {Object} [options] - options
   * @param {String} [options.style=wide] - 'abbreviated', 'narrow', 'short' or 'wide'
   * @return {String} The day's translation
   */
  getDay(day, {
    style = 'wide',
  } = {}) {
    return this.getCldr().main(`dates/calendars/gregorian/days/stand-alone/${style}/${day}`);
  }

  /**
   * Alias of [getDay]{@link I18nModel#getDay}
   * @param {String} dayOfWeek - 'sun', 'mon', 'tue', 'wed', 'thu', 'fri' or 'sat'
   * @param {Object} [options] - options
   * @param {String} [options.style=wide] - 'abbreviated', 'narrow', 'short' or 'wide'
   * @return {String} The day's translation
   */
  getDayOfWeek(dayOfWeek, options) {
    return this.getDay(dayOfWeek, options);
  }

  /**
   * Synchronously get currency's display name.
   * @param {String} [currency=this.currency] - currency code under [ISO 4217]{@link https://www.iso.org/iso-4217-currency-codes.html}
   * @return {String} The currency's display name
   */
  getCurrency(currency = this.currency) {
    return this.getCldr().main(`numbers/currencies/${currency}/displayName`);
  }

  /**
   * Synchronously get currency's symbol.
   * @param {String} [currency=this.currency] - currency code under [ISO 4217]{@link https://www.iso.org/iso-4217-currency-codes.html}
   * @param {Object} [options] - options
   * @param {String} [options.alt] - undefined, 'narrow' or 'variant'.
   *  'narrow' or 'variant' won't exist for sure.
   *   when the data missing, it will fallback to the default symbol.
   *   As an example:
   *     for TRY (Turkish Lira), default symbol is 'TRY', narrow alt is '₺', variant alt is 'TL'
   * @return {String} The currency's symbol
   */
  getCurrencySymbol(currency = this.currency, {
    alt,
  } = {}) {
    const defaultSymbolKey = 'symbol';
    const key = alt ? `symbol-alt-${alt}` : defaultSymbolKey;

    return this.getCldr().main(`numbers/currencies/${currency}/${key}`) ||
      this.getCldr().main(`numbers/currencies/${currency}/${defaultSymbolKey}`);
  }

  /**
   * The object contains all patterns
   * @typedef {Object} Patterns
   * @property {string} [full] The full format pattern
   * @property {string} [long] The long format pattern
   * @property {string} [medium] The medium format pattern
   * @property {string} [short] The short format pattern
   */

  /**
   * The DateFormats contains date/dateTime/time format patterns
   * @typedef {Object} DateFormats
   * @property {string} [default] The default date format pattern
   * @property {Patterns} [date] The date patterns
   * @property {Patterns} [dateTime] The dateTime patterns
   * @property {Patterns} [time] The time patterns
   */

  /**
   * Synchronously get date format patterns for user's culture
   * @return {DateFormats} The date format patterns object
   */
  getDateFormats() {
    const calendar = this.getCldr().main('dates/calendars/gregorian');
    const defaultProperties = ['full', 'long', 'medium', 'short'];

    return {
      default: calendar.dateTimeFormats.availableFormats.yMd, // Globalize will use yMd as the default date format pattern. https://github.com/globalizejs/globalize/blob/d5e98d1e3047ac722cce5817f8168268dd22ddf4/src/date.js#L136
      date: _.pick(calendar.dateFormats, defaultProperties),
      dateTime: _.pick(calendar.dateTimeFormats, defaultProperties),
      time: _.pick(calendar.timeFormats, defaultProperties),
    };
  }

  /**
   * Synchronously format decimal number with given options.
   * @param {number} number - The number to be formatted
   * @param {Object} [options] - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/number/number-formatter.md}
   * @param {string} [options.withSign=auto] - Show positive/negative sign or not.
   *    Could be 'always', 'auto' or 'never'. 'auto' means show sign for negative but not positive.
   * @return {string} The formatted decimal number
   */
  formatDecimal(number, options) {
    return this.decimalFormatter(options)(number);
  }

  /**
   * Return a function synchronously format decimal number with given options.
   * @param {Object} [options] - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/number/number-formatter.md}
   * @param {string} [options.withSign=auto] - Show positive/negative sign or not.
   *    Could be 'always', 'auto' or 'never'. 'auto' means show sign for negative but not positive.
   * @return {Function} The function formatting decimal number
   */
  decimalFormatter({
    minimumIntegerDigits,
    minimumFractionDigits,
    maximumFractionDigits,
    minimumSignificantDigits,
    maximumSignificantDigits,
    round,
    useGrouping,
    compact,
    withSign = 'auto',
  } = {}) {
    return numberFormatterWithSign.call(this, withSign, {
      style: 'decimal',
      minimumIntegerDigits,
      minimumFractionDigits,
      maximumFractionDigits,
      minimumSignificantDigits,
      maximumSignificantDigits,
      round,
      useGrouping,
      compact,
    });
  }

  /**
   * Synchronously format decimal number to fixed-point as toFixed(digits) with given options.
   * This method is only a shortcut built on top of formatDecimal, it isn't in i18n interface
   *   and shouldn't be used by shared components.
   * @see [Number.prototype.toFixed()]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toFixed}
   * @param {number} number - The number to be formatted
   * @param {Object} [options] - options
   * @param {number} [options.digits=2] - The number of fraction digits
   * @param {boolean} [options.round] - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/number/number-formatter.md}
   * @param {boolean} [options.useGrouping] - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/number/number-formatter.md}
   * @param {string} [options.withSign=auto] - Show positive/negative sign or not.
   *    Could be 'always', 'auto' or 'never'. 'auto' means show sign for negative but not positive.
   * @return {string} The formatted decimal number
   */
  formatDecimalToFixed(number, options) {
    return this.decimalToFixedFormatter(options)(number);
  }

  /**
   * Return a function synchronously format decimal number to fixed-point as toFixed(digits).
   * This method is only a shortcut built on top of formatDecimal, it isn't in i18n interface
   *   and shouldn't be used by shared components.
   * @see [Number.prototype.toFixed()]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toFixed}
   * @param {Object} [options] - options
   * @param {number} [options.digits=2] - The number of fraction digits
   * @param {boolean} [options.round] - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/number/number-formatter.md}
   * @param {boolean} [options.useGrouping] - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/number/number-formatter.md}
   * @param {string} [options.withSign=auto] - Show positive/negative sign or not.
   *    Could be 'always', 'auto' or 'never'. 'auto' means show sign for negative but not positive.
   * @return {Function} The function formatting decimal number
   */
  decimalToFixedFormatter({
    digits = 2,
    round,
    useGrouping,
    compact,
    withSign = 'auto',
  } = {}) {
    return this.decimalFormatter({
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
      round,
      useGrouping,
      compact,
      withSign,
    });
  }

  /**
   * Synchronously format decimal number to integer as toFixed(0) with given options.
   * This method is only a shortcut built on top of formatDecimal, it isn't in i18n interface
   *   and shouldn't be used by shared components.
   * @see [Number.prototype.toFixed()]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toFixed}
   * @param {number} number - The number to be formatted
   * @param {Object} [options] - options
   * @param {boolean} [options.round] - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/number/number-formatter.md}
   * @param {boolean} [options.useGrouping] - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/number/number-formatter.md}
   * @param {string} [options.withSign=auto] - Show positive/negative sign or not.
   *    Could be 'always', 'auto' or 'never'. 'auto' means show sign for negative but not positive.
   * @return {string} The formatted decimal number
   */
  formatDecimalToInteger(number, options) {
    return this.decimalToIntegerFormatter(options)(number);
  }

  /**
   * Return a function synchronously format decimal number to integer as toFixed(0).
   * This method is only a shortcut built on top of formatDecimal, it isn't in i18n interface
   *   and shouldn't be used by shared components.
   * @see [Number.prototype.toFixed()]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toFixed}
   * @param {Object} [options] - options
   * @param {boolean} [options.round] - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/number/number-formatter.md}
   * @param {boolean} [options.useGrouping] - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/number/number-formatter.md}
   * @param {string} [options.withSign=auto] - Show positive/negative sign or not.
   *    Could be 'always', 'auto' or 'never'. 'auto' means show sign for negative but not positive.
   * @return {Function} The function formatting decimal number
   */
  decimalToIntegerFormatter({
    round,
    useGrouping,
    compact,
    withSign = 'auto',
  } = {}) {
    return this.decimalToFixedFormatter({
      digits: 0,
      round,
      useGrouping,
      compact,
      withSign,
    });
  }

  /**
   * Synchronously parse decimal number.
   * @param {string} value - The number string to be parsed
   * @return {string} The parsed decimal number, NaN is value is invalid
   */
  parseDecimal(value) {
    return this.decimalParser()(value);
  }

  /**
   * Return a function synchronously parse decimal number.
   * @return {Function} The function parsing decimal number
   */
  decimalParser() {
    return synchronousGlobalize.call(this).numberParser({
      style: 'decimal',
    });
  }

  /**
   * Synchronously validate decimal number, a simple wrapper of parseDecimal.
   * @param {string} value - The number string to be validate
   * @return {boolean} If value is string and valid.
   *   Note: number is not valid too, as this method is for strings.
   */
  isValidDecimal(value) {
    return this.decimalValidator()(value);
  }

  /**
   * Return a function synchronously validate decimal number.
   * @return {Function} The function validating decimal number
   */
  decimalValidator() {
    const parser = this.decimalParser();

    return value => _.isString(value) && !_.isNaN(parser(value));
  }

  /**
   * Synchronously format percent number with given options.
   * @param {number} number - The number to be formatted
   * @param {Object} [options] - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/number/number-formatter.md}
   * @param {string} [options.withSign=auto] - Show positive/negative sign or not.
   *    Could be 'always', 'auto' or 'never'. 'auto' means show sign for negative but not positive.
   * @return {string} The formatted percent number
   */
  formatPercent(number, options) {
    return this.percentFormatter(options)(number);
  }

  /**
   * Return a function synchronously format percent number with given options.
   * @param {Object} [options] - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/number/number-formatter.md}
   * @param {string} [options.withSign=auto] - Show positive/negative sign or not.
   *    Could be 'always', 'auto' or 'never'. 'auto' means show sign for negative but not positive.
   * @return {Function} The function formatting percent number
   */
  percentFormatter({
    minimumIntegerDigits,
    minimumFractionDigits,
    maximumFractionDigits,
    minimumSignificantDigits,
    maximumSignificantDigits,
    round,
    useGrouping,
    compact,
    withSign = 'auto',
  } = {}) {
    return numberFormatterWithSign.call(this, withSign, {
      style: 'percent',
      minimumIntegerDigits,
      minimumFractionDigits,
      maximumFractionDigits,
      minimumSignificantDigits,
      maximumSignificantDigits,
      round,
      useGrouping,
      compact,
    });
  }

  /**
   * Synchronously format percent number to fixed-point as toFixed(digits) with given options.
   * This method is only a shortcut built on top of formatPercent, it isn't in i18n interface
   *   and shouldn't be used by shared components.
   * @see [Number.prototype.toFixed()]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toFixed}
   * @param {number} number - The number to be formatted
   * @param {Object} [options] - options
   * @param {number} [options.digits=0] - The number of fraction digits
   * @param {boolean} [options.round] - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/number/number-formatter.md}
   * @param {boolean} [options.useGrouping] - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/number/number-formatter.md}
   * @param {string} [options.withSign=auto] - Show positive/negative sign or not.
   *    Could be 'always', 'auto' or 'never'. 'auto' means show sign for negative but not positive.
   * @return {string} The formatted percent number
   */
  formatPercentToFixed(number, options) {
    return this.percentToFixedFormatter(options)(number);
  }

  /**
   * Return a function synchronously format percent number to fixed-point as toFixed(digits).
   * This method is only a shortcut built on top of percentFormatter, it isn't in i18n interface
   *   and shouldn't be used by shared components.
   * @see [Number.prototype.toFixed()]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toFixed}
   * @param {Object} [options] - options
   * @param {number} [options.digits=0] - The number of fraction digits
   * @param {boolean} [options.round] - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/number/number-formatter.md}
   * @param {boolean} [options.useGrouping] - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/number/number-formatter.md}
   * @param {string} [options.withSign=auto] - Show positive/negative sign or not.
   *    Could be 'always', 'auto' or 'never'. 'auto' means show sign for negative but not positive.
   * @return {Function} The function formatting percent number
   */
  percentToFixedFormatter({
    digits = 0,
    round,
    useGrouping,
    compact,
    withSign = 'auto',
  } = {}) {
    return this.percentFormatter({
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
      round,
      useGrouping,
      compact,
      withSign,
    });
  }

  /**
   * Synchronously parse percent number.
   * @param {string} value - The number string to be parsed
   * @return {string} The parsed percent number, NaN when value is invalid
   */
  parsePercent(value) {
    return this.percentParser()(value);
  }

  /**
   * Return a function synchronously parse percent number.
   * @return {Function} The function parsing percent number
   */
  percentParser() {
    return synchronousGlobalize.call(this).numberParser({
      style: 'percent',
    });
  }

  /**
   * Synchronously validate percent number, a simple wrapper of parsePercent.
   * @param {string} value - The number string to be validate
   * @return {boolean} If value is string and valid
   */
  isValidPercent(value) {
    return this.percentValidator()(value);
  }

  /**
   * Return a function synchronously validate percent number.
   * @return {Function} The function validating percent number
   */
  percentValidator() {
    const parser = this.percentParser();

    return value => _.isString(value) && !_.isNaN(parser(value));
  }

  /**
   * Synchronously format currency with given options.
   * @param {number} number - The number to be formatted
   * @param {string} currency - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/currency/currency-formatter.md}
   * @param {Object} [options] - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/currency/currency-formatter.md}
   * @param {string} [options.withSign=auto] - Show positive/negative sign or not.
   *    Could be 'always', 'auto' or 'never'. 'auto' means show sign for negative but not positive.
   * @param {string} [options.withSymbol=true] - Show currency symbol or not.
   * @return {string} The formatted currency
   */
  formatCurrency(number, currency = this.currency, options) {
    return this.currencyFormatter(currency, options)(number);
  }

  /**
   * Return a function synchronously format currency with given options.
   * @param {string} currency - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/currency/currency-formatter.md}
   * @param {Object} [options] - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/currency/currency-formatter.md}
   * @param {string} [options.withSign=auto] - Show positive/negative sign or not.
   *    Could be 'always', 'auto' or 'never'. 'auto' means show sign for negative but not positive.
   * @param {string} [options.withSymbol=true] - Show currency symbol or not.
   * @return {Function} The function formatting currency
   */
  currencyFormatter(currency = this.currency, options = {}) {
    // Didn't do argument destructuring for options,
    // due to currencyFormatter dose not format floating point correctly
    // with some of the options undefined.
    const { withSign, withSymbol } = options;

    return currencyFormatterWithSymbol.call(this, currency, withSign, withSymbol, _.omit(options, 'withSign', 'withSymbol'));
  }

  /**
   * Synchronously format date with given options.
   * @param {Date} value - The date to be formatted
   * @param {Object} [options] Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/date/date-formatter.md}
   * @return {string} The formatted date
   */
  formatDate(value, options) {
    return this.dateFormatter(options)(value);
  }

  /**
   * Return a function synchronously format date with given options.
   * @param {Object} [options] Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/date/date-formatter.md}
   * @return {Function} The function formatting date
   */
  dateFormatter(options) {
    return synchronousGlobalize.call(this)
      .dateFormatter(dateMethodOptions.call(this, options));
  }

  /**
   * Synchronously format date into parts tokens with given options.
   * @param {Date} value - The date to be formatted
   * @param {Object} [options] Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/date/date-formatter.md}
   * @return {Object[]} The formatted date parts
   */
  formatDateToParts(value, options) {
    return this.dateToPartsFormatter(options)(value);
  }

  /**
   * Return a function synchronously format date into parts tokens with given options.
   * @param {Object} [options] Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/date/date-formatter.md}
   * @return {Function} The function formatting date to parts
   */
  dateToPartsFormatter(options) {
    return synchronousGlobalize.call(this)
      .dateToPartsFormatter(dateMethodOptions.call(this, options));
  }

  /**
   * Synchronously parse date.
   * @param {string} value - The date string to be parsed
   * @param {Object} [options] - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/date/date-parser.md}
   * @return {string} The parsed date, null when value is invalid
   */
  parseDate(value, options) {
    return _.isString(value) ? this.dateParser(options)(value) : null;
  }

  /**
   * Return a function synchronously parse date with given options.
   * @param {Object} [options] Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/date/date-formatter.md}
   * @return {Function} The function parsing date
   */
  dateParser(options) {
    return synchronousGlobalize.call(this)
      .dateParser(dateMethodOptions.call(this, options));
  }

  /**
   * Synchronously validate date, a simple wrapper of parseDate.
   * @param {string} value - The date string to be validate
   * @param {Object} [options] - Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/date/date-parser.md}
   * @return {boolean} If value is string and valid
   */
  isValidDate(value, options) {
    return this.dateValidator(options)(value);
  }

  /**
   * Return a function synchronously validate date with given options.
   * @param {Object} [options] Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/date/date-formatter.md}
   * @return {Function} The function validating date
   */
  dateValidator(options) {
    const parser = this.dateParser(options);

    return value => _.isString(value) && !_.isNull(parser(value));
  }

  /**
   * Synchronously format civil date to localized string.
   * @param {CivilDate} civilDate - The civil date to be formatted
   * @param {Object} [options] Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/date/date-formatter.md}
   *  except for 'timeZone', time', and 'datetime'.
   *  As CivilDate doesn't have timeZone/time infomation.
   * @return {string} The formatted civil date
   * @see [Temporal Proposal]{@link https://github.com/tc39/proposal-temporal}
   */
  formatCivilDate(civilDate, options) {
    return this.civilDateFormatter(options)(civilDate);
  }

  /**
   * Return a function synchronously format civil date into string with given options.
   * @param {Object} [options] Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/date/date-formatter.md}
   *  except for 'timeZone', time', and 'datetime'.
   *  As CivilDate doesn't have timeZone/time infomation.
   * @return {Function} The function formatting civil date to string
   * @see [Temporal Proposal]{@link https://github.com/tc39/proposal-temporal}
   */
  civilDateFormatter(options) {
    const dateFormatter = this.dateFormatter(_.defaults({
      // we create a date under machine time zone from civilDate
      timeZone: null,
    }, _.omit(options, 'datetime', 'time')));

    return civilDate => dateFormatter(new Date(civilDate.year, civilDate.month - 1, civilDate.day));
  }

  /**
   * Synchronously parse civil date.
   * @param {string} value - The date string to be parsed
   * @param {Object} [options] Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/date/date-formatter.md}
   *  except for 'timeZone', time', and 'datetime'.
   *  As CivilDate doesn't have timeZone/time infomation.
   * @return {string} The parsed civil date, null when value is invalid
   * @see [Temporal Proposal]{@link https://github.com/tc39/proposal-temporal}
   */
  parseCivilDate(value, options) {
    return this.civilDateParser(options)(value);
  }

  /**
   * Return a function synchronously parse civil date with given options.
   * @param {Object} [options] Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/date/date-formatter.md}
   *  except for 'timeZone', time', and 'datetime'.
   *  As CivilDate doesn't have timeZone/time infomation.
   * @return {Function} The function parsing civil date
   * @see [Temporal Proposal]{@link https://github.com/tc39/proposal-temporal}
   */
  civilDateParser(options) {
    const dateParser = this.dateParser(_.defaults({
      // we create a date under machine time zone from civilDate
      timeZone: 'UTC',
    }, _.omit(options, 'datetime', 'time')));

    return (value) => {
      const date = dateParser(value);
      // for now, we don't want introduce temporal as dependency of this package
      return _.isDate(date) ? {
        year: date.getUTCFullYear(),
        month: date.getUTCMonth() + 1,
        day: date.getUTCDate(),
        toString() { return date.toISOString().substr(0, 10); },
      } : null;
    };
  }

  /**
   * Synchronously format unit with given options.
   * @param {number} value - The number to be formatted
   * @param {string} unit - The unit to be formatted
   * @param {Object} [options] Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/unit/unit-formatter.md}
   * @return {string} The formatted unit
   */
  formatUnit(value, unit, options) {
    return this.unitFormatter(unit, options)(value);
  }

  /**
   * Return a function synchronously format unit with given options.
   * @param {string} unit - The unit to be formatted
   * @param {Object} [options] Same options as [Globalize.js]{@link https://github.com/globalizejs/globalize/blob/master/doc/api/unit/unit-formatter.md}
   * @return {Function} The function formatting unit
   */
  unitFormatter(unit, {
    form,
    numberFormatter = this.decimalFormatter(),
  } = {}) {
    return synchronousGlobalize.call(this).unitFormatter(unit, {
      form,
      numberFormatter,
    });
  }

  /**
   * Synchronously format list with given options.
   * @param {string[]} list - The list to be formatted
   * @param {Object} [options] Same options as [Intl.ListFormat stage 2 proposal]{@link https://github.com/zbraniecki/proposal-intl-list-format}
   * @param {Object} [options.style=regular] Could be one of "regular", "regular-long",
   *   "regular-short", "unit", "unit-long", "unit-short", "unit-narrow"
   * @return {string} The formatted list
   */
  formatList(list, options) {
    return this.listFormatter(options)(list);
  }

  /**
   * Return a function synchronously format list with given options.
   * @param {Object} [options] Same options as [Intl.ListFormat stage 2 proposal]{@link https://github.com/zbraniecki/proposal-intl-list-format}
   * @param {Object} [options.style=regular] Could be one of "regular", "regular-long",
   *   "regular-short", "unit", "unit-long", "unit-short", "unit-narrow"
   * @return {Function} The function formatting list
   */
  listFormatter({
    style = 'regular',
  } = {}) {
    const [type, variant] = style.split('-');
    const cldr = this.getCldr();
    const cldrTypeName = _.compact([
      type === 'regular' ? 'standard' : type,
      variant === 'long' ? null : variant,
    ]).join('-');
    const listPattern = cldr.main(`listPatterns/listPattern-type-${cldrTypeName}`);
    const template = pattern => ([first, second]) => pattern.replace('{0}', first).replace('{1}', second);
    const twoTemplate = template(listPattern['2']);
    const middleTemplate = template(listPattern.middle);
    const startTemplate = template(listPattern.start);
    const endTemplate = template(listPattern.end);

    return (list) => {
      const size = _.size(list);

      if (size === 0) {
        return '';
      } else if (size === 1) {
        return list[0];
      }

      if (size === 2) {
        return twoTemplate(list);
      }

      const [first, second, ...rest] = list;
      const middle = _.reduce(
        _.initial(rest),
        (memo, item) => middleTemplate([memo, item]),
        second
      );

      return startTemplate([
        first,
        endTemplate([
          middle,
          _.last(list),
        ]),
      ]);
    };
  }

  // Async API

  /**
   * Asynchronously get and evaluate a localized string template (or the string as-is if it's
   *   not a template).
   * @param {string} key - The key of the string resource
   * @param {Object} [model] - The model for the string template (if needed)
   * @return {Promise.<string>} A promise that resolves to the template evaluation result
   */
  fetchString(key, ...args) {
    return this.p$data.then(({ resource }) => {
      const str = resource.getString(key, ...args);
      if (!str) {
        this.missingKeys[key] = true;
      }
      return str;
    });
  }

  /**
   * Asynchronously get locale used for this model.
   * @returns {Promise.<string>} locale used for this model.
   */
  fetchLocale() {
    return this.p$data.then(data => data.locale);
  }
}
