import { createActivity } from './activity';
import { PerfMarker } from './marker';

let instrumentation;
const filesFetched = [];

export class PerfMarkerFactory {
  static init(instr) {
    instrumentation = instr;
  }

  static createMarker(
    name,
    markerOptions = { autoEnd: false, isActiveChild: true },
    activity = createActivity(instrumentation),
    parentMarker = {}
  ) {
    const isFileFetched = filesFetched.indexOf(name) > -1;

    if (!isFileFetched) {
      filesFetched.push(name);
    }

    return new PerfMarker(name, markerOptions, isFileFetched, activity, parentMarker);
  }

  static isPerfMarker(obj) {
    return obj instanceof PerfMarker;
  }

  static isInit() {
    return instrumentation !== null && instrumentation !== undefined;
  }
}
