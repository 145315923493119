/* eslint-disable max-len */
import React from 'react';
import { LayoutContext } from './layout-context';

export function withLayoutContext(Component) {
  return props => (
    <LayoutContext.Consumer>
      {({ config, change }) => (<Component {...props} changeLayout={change} layoutConfig={config} />)}
    </LayoutContext.Consumer>
  );
}
