export const helpbuttonStyle = ({ palette = {}, iconFontFamily = 'MsAds MDL2 Assets' }) => ({
  '.helpbutton': {
    fontSize: 15,
    color: `${palette.themePrimary} !important`,
    verticalAlign: 'middle',
    fontWeight: 'normal',
    marginLeft: 7,

    '&:focus': {
      outline: '1px solid',
      outlineColor: palette.neutralSecondary,
      border: 'none',
      color: palette.black,
      textDecoration: 'none',
    },

    '&:hover': {
      backgroundColor: palette.neutralLighter,
      color: palette.black,
      textDecoration: 'none',
    },

    '&:active': {
      backgroundColor: palette.neutralLight,
      color: palette.black,
      textDecoration: 'none',
    },
  },

  '.helpbutton::before': {
    content: '"\\E9CE"',
    fontFamily: iconFontFamily,
    position: 'relative',
    top: 2,
  },
});

