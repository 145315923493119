export const alertStyle = ({ palette = {}, iconFontFamily = 'MsAds MDL2 Assets' }) => ({
  '& .alert': {
    border: 'none',
    boxShadow: 'none',
    color: palette.neutralPrimary,
    display: 'flex',
    fontSize: 12,
    height: 'auto',
    lineHeight: '16px',
    padding: '8px 32px 8px 8px',

    '&.alert-danger .alert-image:before': {
      fontFamily: iconFontFamily,
      fontSize: 16,
      lineHeight: '16px',
      color: '#A80000',
      marginRight: 8,
      content: '"\\EA39"',
    },

    '&.alert-info .alert-image:before': {
      fontFamily: iconFontFamily,
      fontSize: 16,
      lineHeight: '16px',
      color: palette.neutralSecondary,
      marginRight: 8,
      content: '"\\E946"',
    },

    '&.alert-warning .alert-image:before': {
      fontFamily: iconFontFamily,
      fontSize: 16,
      lineHeight: '16px',
      color: '#d83b01',
      marginRight: 8,

      content: '"\\E7BA"',
    },
  },
});
