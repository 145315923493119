import _ from 'underscore';
import Promise from 'bluebird';

type WithLastPromiseOptions<T> = {
  promiseFactory: (...args: any[]) => Promise<T>,
  finish: (result?: T) => void,
  cancel?: () => void,
  fail?: (error: Error) => void
};

/**
 * This function returns a new "guarded" function. The guarded function behaves like this:
 * - everytime it is called it is going to call the promiseFactory function to
 * generate a new promise and remember it as the last promise;
 * - the guarded function returns a process handle object, which has the cancel function,
 * which can be used to ignore the results of the last promise, and, instead trigger
 * the cancel() callback;
 * - when the "last promise" finishes either finish or fail callback will be triggered,
 * depending on the outcome
 * of the last promise.
 * @param {Object} options - the options object
 * @param {Function} options.promiseFactory - the function that returns a new promise.
 * The factory will be called with the arguments passed to the guarded function.
 * @param {Function} options.finish - the function that will be called when the last
 * promise is resolved. The only argument to this function is the value the value from
 * the last promise.
 * @param {Function} options.fail - a function that is called when the last promise is
 * failed. The only
 * argument to this function is the error from the last promise.
 * @param {Function} options.cancel - a function that is called when the last promise is canceled.
 * No arguments are passed in. This function will be called not early than the next
 * scheduler iteration, after a call to process handle's cancel() function.
 * @returns {Function} - the "guarded" function. See description.
 */
export function withLastPromise<T>(options: WithLastPromiseOptions<T>): (...args: any[]) => { cancel: () => void } {
  const {
    promiseFactory, finish, cancel = _.noop, fail = _.noop,
  } = options;
  let lastPromise: Promise<T>;
  let lastPromiseCanceled = false;

  const finishProcess = (error: Error | undefined, result?: T) => {
    if (lastPromiseCanceled) {
      cancel();
    } else if (error) {
      fail(error);
    } else {
      finish(result);
    }
  };

  const guardedFunction = (...factoryArgs: any[]) => {
    lastPromise = promiseFactory(...factoryArgs);
    lastPromiseCanceled = false;
    const processHandle = ((promise: Promise<T>) => {
      promise.then((result) => {
        if (promise === lastPromise) {
          finishProcess(undefined, result);
        }

        // skipping the results, since a new promise has started since
        // or the last one was canceled
      }, (error) => {
        if (promise === lastPromise) {
          finishProcess(error);
        }

        // skipping the error, since a new promise has started since
        // or the last one was canceled
      });

      return {
        cancel() {
          lastPromiseCanceled = true;
        },
      };
    })(lastPromise);

    return processHandle;
  };

  return guardedFunction;
}

enum ASYNC_STATUS {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  CANCELLED = 'CANCELLED',
};

type Callbacks<T> = {
  onSuccess?: (result: T) => void,
  onError?: (err: Error) => void,
  onCancel?: () => void,
};

type PromiseChainItem<T> = {
  resolve: (result: T) => Promise<T>,
  reject: null | ((result: Error) => Promise<T>)
};

type Resolvable<R> = R | PromiseLike<R>;

type Options = {
  cancelCallback?: () => void,
};

/**
 * This function returns an async token, which allows the caller to cancel a promise.
 * The token behaves in the following way:
 *  - The token is created using the function, however needs to be finalizes with .on().
 *  - Only one of the callbacks will ever be called (onCancel, onSuccess, onError)
 *  - If the token is cancelled, as long as the promise hasn't been resolved,
 *    onCancel will be synchronously called and all references will be released.
 * @param {Object} promise - the promise
 * @param {Object} options - the options to create async token
 * @param {Function} options.cancelCallback
 * This callback is intended to be provided by a component that returns async token
 * from one of its interface method, and helps handle cancellation specific to the component
 * @returns {Object} asyncToken - the cancelable async token
 * @returns {Function} asyncToken.chain - (resolve, reject)
 * Allows the user to chain more async tasks,
 * similar to .then(resolve, reject), however will be cancelable by the token.
 * @returns {Function} asyncToken.on - ({onCancel, onSuccess, onError})
 * finalizes the token with three callbacks.
 * Only one will ever be called. If no onError function is submitted, it will re-throw any errors.
 * the last promise.
 * @returns {Function} asyncToken.cancel - if promise has resolved, will be a noop.
 * Otherwise will stop any more tasks or callbacks from being called,
 * and will synchronously call onCancel callback.
 */
export function createAsyncToken<T>(promise: null | PromiseLike<T>, options?: Options) {
  if (!promise) {
    throw new Error('promise is a required parameter');
  }

  // declare scoped variables
  let currentPromise: null | PromiseLike<T> = promise;
  let promiseChain: PromiseChainItem<T>[] = [];
  let callbacks: Callbacks<T> | null = null;

  // Implement functions
  function queueNextWork() {
    (<Promise<T>>currentPromise).then((result) => {
      finishProcess(ASYNC_STATUS.SUCCESS, result);
    }, (error) => {
      finishProcess(ASYNC_STATUS.ERROR, error);
    });
  };

  function dequeuePromise(status: ASYNC_STATUS, result?: T | Error) {
    const { resolve, reject } = <PromiseChainItem<T>>promiseChain.shift();

    if (status === ASYNC_STATUS.ERROR) {
      if (reject) {
        currentPromise = reject(<Error>result);
      } else {
        // if no reject function was provided, rerun finishProcess, don't call queueNextWork
        finishProcess(status, result);
        return;
      }
    } else {
      currentPromise = resolve(<T>result);
    }

    queueNextWork();
  };

  function finishProcess(status: ASYNC_STATUS, result?: T | Error) {
    if (callbacks == null) {
      // Noop
      return;
    }
    const {
      onSuccess = _.noop,
      onError = (err: Error) => { throw err; },
      onCancel = _.noop,
    } = callbacks;

    if (status === ASYNC_STATUS.CANCELLED) {
      cleanup();
      onCancel();
    } else if (!_.isEmpty(promiseChain)) {
      dequeuePromise(status, result);
    } else if (status === ASYNC_STATUS.ERROR) {
      cleanup();
      onError(<Error>result);
    } else {
      cleanup();
      onSuccess(<T>result);
    }
  }

  function cleanup() {
    // cleanup all scoped references to unblock garbage collector
    callbacks = null;
    promiseChain = [];
    currentPromise = null;
  }

  const asyncToken = {
    chain(resolve: () => Resolvable<T>, reject: null | (() => Resolvable<T>) = null) {
      if (!_.isNull(callbacks)) {
        throw new Error('Cannot call .chain after calling .on');
      }

      // wrap callbacks to allow async
      promiseChain.push({
        resolve: Promise.method<T>(resolve),
        reject: reject == null ? null : Promise.method<T>(reject),
      });
      return asyncToken;
    },
    on({
      onSuccess,
      onError,
      onCancel,
    }: Callbacks<T>) {
      if (!_.isNull(callbacks)) {
        throw new Error('Cannot call .on multiple times');
      }

      callbacks = {
        onSuccess,
        onError,
        onCancel,
      };

      // .on will finalize the token, begin work
      queueNextWork();
      return asyncToken;
    },
    cancel() {
      options && options.cancelCallback && options.cancelCallback();
      finishProcess(ASYNC_STATUS.CANCELLED);
      return asyncToken;
    }
  };

  return asyncToken;
}
