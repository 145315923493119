import _ from 'underscore';
import { Formatter } from '@bingads-webui/listener';

const bracketedParamsRegex = new RegExp(/\([^)]*\)/g);

export class ApiFormatter extends Formatter {
  /* eslint-disable no-param-reassign */
  convertAbsoluteToRelativePath(log) {
    const path = log.Api.toLowerCase();

    if ((path.indexOf('http://') !== 0) && (path.indexOf('https://') !== 0)) {
      return log;
    }

    const a = document.createElement('a');

    a.href = path;
    log.Api = a.pathname;
    if (log.Api.indexOf('/') === 0 && log.Api.length > 1) {
      log.Api = log.Api.substring(1);
    }

    return log;
  }

  removeQueryParameters(log) {
    [log.Api] = log.Api.split('?');
    return log;
  }

  removeDotMExtension(log) {
    log.Api = log.Api.replace('.m/', '/');
    if (log.Api.substr(-2) === '.m') {
      log.Api = log.Api.substr(0, log.Api.length - 2);
    }
    return log;
  }

  removeBracketedParameters(log) {
    log.Api = log.Api.replace(bracketedParamsRegex, '');
    return log;
  }

  removeApplicationNameFromApi(log) {
    const appName = `${log.As}/`.toLowerCase();

    if (log.Api.toLowerCase().indexOf(appName.toLowerCase()) === 0) {
      log.Api = log.Api.substring(appName.length);
    }
    return log;
  }

  format(log) {
    if (!_.isEmpty(log.Api)) {
      log = _.chain(log)
        .tap(this.removeQueryParameters)
        .tap(this.removeDotMExtension)
        .tap(this.convertAbsoluteToRelativePath)
        .tap(this.removeBracketedParameters)
        .tap(this.removeApplicationNameFromApi)
        .value();
    }
    return log;
  }
}
