import { ZIndexShellComponents } from '../constants';

import { getBlockerStyle, spinnerKeyframe } from './spinner-style';
// this style is in the body scope, out of the feature scope, so use as the global pattern
export const uiblockerStyle = ({ palette = {} }) => (
  Object.assign({
    '.blocker': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(255,255,255,0.4)',
    },

    '.blocker-global, .blocker-local': {
      backgroundColor: 'rgba(255,255,255,0.4)',
      position: 'absolute',
      zIndex: ZIndexShellComponents.Loader,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '.blocker-global': {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },

    '.blocker, .blocker-global, .blocker-local': {
      '& .blocker-indicator': {
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: '10px',
        color: palette.themePrimary,
        top: 'calc(50% - 24px)',
        left: 'calc(50% - 58px)',
        position: 'absolute',

        '& .blocker-image': getBlockerStyle(28, palette),
      },
    },
  }, spinnerKeyframe));

// blocker defined in $.advertiser
export const legacyUIBlockerStyle = ({ palette = {} }) => ({
  '.blocker-panel': {
    position: 'absolute',
    backgroundColor: 'rgba(255,255,255,0.4)',
  },
  '.blocker-panel + .blocker-indicator': {
    outline: 'none',
    backgroundColor: 'white',
    padding: '10px',
    color: palette.themePrimary,
    position: 'absolute',

    '& .blocker-image': {
      ...getBlockerStyle(28, palette),
      position: 'absolute',
    },
    '& .blocker-text': {
      margin: '5px 0 0 40px',
    },
  },
});

// for @bingads-webui-react/ui-blocker
export const reactUIBlockerStyle = (palette, backgroundColor = 'white') => ({
  '& .react-block-ui': {
    '& > div:last-child': {
      alignItems: 'center',
      backgroundColor,
      color: palette.themePrimary,
      display: 'flex',
      outline: 'none',
      padding: 10,

      '& .blocker-text': {
        fontWeight: 'normal',
      },

      '& .blocker-image': getBlockerStyle(28, palette),
    },
  },
});
