import _ from 'underscore';
import StackTrace from 'stacktrace-js';
import Promise from 'bluebird';
import { schema } from './error-stub-schema';

function getStackStringFromStackFrames(stackFrames) {
  const stackLines = _.map(stackFrames, frame => [
    frame.getFunctionName() || '<anonymous>', ' (',
    frame.getFileName(), ':',
    frame.getLineNumber(), ':',
    frame.getColumnNumber(), ')',
  ].join('')).join('\n');

  return stackLines;
}

// By default, stacktrace-js loads source maps synchronously. This is not good for performance.
export function asyncAjaxOverride(url) {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.open('get', url, true); // Third parameter true for async call
    req.onerror = reject;
    req.onreadystatechange = function onreadystatechange() {
      if (req.readyState === 4) {
        if ((req.status >= 200 && req.status < 300) ||
          (url.substr(0, 7) === 'file://' && req.responseText)) {
          resolve(req.responseText);
        } else {
          reject(new Error(`HTTP status: ${req.status} retrieving ${url}`));
        }
      }
    };
    req.send();
  });
}

/**
 * ErrorStub class - Creates a window.onerror stub that can be used by Instrumentation
 */
export class ErrorStub {
  /**
   * @param {object} options - contains instrumentation for logging
   */
  constructor(options = {}) {
    // create scenario for logging
    this.setupScenario(options.instrumentation);
    this.logUnhandledPromiseRejections = options.logUnhandledPromiseRejections || false;
    this.skipStackTraceResolutionForPromiseRejections = options.skipStackTraceResolutionForPromiseRejections;

    this.isExpectedUnhandledPromiseError = options.isExpectedUnhandledPromiseError;
    this.stackTraceOptions = {
      ajax: asyncAjaxOverride, // by default it uses synchroneous ajax
    };
  }

  init() {
    this.intercept();
  }

  intercept() {
    window.onerror = (errorMsg, url, lineNumber, column, errorObj) => {
      const activity = this.errorStubScenario.errorStubActivity.create();

      this.handleError(errorMsg, url, lineNumber, column, errorObj, activity);

      return true;
    };

    if (this.logUnhandledPromiseRejections) {
      window.addEventListener('unhandledrejection', (promiseRejection) => {
        const activity = this.errorStubScenario.errorStubActivity.create();

        this.handlePromiseRejection(promiseRejection, activity);
      });
    }
  }

  handleError(errorMsg, url, lineNumber, column, errorObj, activity) {
    let errorMessage = `${errorMsg}\tScript: ${url} Line: ${lineNumber}`;
    if (!_.isUndefined(column)) {
      errorMessage += ` Column: ${column}`;
    }
    return StackTrace.fromError(errorObj, this.stackTraceOptions).then((stackFrames) => {
      errorMessage += ` StackTrace: ${getStackStringFromStackFrames(stackFrames)}`;
    }).catch((ex) => {
      if (_.isObject(errorObj) && _.has(errorObj, 'stack')) {
        errorMessage += ` StackTrace: ${errorObj.stack}\n [StackTrace Error: ${ex}]`;
      }
    }).finally(() => {
      errorMessage += `\tCurrentURL: ${window.location.href}\tPreviousURL: ${document.referrer ? document.referrer : 'Unknown'}`;

      const perviousRoute = window.history.state && window.history.state.state ?
        window.history.state.state.previousRoute : null;

      if (perviousRoute) {
        errorMessage += `\tPreviousRoute: ${perviousRoute}`;
      }

      activity.error(errorMessage, 'onerror');
    });
  }

  handlePromiseRejection(promiseRejectionEvent, activity) {
    let errorMessage = 'Unhandled promise rejection;';

    const logError = (message) => {
      if (this.isExpectedUnhandledPromiseError && this.isExpectedUnhandledPromiseError(message)) {
        activity.trace(message, 'onunhandledrejection');
      } else {
        activity.error(message, 'onunhandledrejection');
      }
      return Promise.resolve();
    };

    // Bluebird stores these properties in .detail while native promise stores these properties at top level
    const { promise, reason } = promiseRejectionEvent.detail || promiseRejectionEvent;

    // Reason is an Error-like object
    if (reason && (reason.message || reason.stack)) {
      const { message, stack } = reason;
      if (this.skipStackTraceResolutionForPromiseRejections) {
        errorMessage += ` Message: ${message}; StackTrace: ${stack}`;
        return logError(errorMessage);
      }

      return StackTrace.fromError(reason, this.stackTraceOptions).then((stackFrames) => {
        errorMessage += ` Message: ${message}; StackTrace: ${getStackStringFromStackFrames(stackFrames)}`;
      }).catch((ex) => {
        errorMessage += `${message} at ${stack}\n [StackTrace Error: ${ex}]`;
      }).finally(() => {
        logError(errorMessage);
      });
    }

    // Use bluebird's stack trace if available
    errorMessage += `Non error object thrown; Reason: ${JSON.stringify(reason)}; StackTrace: ${_.get(promise, '_trace.stack')};`;
    logError(errorMessage);
    return Promise.resolve();
  }

  setupScenario(instr) {
    instr.addScenario(schema);
    this.errorStubScenario = instr.errorStubScenario.create(instr.ScenarioContext);
  }
}
