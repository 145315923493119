export const fabricPanelStyle = () => ({
  footer: {
    background: 'white',
    zIndex: 1,
  },
  commands: {
    marginTop: 0,
    marginBottom: 0,
    zIndex: 1,
  },
});
