import { include } from 'underscore.string';

const TOKENS = {
  // NewTenantTest database for local debugging
  Local: 'c2048c8ee1c7466fa53ff77880fc797c-3d99112e-5e15-4022-b9a5-f53983937f76-6526',
  // Test: SI/TIP
  Aria3: 'f37eb78c41a442f4ba7e4d0b3660d938-954f8a2a-10e1-4c9b-b467-6a966e9994c9-7308',
  // PROD
  Aria07: '9cf7b68164b34e7e995562121503c72a-72856c17-5747-49e6-bd96-fde5bb08a4f4-7006',
  // AdInsight
  AdInsight: '2b2318c0ebbf44808a5e789256fab332-cd52b35b-3679-4c06-8958-fae3a1646c7c-7279',
};

const allTokens = {
  // Local
  'ccui.devdev.bingads.microsoft.com': TOKENS.Local,
  'redmond.corp.microsoft.com': TOKENS.Local,

  // Test
  'app1.ads-int.microsoft.com': TOKENS.Aria3,
  'ui.si.bingads.microsoft.com': TOKENS.Aria3,
  'ui.beta.si.bingads.microsoft.com': TOKENS.Aria3,
  'ui.ads-int.microsoft.com': TOKENS.Aria3,
  'ui.beta.ads-int.microsoft.com': TOKENS.Aria3,
  'ui.tip.bingads.microsoft.com': TOKENS.Aria3,
  'ui.tip.ads.microsoft.com': TOKENS.Aria3,
  'si.bingads.microsoft.com': TOKENS.Aria3,
  'beta.si.bingads.microsoft.com': TOKENS.Aria3,
  'ads-int.microsoft.com': TOKENS.Aria3,
  'beta.ads-int.microsoft.com': TOKENS.Aria3,
  'tip.bingads.microsoft.com': TOKENS.Aria3,
  'tip.ads.microsoft.com': TOKENS.Aria3,

  'ui.sandbox.bingads.microsoft.com': TOKENS.Aria3,
  'ui.sandbox.ads.microsoft.com': TOKENS.Aria3,

  // Prod
  'ui.bingads.microsoft.com': TOKENS.Aria07,
  'ui.beta.bingads.microsoft.com': TOKENS.Aria07,
  'ui.ads.microsoft.com': TOKENS.Aria07,
  'ui.beta.ads.microsoft.com': TOKENS.Aria07,
  'bingads.microsoft.com': TOKENS.Aria07,
  'beta.bingads.microsoft.com': TOKENS.Aria07,
  'ads.microsoft.com': TOKENS.Aria07,
  'beta.ads.microsoft.com': TOKENS.Aria07,


  // AdLibrary
  'adlibraryui-test.azureedge.net': TOKENS.Aria3,
  'adlibrary.ads.microsoft.com': TOKENS.Aria07,

  // AdInsight
  // TODO: move out once adinsights use shared listener
  adInsightOppTracking: TOKENS.AdInsight,
};

const defaultConfig = {
  // Note: keeping this for backward compatability
  tokens: {
    'ui.si.bingads.microsoft.com': TOKENS.Aria3,
    'ui.beta.si.bingads.microsoft.com': TOKENS.Aria3,
    'ui.bingads.microsoft.com': TOKENS.Aria07,
    'ui.beta.bingads.microsoft.com': TOKENS.Aria07,
    'ui.tip.bingads.microsoft.com': TOKENS.Aria3,
    adInsightOppTracking: TOKENS.AdInsight, // TODO: move out once adinsights use shared listener
    'redmond.corp.microsoft.com': TOKENS.Local,
    // Rebranded domain mappings
    'ui.ads-int.microsoft.com': TOKENS.Aria3,
    'ui.beta.ads-int.microsoft.com': TOKENS.Aria3,
    'ui.ads.microsoft.com': TOKENS.Aria07,
    'ui.beta.ads.microsoft.com': TOKENS.Aria07,
    'ui.tip.ads.microsoft.com': TOKENS.Aria3,
    'ui.sandbox.bingads.microsoft.com': TOKENS.Aria3,
    'ui.sandbox.ads.microsoft.com': TOKENS.Aria3,
    // AdLibrary
    'adlibraryui-test.azureedge.net': TOKENS.Aria3,
    'adlibrary.ads.microsoft.com': TOKENS.Aria07,
  },
  flushInterval: 3000,
  batchSize: 40,
};

export function getTokensForSmartPage(env, token) {
  let spToken = token;
  if (include(env, 'biz.site') || include(env, 'beta.smartpage.microsoft.com')) {
    spToken = TOKENS.Aria07;
  } else if (include(env, 'si.smartpage.microsoft.com')) {
    spToken = TOKENS.Aria3;
  } else if (include(env, 'ci.smartpage.microsoft.com')) {
    spToken = TOKENS.Local;
  }

  return spToken;
}

export default function getAriaConfig(env = null) {
  let token = allTokens[env];
  if (include(env, 'redmond.corp.microsoft.com')) {
    token = TOKENS.Local;
  } else if (include(env, 'biz.site') || include(env, 'smartpage')) {
    token = getTokensForSmartPage(env, token);
  }

  return {
    token,
    ...defaultConfig,
  };
}
