import _ from 'underscore';

/**
 * LogTransmitter class responsible for transmitting logs to listeners
 */
export class LogTransmitter {
  /**
   * @param {object} options - has listeners, optional processors and scenarioContext
   */
  constructor(options = {}) {
    this.listeners = options.listeners;
    this.processors = options.processors;
    this.scenarioContext = options.scenarioContext;
  }

  /**
   * Updates shared scenario context every time we write, so it can be read(as parentScenario)
   * by internal activities when writing logs
   * @param {object} rawLogs - the raw logs being transmitted to the listeners
   * @returns {undefined} - no returns
   */
  updateScenarioContext(rawLogs) {
    // Scenario is marked hidden for non user defined scenarios
    const isHiddenScenario = _.result(rawLogs.scenario.setting, 'hidden');
    if (!isHiddenScenario) {
      this.scenarioContext.id = rawLogs.scenario.id;
      this.scenarioContext.value = rawLogs.scenario.value;
    }
  }

  transmit(rawLogs) {
    if (this.stopped) {
      return;
    }

    if (_.has(rawLogs, 'scenario')) {
      this.updateScenarioContext(rawLogs);
    }

    // Pass the rawLogs through each procesor to enrich them
    let processedLogs = rawLogs;
    _.each(this.processors, (processor) => {
      processedLogs = processor.process(processedLogs);
    });

    _.each(this.listeners, listener => listener.write(processedLogs));
  }

  /**
   * Stop the log transmitter service by tearing down the listeners
   * @returns {undefined} - no returns
   */
  stop() {
    _.each(this.listeners, (listener) => {
      listener.teardown();
    });
    this.stopped = true;
  }
}
