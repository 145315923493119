import _ from 'underscore';

/**
 * ScenarioProcessor class responsible for enriching logs with scenario/activity information
 */
export class ScenarioProcessor {
  process(rawLogs) {
    if (!_.has(rawLogs, 'scenario')) {
      return rawLogs;
    }

    let logs = _.extend(rawLogs, {
      activityId: rawLogs.activity.id,
      activityName: rawLogs.activity.value,
      scenarioId: rawLogs.scenario.id,
      scenarioName: rawLogs.scenario.value,
      parentScenarioId: rawLogs.parentScenario && rawLogs.parentScenario.id,
      parentScenarioName: rawLogs.parentScenario && rawLogs.parentScenario.value,
    });

    // drop activity/scenario/parentScenarioScenario since we've already extracted the needed info
    logs = _.omit(logs, 'activity', 'scenario', 'parentScenario');

    return logs;
  }
}
