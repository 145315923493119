import { AriaPropertyFormatter } from './formatters/log-property-formatter';

const propertyFormatter = new AriaPropertyFormatter();

export const defaultConfig = {
  formatters: { propertyFormatter },
  enableSessionTracking: true,
  flushInterval: 5000,
  batchSize: 50,
};
