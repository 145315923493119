export const getNewFeatureLabelStyle = ({ palette = {} }) => ({
  '& .new-feature-label': {
    color: palette.white,
    border: `1px solid ${palette.white}`,
    padding: '0 9px',
    fontSize: '12px',
    borderRadius: '12px',
    backgroundColor: palette.orange,
    marginLeft: '5px',
  },
});
