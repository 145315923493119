import _ from 'underscore';

export const getStyles = ({ blockUi = {} }, { color = {} }) => {
  const defaultReactBlockUi = {
    position: 'relative',
    minHeight: '2em',
  };

  const defaultBlockUiContainer = {
    position: 'absolute',
    backgroundColor: 'rgba(51, 51, 51, 0.15)',
    zIndex: 1010,
    textAlign: 'center',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    minHeight: '2em',
    cursor: 'wait',
    overflow: 'hidden',
    '&:focus': {
      outline: 'none',
    },
  };

  const defaultBlockUiOverlay = {
    width: '100%',
    height: '100%',
  };

  const defaultBlockerIndicator = {
    marginTop: '-55px',
    padding: '20px',
    top: '45%',
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: 'white',
    maxWidth: '370px',
    minWidth: '140px',
    minHeight: '70px',
    zIndex: 1011,
    '&:hover &:focus': {
      border: {
        color: _.result(color.light, 'dark30'),
      },
    },
  };

  return {
    blockUiContainer: _.extend(defaultBlockUiContainer, blockUi.blockUiContainer),
    reactBlockUi: _.extend(defaultReactBlockUi, blockUi.reactBlockUi),
    blockUiOverlay: _.extend(defaultBlockUiOverlay, blockUi.blockUiOverlay),
    blockerIndicator: _.extend(defaultBlockerIndicator, blockUi.blockerIndicator),
  };
};
