export const getSharedTableStyles = (components, { palette }) => ({
  '&': {
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'none',
  },
  '& .glyphicon': {
    position: 'static',
  },

  '& .iconba': {
    position: 'static',
  },

  '& .grid-disabled': {
    color: palette.neutralSecondaryAlt,
  },
});
