import _ from 'underscore';

export class DebugProcessor {
  constructor({ isDebugMode }) {
    this.isDebugMode = isDebugMode;
  }

  process(rawLogs) {
    return _.extend(rawLogs, {
      isDebugMode: this.isDebugMode,
    });
  }
}
