export const buttonInputStyle = ({ palette = {} }) => ({
  '& input.btn-primary[type="button"], input.btn-primary[type="submit"], input.btn-hero[type="button"]': {
    '&:focus': {
      boxShadow: `inset 0 0 0 1px ${palette.white}`,
      border: `2px solid ${palette.themePrimary}`,
    },
  },

  '& input.btn-default[type="button"]': {
    '&:focus': {
      outline: `1px solid ${palette.neutralSecondaryAlt}`,
      boxShadow: `inset 0 0 0 1px ${palette.neutralSecondary}`,
      outlineOffset: 0,
      border: `2px solid ${palette.white}`,
    },
  },
});
