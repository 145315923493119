import _ from 'underscore';

export class PerfTimingProcessor {
  constructor({ landingPageUrl = '' }) {
    this.landingPageUrl = landingPageUrl;
  }

  process(rawLogs) {
    return _.extend(rawLogs, {
      href: window.location.href,
      referrer: document.referrer,
      landingPg: this.landingPageUrl,
    });
  }
}
