export const dropDownEditorStyle = ({ palette = {} }) => ({
  '& .grid-editable-cell select.editor-control.select-editor': {
    borderWidth: '0px 0px 1px 0px',
    borderBottomColor: 'transparent',
    paddingBottom: 8,
    paddingTop: 8,
    '&:hover, &:focus, &:active': {
      borderBottomColor: 'unset',
      outline: 'none',
    },
    '&:active': {
      borderBottomColor: palette.themePrimary,
    },
  },
});
