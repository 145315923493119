import { getNewFeatureLabelStyle } from '../new-feature-label-style';

export const getColumnChooserOverrideStyle = palette => ({
  // Shared part already moved to 'global-styles.js' to be shared with all top of grid panel.
  // Only put column chooser specific override here.
  '& .column-chooser': {
    display: 'block !important',
    lineHeight: '15px',
    fontWeight: 'normal',

    '& .panel': {
      '& .panel-body': {
        '& .column-chooser-selection-area': {
          padding: '0px 0px 18px 0px',

          '& .column-chooser-option': {
            backgroundColor: 'white',
            border: `1px solid ${palette.neutralTertiary}`,
            ...getNewFeatureLabelStyle({ palette }),

            '& .option': {
              height: '36px',
              display: 'flex',
              alignItems: 'center',
            },

            '& .column-chooser-option-header': {
              display: 'none',
            },

            '& .column-chooser-categories': {
              left: 0,
              width: '300px',

              '& .category-tabs': {
                left: 0,
                width: '300px',

                '& li a:after': {
                  fontFamily: '"MsAds MDL2 Assets"',
                  content: '"\\E76C"',
                  color: palette.neutralSecondary,
                  float: 'right',
                },

                '& > li': {
                  padding: '10px 8px 10px 10px',

                  '& .new-feature-label': {
                    display: 'inline-block',
                    position: 'absolute',
                  },
                },

                '& > li.active': {
                  fontWeight: 'normal',
                  border: 0,
                  backgroundColor: palette.neutralLight,
                  '& > a.currentActive': {
                    color: palette.themeDarker,
                  },
                },
              },
            },

            '& .options-view .column-chooser-option-list': {
              border: '0px',
              borderLeft: `1px solid ${palette.neutralLight}`,
              width: '300px',
              height: '282px',

              '& .column-chooser-bulk-control': {
                padding: '10px 8px 9px 10px',
                minHeight: '38px',

                '& .arrow-button': {
                  display: 'none',
                },
              },

              '& ul.column-chooser-options': {
                height: '243.6px',

                '& span.option-not-selected': {
                  fontWeight: 'normal',
                },

                '& span.option-name': {
                  display: 'inline-block',
                  maxWidth: '165px',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                },

                '& .option-status, & a.add-column-link': {
                  marginLeft: 'auto',
                },
              },

              '& .column-chooser-editable-picker': {
                height: '282px',
                display: 'flex',
                flexDirection: 'column',
                '& .custom-column-remove': {
                  margin: '0px 10px',
                },
                '& .glyphicon-pencil': {
                  textDecoration: 'none',
                  '&:focus': {
                    outline: `1px solid ${palette.neutralSecondary}`,
                  },

                  '&:before': {
                    fontFamily: 'MsAds MDL2 Assets',
                    content: '"\\E70F"',
                    color: palette.themeDark,
                  },
                },
                '& .glyphicon-remove': {
                  textDecoration: 'none',
                  '&:focus': {
                    outline: `1px solid ${palette.neutralSecondary}`,
                  },

                  '&:before': {
                    fontFamily: 'MsAds MDL2 Assets',
                    content: '"\\E711"',
                  },
                },
                '& span.option-status.enabled': {
                  marginLeft: 'auto',
                },
                '& a.add-column-link': {
                  marginLeft: 'auto',
                },
                '& .inapplicable-column': {
                  marginLeft: 'auto',
                },
                '& .column-chooser-bulk-control': {
                  padding: '0',
                  minHeight: '38px',
                  display: 'flex',
                  justifyContent: 'space-between',

                  '& .custom-column-add': {
                    margin: '8px 8px 0px',
                  },

                  '& .remove-all-in-list, .add-all-in-list': {
                    padding: '10px 8px 9px 10px',
                  },
                },
                '& .invalid-custom-column:focus': {
                  outline: `1px solid ${palette.neutralSecondary}`,
                },
                '& .column-chooser-options': {
                  flex: '1 1 1px',
                  margin: '0',
                  '& .option': {
                    display: 'flex',
                    alignItems: 'center',
                    '& .option-name': {
                      flex: '1',
                    },
                  },
                },
              },
            },
          },

          '& .column-chooser-selected': {
            border: `1px solid ${palette.neutralTertiary}`,
            marginLeft: '8px',
            width: '300px',
            height: '334px',
            display: 'flex',
            flexDirection: 'column',

            '& .column-chooser-selected-header': {
              backgroundColor: 'white',
              padding: '10px 0px 10px 10px',
              width: '298px',
            },

            '& .column-chooser-selected-header:before': {
              content: 'Test new header',
              fontWeight: 'bold',
            },

            '& .column-chooser-selected-header:after': {
              content: 'Test new subheader',
            },

            '& .remove-all-in-selected-list': {
              marginLeft: 'auto',
              float: 'right',
              display: 'inline-block',
              paddingTop: '10px',
              paddingRight: '10px',
            },

            '& .column-chooser-selected-list': {
              flex: '1',
              overflow: 'auto',
              '& .draggable-list-view': {
                margin: 0,
                height: '100%',
                width: '298px',
                border: 0,

                '& ul.draggable-list': {
                  padding: 0,
                  listStyle: 'none',
                  height: '100%',
                  overflow: 'auto',
                  marginBottom: '0',

                  '& li.draggable-list-item': {
                    border: '1px solid transparent',
                    padding: '1px 0',
                    outline: 'none',

                    '&:focus': {
                      border: `1px solid ${palette.neutralTertiary}`,
                    },

                    '& .selected-list-item': {
                      verticalAlign: 'middle',
                      display: 'flex',
                      alignItems: 'flex-start',

                      '& .selected-item-name': {
                        flex: '1',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      },

                      '& .trigger-icon': {
                        color: '#797775',
                        fontFamily: '"MsAds MDL2 Assets"',
                        lineHeight: '19px',
                        padding: '0px 8px',
                        position: 'relative',

                        '&:after': {
                          color: palette.themePrimary,
                          content: '"\\E76F"',
                          fontSize: '20px',
                        },
                      },
                    },

                    '&.selected, &:focus, &:hover': {
                      '& .trigger-icon:before': {
                        content: '"\\F090\\F08E"',
                        fontSize: '14px',
                        letterSpacing: '3px',
                        marginTop: '-6px',
                        position: 'absolute',
                        writingMode: 'vertical-lr',
                      },
                    },

                    '&.selected': {
                      backgroundColor: palette.neutralLight,

                      '& .trigger-icon:before': {
                        color: palette.neutralPrimary,
                      },
                    },
                  },

                  '& li.draggable-list-item.draggable-list__item-locked': {
                    height: '36px',
                    padding: '0px',
                    backgroundColor: 'white',

                    '& .selected-list-item': {
                      margin: '8px 8px 9px 36px',
                    },
                  },

                  '& li.draggable-list-item.draggable-list__item-draggable': {
                    height: '36px',
                    padding: '0px',
                    backgroundColor: 'white',

                    '& .selected-list-item': {
                      margin: '8px 8px 9px 0px',
                    },
                  },
                },
              },
            },
          },
        },

        '& .save-column-view': {
          '& .save-checkbox': {
            '& label+input': {
              marginLeft: '13px',
            },

            '& .column-chooser-save-column': {
              float: 'left',

              '& .save-column-checkbox': {
                height: '20px',
                width: '20px',
              },
              '& input[type="checkbox"]': {
                marginRight: '8px',
              },
            },

            '& .column-chooser-saved-name': {
              height: '32px',
              width: '250px',
            },
          },
        },
      },
    },
  },
});
