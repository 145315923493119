/* eslint-disable no-unused-vars */
export const datePickerStyle = ({ palette = {}, iconFontFamily = 'MsAds MDL2 Assets', focusedStyle }) => ({
  '.k-animation-container': {
    width: '220px !important',
    height: 'auto !important',
    background: palette.white,
    boxShadow: '0px 0.6px 1.8px rgba(0, 0, 0, 0.108), 0px 3.2px 7.2px rgba(0, 0, 0, 0.132)',
    borderRadius: 2,

    '& .k-calendar-container': {
      position: 'relative !important',
      padding: 12,
      width: '100%',
      height: '100%',

      '& .k-link:hover': {
        backgroundColor: `${palette.neutralLight} !important`,
        color: `${palette.neutralDark} !important`,
      },

      '& .k-header': {
        width: '100%',
        paddingBottom: 4,
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',

        '& .k-nav-fast': {
          order: '1',
          flex: '1',
          lineHeight: '19px',
          fontWeight: 600,
          textAlign: 'left',
          display: 'flex',
          paddingTop: 4,
          paddingBottom: 5,
          color: palette.neutralPrimary,
        },

        '& .k-nav-prev': {
          order: '2',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        '& .k-nav-next': {
          order: '3',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },

        '& .k-icon': {
          fontFamily: iconFontFamily,
          fontSize: 12,
          lineHeight: '12px',
          width: 28,
          height: '28px',
          padding: 8,
          color: palette.neutralSecondary,
        },

        '& .k-i-arrow-w:before': {
          content: '"\\E74A"',
        },

        '& .k-i-arrow-e:before': {
          content: '"\\E74B"',
        },
      },

      '& .k-content': {
        '& th, td': {
          width: 28,
          height: '28px',
          textAlign: 'center',
          fontWeight: 'normal',
          background: palette.white,
          borderRadius: 2,
          lineHeight: '16px',
          fontSize: 12,

          '& a': {
            color: '#212121',
            display: 'flex',
            width: '28px',
            height: '28px',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },

        '& .k-link': {
          background: palette.neutralLighter,
          borderRadius: '2px',
        },

        '& .k-other-month .k-link': {
          background: palette.white,
        },

        '& .k-state-selected': {
          '& .k-link': {
            // outline will only be visible when media query (forced-colors: active)
            outline: '1px dashed transparent',
            background: palette.themePrimary,
            fontWeight: 600,
            color: palette.white,
          },
        },

        '& .k-today': {
          '& .k-link': {
            // outline will only be visible when media query (forced-colors: active)
            outline: '1px dotted transparent',
            background: palette.themeLighter,
            fontWeight: 600,
            color: palette.neutralDark,
          },
        },

        '& .k-state-focused': {
          '& .k-link': focusedStyle || {
            // outline will only be visible when media query (forced-colors: active)
            outline: '1px solid transparent',
            background: palette.themePrimary,
            fontWeight: 600,
            color: palette.white,
          },
        },
      },

      '& .k-meta-view': {
        '& tr': {
          '& td': {
            background: `${palette.white} !important`,

            '& a': {
              display: 'flex',
              color: `${palette.neutralPrimary} !important`,
              width: '36px',
              height: '36px',
              marginBottom: 20,
              marginRight: 17,
              alignItems: 'center',
              justifyContent: 'center',
            },
          },

          '& td:last-child': {
            '& a': {
              marginRight: 0,
            },
          },
        },

        '& .k-state-focused': {
          '& a': {
            color: `${palette.neutralDark} !important`,
            background: palette.themeLighter,
          },
        },
      },
    },
  },
});
