/* eslint-env browser */
// this file depends on Web API atob, Blob, File, it should not be in this project
import _ from 'underscore';

export function dataURItoBlob(dataURI: string) {
  const reg = /:(.+?);/;
  const arrs = dataURI.split(',');
  const header = <string>_.first(arrs);
  const content = <string>_.last(arrs);
  let type = 'image/png';

  const byteString = atob(content);
  const array = new Uint8Array(byteString.length);

  for (let i = 0; i < byteString.length; i += 1) {
    array[i] = byteString.charCodeAt(i);
  }
  if (reg.test(header)) {
    ({ 1: type } = <string[]>header.match(reg));
  }
  return new Blob([array], { type });
}

/**
 * Convert image string to File object
 * @param {string} img image string to be convertImage2File
 * @param {string} name file name of image
 * @returns {File} File object
 */
export function convertImage2File(img: string, name: string): File {
  const blob = dataURItoBlob(img);
  const postfix = _.last(blob.type.split('/'));
  return new File([blob], `${name}.${postfix}`, { type: blob.type, lastModified: Date.now() });
}
