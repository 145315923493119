import _ from 'underscore';

const schema = {
  scenario: [
    {
      name: 'perfMarker',
      value: 'PerfMarker',
      setting: {},
      activity: [
        {
          name: 'sendLog',
          value: 'SendLog',
        },
      ],
    },
  ],
};

let perfScenario = null;

function getScenario(instrumentation) {
  if (_.isEmpty(perfScenario)) {
    instrumentation.addScenario(schema);
    perfScenario = instrumentation.perfMarker.create();
  }

  return perfScenario;
}

export function createActivity(instrumentation) {
  const scenario = getScenario(instrumentation);

  return scenario.sendLog.create();
}
