import $ from 'jquery';
import _ from 'underscore';
import { getTimestamp } from '@bingads-webui-universal/primitive-utilities';

/* eslint-disable max-len */

/**
 * AjaxEventHandler - contains event handlers for ajax events (beforeSend, success, error, complete)
 * The consuming application can override the handlers here by extending from this base handler
 * and pass in a custom AjaxEventHandler when instantiating the AjaxStub
 */
export class AjaxEventHandler {
  constructor(options = {}) {
    this.errorMappers = options.errorMappers || [];
    this.shouldAddHeaders = options.shouldAddHeaders || _.constant(true);
  }

  /**
   * Handler for the ajax beforeSend event.
   * @param {object} ajaxContext - data related to the current ajax call
   * @param {Array} args - args passed to the beforeSend ajax callback
   * @returns {undefined} - no returns
   */
  beforeSendHandler(ajaxContext, [jqXHR]) {
    /* eslint-disable no-param-reassign */
    ajaxContext.ajaxStartTime = getTimestamp();

    if (this.shouldAddHeaders(ajaxContext) === true) {
      const applicationName = _.result(ajaxContext.stubOptions.ajaxStubOptions, 'applicationName', 'bingadsweb');

      jqXHR.setRequestHeader('x-ms-requestid', ajaxContext.requestId);
      // eslint-disable-next-line no-unused-expressions
      ajaxContext.pageTrackingId && jqXHR.setRequestHeader('x-ms-pagetrackingid', ajaxContext.pageTrackingId);
      jqXHR.setRequestHeader('x-ms-applicationname', applicationName);
      jqXHR.setRequestHeader('x-ms-lcid', ajaxContext.stubOptions.lcid);
      jqXHR.setRequestHeader('lcid', ajaxContext.stubOptions.lcid);
    }

    ajaxContext.activity.ajax(
      ajaxContext.requestId,
      ajaxContext.url,
      true,
      ajaxContext.httpMethod,
      0,
      true,
      '',
      ajaxContext.entityCount
    );
  }

  /**
   * Handler for the ajax success event.
   * @param {object} ajaxContext - data related to the current ajax call
   * @param {Array} args - args passed to the success ajax callback
   * @returns {undefined} - no returns
   */
  successHandler(ajaxContext, [data, textStatus, jqXHR]) { // eslint-disable-line no-unused-vars
    const ajaxTimeTaken = getTimestamp() - ajaxContext.ajaxStartTime;
    const result = ajaxContext.stubOptions.detectError(data);

    if (!result.pass) {
      ajaxContext.requestResult = false;
      ajaxContext.error = result.message;

      ajaxContext.activity.error(
        ajaxContext.error,
        ajaxContext.url,
        ajaxContext.requestId,
        result.impactUser,
        ajaxContext.httpMethod
      );
    }

    ajaxContext.activity.ajax(
      ajaxContext.requestId,
      ajaxContext.url,
      false,
      ajaxContext.httpMethod,
      ajaxTimeTaken,
      ajaxContext.requestResult,
      this.constructor.getServerPerf(jqXHR),
      ajaxContext.entityCount
    );

    ajaxContext.entityCount = ajaxContext.stubOptions.getEntityCount(data);
    ajaxContext.ajaxRenderStartTime = getTimestamp();
  }

  /**
   * Handler to execute after the ajax success callback.
   * It's only purpose is to measure ajaxRender time.
   * @param {object} ajaxContext - data related to the current ajax call
   * @returns {undefined} - no returns
   */
  successRenderHandler(ajaxContext) {
    if (ajaxContext.requestResult === true) {
      const renderTimeTaken = getTimestamp() - ajaxContext.ajaxRenderStartTime;

      ajaxContext.activity.ajax(
        ajaxContext.requestId,
        ajaxContext.url,
        false,
        ajaxContext.httpMethod,
        renderTimeTaken,
        ajaxContext.requestResult,
        '',
        ajaxContext.entityCount,
        true
      );
    }
  }

  /**
   * Handler for the ajax error event.
   * This may need to be overriden by the application to handle expected errors.
   * @param {object} ajaxContext - data related to the current ajax call
   * @param {Array} args - args passed to the error ajax callback
   * @returns {undefined} - no returns
   */
  errorHandler(ajaxContext, [jqXHR, textStatus, errorThrown]) {
    const ajaxTimeTaken = getTimestamp() - ajaxContext.ajaxStartTime;

    if (jqXHR.status !== 0) {
      ajaxContext.requestResult = false;

      ajaxContext.error = `Ajax error [${jqXHR.status}]`;

      if (textStatus && $.trim(textStatus) !== '') {
        ajaxContext.error += (`, ${textStatus}`);
        // Log jqXHR.responseText when it is not 200 (which means will enter errorHandler)
        if (jqXHR.responseText && $.trim(jqXHR.responseText) !== '') {
          ajaxContext.error += (`, ${jqXHR.responseText}`);
        } else if (jqXHR.responseXML && $.trim(jqXHR.responseXML) !== '') {
          ajaxContext.error += (`, ${jqXHR.responseXML}`);
        }
      }

      if (errorThrown && $.trim(errorThrown) !== '') {
        ajaxContext.error += (`, ${errorThrown}`);
      }

      switch (jqXHR.status) {
        case 400:
        case 404:
          // If error maps to a user error, log at trace level
          if (this.isUserError(ajaxContext, jqXHR, textStatus, errorThrown)) {
            ajaxContext.requestResult = true;
          }
          break;
        case 401:
          // Log 401 unauthorized error at trace level
          ajaxContext.requestResult = true;
          break;
        default:
          ajaxContext.requestResult = false;
      }

      if (ajaxContext.requestResult === false) {
        ajaxContext.activity.error(ajaxContext.error, ajaxContext.url, ajaxContext.requestId, '', ajaxContext.httpMethod);
      } else {
        ajaxContext.activity.trace(ajaxContext.error, ajaxContext.url, ajaxContext.requestId, ajaxContext.httpMethod);
      }
    }

    ajaxContext.activity.ajax(
      ajaxContext.requestId,
      ajaxContext.url,
      false,
      ajaxContext.httpMethod,
      ajaxTimeTaken,
      ajaxContext.requestResult
    );
  }

  /**
   * Handler for the ajax complete event.
   * @param {object} ajaxContext - data related to the current ajax call
   * @param {Array} args - args passed to the beforeSend ajax callback
   * @returns {undefined} - no returns
   */
  completeHandler(ajaxContext, [jqXHR]) { // eslint-disable-line no-unused-vars
    // do nothing
  }

  /**
   * private method to check if ajax error maps to an user error
   * @param {object} ajaxContext - data related to the current ajax call
   * @param {object} jqXHR - jqXHR passed to the ajax callback
   * @param {object} textStatus - textStatus passed to the ajax callback
   * @param {object} errorThrown - errorThrown passed to the ajax callback
   * @returns {bool} - if error is an user error
   */
  isUserError(ajaxContext, jqXHR, textStatus, errorThrown) {
    return _.any(this.errorMappers, errorMapper => errorMapper.check(
      ajaxContext,
      jqXHR,
      textStatus,
      errorThrown
    ));
  }

  static getServerPerf(jqXHR) {
    if (jqXHR && _.isFunction(jqXHR.getResponseHeader)) {
      const perfTimings = jqXHR.getResponseHeader('PerfTimings');
      if (perfTimings) {
        return perfTimings;
      }
      const odataPerf = {};
      _.each(
        ['x-ms-mte2eelapsedtimems', 'x-ms-odataapie2eelapsedtimems', 'x-ms-odataapionlye2eelapsedtimems'],
        (header) => {
          const value = jqXHR.getResponseHeader(header);
          if (value) {
            odataPerf[header] = value;
          }
        }
      );

      if (jqXHR.responseJSON) {
        const key = '@ns.cacheobjectused';
        odataPerf[key] = jqXHR.responseJSON[key];

        const keyUnfilteredCount = '@ns.unfiltered.count';
        odataPerf[keyUnfilteredCount] = jqXHR.responseJSON[keyUnfilteredCount];

        const keyOdataCount = '@odata.count';
        odataPerf[keyOdataCount] = jqXHR.responseJSON[keyOdataCount];
      }

      return JSON.stringify(odataPerf);
    }
    return '';
  }
}
