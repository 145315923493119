export const gridInlineErrorStyle = ({ palette = {}, iconFontFamily = 'MsAds MDL2 Assets' }) => ({
  '& .inline-editor-errors': {
    '& .error-messages': {
      listStyleType: 'none',
      marginBottom: 0,
      '& .error': {
        fontFamily: iconFontFamily,
        padding: '10px',
        fontSize: '10pt',
        '&:before': {
          content: '"\\EA39"',
          color: palette.red,
        },
      },
      '& .error-message': {
        fontSize: '14px',
      },
    },
  },
});
