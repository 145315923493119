export const floatButtonStyle = ({ palette = {} }) => ({
  floatButton: {
    background: palette.neutralLighter,
    border: 'none',
    borderRadius: 0,
    color: palette.themeDarkAlt,
    fontWeight: 'normal',
    height: 'auto',
    minHeight: 'auto',
    minWidth: 'auto',
    width: 'auto',
    padding: '14px 8px',

    '&:active, &:focus, &:hover': {
      color: palette.themeDark,
      background: palette.neutralQuaternaryAlt,
    },
  },
});
