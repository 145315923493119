import PropTypes from 'prop-types';

export const perfMarkerPropTypes = PropTypes.shape({
  createChild: PropTypes.func.isRequired,
  willFetchFile: PropTypes.func.isRequired,
  willFetchData: PropTypes.func.isRequired,
  willInit: PropTypes.func.isRequired,
  willRender: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
});
