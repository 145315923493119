export const SearchBox = {
  inputGroup: {
    position: 'relative',
    overflow: 'hidden',
  },

  input: {
    border: {
      width: 1,
      style: 'solid',
    },
    padding: {
      top: 3,
      right: 33,
      bottom: 3,
      left: 8,
    },
    display: 'block',
    width: '100%',
    fontSize: '1em',
  },

  icon: {
    position: 'absolute',
    right: 60,
    height: 15,
    marginTop: 5,
    width: 20,
    fontSize: 12,

    '&:hover': {
      cursor: 'pointer',
    },
  },
};
