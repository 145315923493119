import _ from 'underscore';
import * as putil from '@bingads-webui-universal/primitive-utilities';
import { staticI18nMock } from './static-i18n-mock';

const {
  isValidDecimal,
  parseDecimal,
  formatDecimal,
} = staticI18nMock;

class FixedI18nMock {
  constructor(dictionary) {
    this.dictionary = dictionary;
  }

  getString(key, model) {
    if (!_.isString(key)) {
      throw new Error('Localization key must be a String');
    }

    if (!_.has(this.dictionary, key)) {
      return key;
    }

    let ret = this.dictionary[key];

    if (ret && model) {
      const compiled = putil.curlyTemplate(ret);

      ret = compiled(model);
    }

    return ret;
  }
}

_.extend(FixedI18nMock.prototype, {
  isValidDecimal,
  parseDecimal,
  formatDecimal,
});

export { FixedI18nMock };
