import { Instrumentation } from '@bingads-webui/instrumentation';

import { PerformanceTimingLogger } from '@bingads-webui/performance-timing-logger';
import { PerfMarkerFactory } from '@bingads-webui/performance-marker';
import { getListeners } from './listeners';
import { getProcessors } from './processors';
import { setStub } from './set-stub';
import getPerfLogListeners from './perf-timing-config';

export function getInstrumentation({
  pageTrackingId,
  errorMappers,
  isInDevelopmentMode,
  userContext,
  applicationName,
  serverContext,
  ajaxStubOptions,
  ariaConfig = {},
  objectsToStub = [window.$],
}) {
  const processors = getProcessors({
    isInDevelopmentMode,
    pageTrackingId,
    userContext,
    serverContext,
  });

  const instrumentationForPerfTiming = new Instrumentation({
    listeners: getPerfLogListeners({ ariaConfig }),
    processors,
  });

  const perfTimingLogger = new PerformanceTimingLogger({
    instrumentation: instrumentationForPerfTiming,
  });
  perfTimingLogger.init();

  const instrumentation = new Instrumentation({
    listeners: getListeners({ pageTrackingId, applicationName, ariaConfig }),
    processors: {
      contextProcessor: processors.contextProcessor,
      debugProcessor: processors.debugProcessor,
    },
  });

  setStub({
    instrumentation,
    pageTrackingId,
    errorMappers,
    objectsToStub,
    ajaxStubOptions,
    lcid: userContext.CurrentUser.Locale,
  });

  // init Perf Marker Factory
  PerfMarkerFactory.init(instrumentation);

  window.vNextInstrumentation = instrumentation;

  return instrumentation;
}
