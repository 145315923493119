export const getFilterEditorOverrideStyle = palette => ({
  '& .filter-editor': {
    '& .save-form .checkbox label': {
      display: 'inline-block',
    },
    '& .panel-body': {
      paddingLeft: '32px',
      lineHeight: '19px',
      marginBottom: '28px',
    },
    '& .glyphicon-remove:before': {
      // eslint-disable-next-line no-useless-escape
      content: '"\\E711"',
    },
    '& .glyphicon': {
      display: 'inline-block',
      'font-family': 'MsAds MDL2 Assets',
      'font-size': '14px',
      'font-style': 'normal',
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
    },
    '& .glyphicon.glyphicon-remove': {
      top: 0,
    },
    '& .glyphicon.glyphicon-plus': {
      marginRight: 8,
      height: 14,
      width: 14,
      fontFamily: 'MsAds MDL2 Assets',
      color: palette.themePrimary,
      top: 2,
    },
    '& .glyphicon-plus:before': {
      content: '"\\E710" !important',
    },
    '& .delivery-status-category-container:first-of-type': {
      float: 'left',
      marginLeft: '20px',
      display: 'inline-block',
      marginTop: 0,
    },

    '& .delivery-status-category-container + .delivery-status-category-container': {
      paddingLeft: 20,
      float: 'left',
    },
    '& .predicate-row': {
      alignItems: 'end',
    },
    '& .filter_button': {
      marginTop: 2,
    },
  },
});
