import _ from 'underscore';
import { constants } from '@bingads-webui/instrumentation';
import { Filter } from '@bingads-webui/listener';

const ignoredApis = [
  'http://help',
  'https://help',
  'g.bing.com/clientsdk',
  'api/updates/get',
];

export class ApiFilter extends Filter {
  check(logMessage) {
    let shouldFilter = false;

    // If API is in ignored API list, only log it if log category is error.
    if (_.isString(logMessage.api)) {
      const isErrorLog = logMessage.logCategory === constants.LOGCATEGORY.ERROR;
      const isIgnoreApi = _.any(
        ignoredApis,
        ignoredApi => logMessage.api.toLowerCase().indexOf(ignoredApi) > -1
      );

      shouldFilter = !isErrorLog && isIgnoreApi;
    }

    return shouldFilter;
  }
}
