import { tableBorder, totalRowBorderTop, totalRowBackgroundColor, headerRowBorderBottom, headerRowBackgroundColor, headerRowBorderTop } from './variables';

export const getSharedHeaderStyles = (components, { palette, iconFontFamily = 'MsAds MDL2 Assets' }) => ({
  '& tr.aggregated-row': {
    '& th': {
      fontSize: '12px',
    },
  },
  '& tr.overall-total-row': {
    '& th': {
      fontWeight: 'normal',
      borderTop: totalRowBorderTop,
      borderBottom: tableBorder,
      backgroundColor: totalRowBackgroundColor,
    },
  },
  '& tr': {
    '& th.column-header': {
      borderTop: headerRowBorderTop,
      borderBottom: headerRowBorderBottom,
      '& .helpbutton::before': {
        position: 'static',
      },

      '& span.grid-header-text': {
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
      },
      '& span.grid-header-helpId': {
        lineHeight: 1,
      },
    },
    '& th': {
      verticalAlign: 'middle',
      fontWeight: '600',
      backgroundColor: headerRowBackgroundColor,
      color: palette.neutralPrimary,
      border: tableBorder,
      padding: '6px 8px',
    },

    '& th:first-child': {
      paddingLeft: '12px',
    },

    // we don't want pop first column header to have special padding left
    '&.pop-enabled + tr.pop-enabled th:first-child': {
      paddingLeft: '10px',
    },

    '& th.column-header-sortable': {
      cursor: 'pointer',
    },

    '&.grid-cross-page-select-all': {
      '& th': {
        padding: '0',
        border: 'none',
      },
    },
    '& .glyphicon': {
      fontFamily: iconFontFamily,
    },
    '& .glyphicon-arrow-up:before': {
      content: '"\\EE68"',
    },
    '& .glyphicon-arrow-down:before': {
      content: '"\\EE69"',
    },

    // this temporary style for grid POP before we got UI from designer
    '& th span.pop-toggle-icon.clickable': {
      height: '14px',
      width: '14px',
      border: '1px solid transparent',
      display: 'inline-block',
      float: 'none',
      marginTop: '3px',

      '&:hover': {
        border: '1px solid black',
      },

      '& span': {
        display: 'block',
        fontSize: '10px',
        fontFamily: iconFontFamily,
        fontWeight: 'bold',
        '-webkit-font-smoothing': 'antialiased',
        height: '12px',
        lineHeight: '12px',
        textAlign: 'center',
        width: '12px',

        '&.pop-expand:before': {
          content: '"\\E710"',
          color: 'black',
        },

        '&.pop-collapse:before': {
          content: '"\\E738"',
          color: 'black',
        },
      },
    },
  },

  '& tr.pop-enabled th.column-header.pop-column-header > div.column-header-container': {
    display: 'inline-block',
  },
});
