import _ from 'underscore';
import { Formatter } from '@bingads-webui/listener';
import { safeParseInteger } from './format-utilities';

export class ReportingApiFormatter extends Formatter {
  format(log) {
    if (log.As === 'campaign' && log.Api.indexOf('reporting/syncdata/') > -1) {
      /* eslint-disable no-param-reassign */
      log.As = 'reporting';
      const apiChunks = this.getValidatedApiChunks(log.Api.split('/'));

      if (apiChunks.length > 5) {
        const operationId = safeParseInteger(apiChunks[5], -1);

        if ((operationId >= 0 && operationId <= 4) || operationId === 23) {
          log.Api = 'sync/trendchart';
        } else if (operationId === 5) {
          log.Api = 'sync/kdg';
        } else if ((operationId >= 24 && operationId <= 26) || operationId === 6) {
          log.Api = 'sync/changehistorygrid';
        } else if (operationId !== -1) {
          log.Api = 'sync/dimensiongrid';
        }
      }
    }
    return log;
  }

  // make sure that the first element of the rawChunks is the string 'reporting'
  // if not we'll strip all the elements before the 'reporting' element
  getValidatedApiChunks(rawChunks) {
    if (_.isUndefined(rawChunks) || !_.isArray(rawChunks) || rawChunks.length === 0 || rawChunks[0] === 'reporting') {
      return rawChunks;
    }

    const index = _.indexOf(rawChunks, 'reporting');

    if (index > 0) {
      return rawChunks.slice(index);
    }

    return rawChunks;
  }
}
