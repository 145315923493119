import React from 'react';
import PropTypes from 'prop-types';
import { withDefaultStyles } from '@bingads-webui-react/with-default-styles';
import { getStyles } from './getStyles';

const UiBlockerUnthemed = (props) => {
  const {
    blocking,
    children,
    loadingText,
  } = props;

  const classes = blocking ? `${props.classes.reactBlockUi} react-block-ui` : 'react-block-ui-none';

  return (
    <div className={classes} aria-busy={blocking}>
      {children}
      {blocking &&
        <div className={props.classes.blockUiContainer}>
          <div className={props.classes.blockUiOverlay}>
            <div
              className={props.classes.blockerIndicator}
              role="status"
              aria-label={loadingText}
            >
              <div className="blocker-image" />
              <div className="blocker-text">
                {loadingText}
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

UiBlockerUnthemed.propTypes = {
  blocking: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  loadingText: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    reactBlockUi: PropTypes.string,
    blockUiContainer: PropTypes.string,
    blockUiOverlay: PropTypes.string,
    blockerIndicator: PropTypes.string,
  }).isRequired,
};

export const UiBlocker = withDefaultStyles(UiBlockerUnthemed, getStyles);
