/**
 * Pre-defined schema used for Ajax Stub
 */
export const schema = {
  scenario: [{
    name: 'ajaxStubScenario',
    value: 'Ajax Stub Scenario',
    setting: {
      hidden: true,
    },
    activity: [
      {
        name: 'ajaxStubActivity',
        value: 'Ajax Stub Activity',
        setting: {},
      },
    ],
  }],
};
