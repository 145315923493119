export const getSharedGlobalStyles = (components, { palette }) => ({
  '.bingadsgrid': {
    '& .grid-noresult, & .grid-noresult-empty, & .noresult': {
      color: palette.neutralSecondary,
      fontSize: 26,
      lineHeight: '36px',
      padding: '20px 0 20px 40px',
    },
  },
  '.table-container .glyphicon.glyphicon-triangle-left': {
    fontFamily: '"MsAds MDL2 Assets"',
  },
  '.table-container .glyphicon.glyphicon-triangle-left::before': {
    content: '"\\E76B"',
    color: palette.neutralSecondary,
  },
  '.table-container .btn:disabled .glyphicon.glyphicon-triangle-left::before': {
    color: '#BEBBB8',
  },
  '.table-container .glyphicon.glyphicon-triangle-right': {
    fontFamily: '"MsAds MDL2 Assets"',
  },
  '.table-container .glyphicon.glyphicon-triangle-right::before': {
    content: '"\\E76C"',
    color: palette.neutralSecondary,
  },
  '.table-container .btn:disabled .glyphicon.glyphicon-triangle-right::before': {
    color: '#BEBBB8',
  },
  '.table-container .glyphicon.glyphicon-triangle-bottom': {
    fontFamily: '"MsAds MDL2 Assets"',
  },
  '.table-container .glyphicon.glyphicon-triangle-bottom::before': {
    content: '"\\E70D"',
    color: palette.neutralSecondary,
  },
  '.table-container .btn:disabled .glyphicon.glyphicon-triangle-bottom::before': {
    color: '#BEBBB8',
  },
});
