export const closeImageStyle = ({ palette = {}, iconFontFamily = 'MsAds MDL2 Assets' }) => ({
  '.closeimage': {
    fontFamily: iconFontFamily,
    color: palette.neutralSecondary,
    cursor: 'pointer',
    right: -8,
    position: 'relative',
  },

  '.closeimage:before': {
    content: '"\\E711"',
  },
});
