import _ from 'underscore';
import * as putil from '@bingads-webui-universal/primitive-utilities';

/**
 * Translation resource.
 * @private
 */
export class Resource {
  /**
   * Creates an instance of Resource.
   * @param {Object} options - options
   * @param {Object.<string, string>} data - key -> translation pairs.
   * @param {string|bool} failFast - when got errors
   *    "SAFE"  - ignore errors and try to recover by using keys
   *     else   - throw errors and crash current execution
   * @constructs Resource
   * @private
   */
  constructor({
    data,
    failFast,
  }) {
    if (!_.isObject(data)) {
      throw new Error('Localization data must be an Object');
    }

    this.data = data;
    this.failFast = failFast;
  }

  /**
   * Get translation of given key.
   * When translation is Mustache.js-style template string, model will be filled in.
   * @param {string} key - key of the message translated.
   * @param {Object.<string, string>} [model] - model to be filled into the template string.
   * @param {Function} [preprocess] - Function to use on the template before running curlyTemplate.
   * @returns {string} the translation.
   * @private
   */
  getString(key, model, {
    preprocess = _.identity,
  } = {}) {
    if (!_.isString(key)) {
      throw new Error('Localization key must be a String');
    }

    let ret = putil.get(this.data, key);

    if (_.isNull(ret)) { // translation is not ready, use English text instead
      ret = key;
    }

    if (ret) {
      try {
        ret = preprocess(ret);
      } catch (ex) {
        if (this.failFast === 'SAFE') {
          // maybe there are some errors in the translated strings, try to recover by using the key
          ret = preprocess(key);
        } else {
          throw ex;
        }
      }

      if (!_.isString(ret)) {
        throw new Error(`Preprocess function must return a String. ${key}`);
      }

      if (model) {
        try {
          const compiled = putil.curlyTemplate(ret);
          ret = compiled(model);
        } catch (ex) {
          if (this.failFast === 'SAFE') {
            // maybe there are some errors in the translated strings, try to recover by using the key
            const compiled = putil.curlyTemplate(key);
            ret = compiled(model);
          } else {
            throw ex;
          }
        }
      }
    }

    return ret;
  }
}
