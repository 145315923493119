import { createTheme, CommunicationColors, NeutralColors, SharedColors, Depths } from '@fluentui/react';

export const ThemeFluent = createTheme({
  palette: {
    neutralDark: NeutralColors.gray190,
    neutralPrimary: NeutralColors.gray160,
    neutralPrimaryAlt: NeutralColors.gray150,
    neutralSecondary: NeutralColors.gray130,
    neutralSecondaryAlt: NeutralColors.gray110,
    neutralTertiary: NeutralColors.gray90,
    neutralTertiaryAlt: NeutralColors.gray60,
    neutralQuaternary: NeutralColors.gray50,
    neutralQuaternaryAlt: NeutralColors.gray40,
    neutralLight: NeutralColors.gray30,
    neutralLighter: NeutralColors.gray20,
    neutralLighterAlt: NeutralColors.gray10,
    themeDark: CommunicationColors.shade20,
    themeDarker: CommunicationColors.shade30,
    themeDarkAlt: CommunicationColors.shade10,
    themeLight: CommunicationColors.tint20,
    themeLighter: CommunicationColors.tint30,
    themeLighterAlt: CommunicationColors.tint40,
    themePrimary: CommunicationColors.primary,
    themeSecondary: CommunicationColors.tint10,
    themeTertiary: '#71afe5',
    // Shared Colors
    red: SharedColors.red10,
    redDark: SharedColors.red20,
    black: '#000000',
    white: '#ffffff',
    // MS Ads
    globalBackground: '#2f2f2f',
    mainMenuHoverBackground: '#484644',
  },
  effects: {
    roundedCorner2: '2px',
    elevation4: Depths.depth4,
    elevation8: Depths.depth8,
    elevation16: Depths.depth16,
    elevation64: Depths.depth64,
  },
});

ThemeFluent.iconFontFamily = 'MsAds MDL2 Assets';
