/* eslint-disable no-unused-vars */
export const scrollbarStyle = ({ palette = {} }) => ({
  '::-webkit-scrollbar':
  {
    width: 4,
    backgroundColor: 'transparent',
  },

  '::-webkit-scrollbar-track':
  {
    backgroundColor: 'transparent',
  },

  '::-webkit-scrollbar-thumb':
  {
    width: 4,
    height: 44,
    backgroundColor: '#EBEAE8',
  },

  '::-webkit-scrollbar:horizontal':
  {
    height: 4,
    backgroundColor: 'transparent',
  },

  '::-webkit-scrollbar-track:horizontal':
  {
    backgroundColor: 'transparent',
  },

  '::-webkit-scrollbar-thumb:horizontal':
  {
    width: 200,
    height: 4,
    backgroundColor: '#EBEAE8',
  },
});
