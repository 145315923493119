import _ from 'underscore';

export function safeParseInteger(number, defaultValue = 0) {
  const parsedNum = Number(number);

  if (_.isNaN(parsedNum) || parsedNum < 0) {
    return defaultValue;
  }
  return Math.round(parsedNum);
}
