// use this in '& col'
export const getSharedColGroupStyles = () => ({
  width: 120,
  minWidth: 120,

  '&.col-selection': {
    width: 46,
    minWidth: 46,
  },
});
