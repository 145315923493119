/* eslint-disable max-len */
import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { LayoutContext } from './layout-context';
import { layoutConfigs } from './layout-configs';

class LayoutHoC extends React.Component {
  static propTypes = {
    componentProps: PropTypes.objectOf(PropTypes.any).isRequired,
    changeLayout: PropTypes.func.isRequired,
    component: PropTypes.elementType.isRequired,
    toLayout: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  constructor(props) {
    super(props);
    props.changeLayout(props.toLayout, true);
  }

  shouldComponentUpdate(nextProps) {
    const { component, componentProps } = nextProps;
    if (!_.isEqual(component, this.props.component) || !_.isEqual(componentProps, this.props.componentProps)) {
      this.props.changeLayout(nextProps.toLayout, true);
      return true;
    }

    return false;
  }

  render() {
    const { componentProps, component: Component } = this.props;
    return <Component changeLayout={this.props.changeLayout} {...componentProps} />;
  }
}


export function withFullScreenLayout(Component) {
  return props => (
    <LayoutContext.Consumer>
      {({ change }) => (<LayoutHoC component={Component} toLayout={layoutConfigs.fullScreen} changeLayout={change} componentProps={props} />)}
    </LayoutContext.Consumer>
  );
}

export function withLayout(Component, toLayout) {
  return props => (
    <LayoutContext.Consumer>
      {({ change }) => (<LayoutHoC component={Component} toLayout={toLayout} changeLayout={change} componentProps={props} />)}
    </LayoutContext.Consumer>
  );
}

export function withMenuLayout(Component) {
  return props => (
    <LayoutContext.Consumer>
      {({ config }) => (<Component {...props} {...config.menu} />)}
    </LayoutContext.Consumer>
  );
}

export function withDefaultLayout(Component) {
  return props => (
    <LayoutContext.Consumer>
      {({ change }) => (<LayoutHoC component={Component} toLayout={{}} changeLayout={change} componentProps={props} />)}
    </LayoutContext.Consumer>
  );
}

export function withCollapsedMenuOnly(Component) {
  return props => (
    <LayoutContext.Consumer>
      {({ change }) => (<LayoutHoC component={Component} toLayout={layoutConfigs.withCollapsedMenuOnly} changeLayout={change} componentProps={props} />)}
    </LayoutContext.Consumer>
  );
}

export function withCollapsedMenuAndNav(Component) {
  return props => (
    <LayoutContext.Consumer>
      {({ change }) => (<LayoutHoC component={Component} toLayout={layoutConfigs.withCollapsedMenuAndNav} changeLayout={change} componentProps={props} />)}
    </LayoutContext.Consumer>
  );
}

export const withMenuOnlyLayout = _.partial(withLayout, _, layoutConfigs.withMenuOnly);
export const withoutMenuLayout = _.partial(withLayout, _, layoutConfigs.withoutMenu);
export const withSingleTabLayout = _.partial(withLayout, _, layoutConfigs.withSingleTab);
export const withSummaryBarAndTabNoDateLayout = _.partial(withLayout, _, { ...layoutConfigs.fullScreenWithSummaryBarAndTab, ...layoutConfigs.withoutDate });
export const withSummaryBarAndTabAndSubTabLayout = _.partial(withLayout, _, { ...layoutConfigs.fullScreenWithSummaryBarAndTab, ...layoutConfigs.withTabAndSubTab });
export const withFullScreenWithSummaryBar = _.partial(withLayout, _, layoutConfigs.fullScreenWithSummaryBar);
export const withFullScreenWithSummaryBarAndTabLayout = _.partial(withLayout, _, { ...layoutConfigs.fullScreenWithSummaryBarAndTab });
