export const entitySelectorStyle = ({ palette = {}, iconFontFamily = 'MsAds MDL2 Assets' }) => ({
  '& .ba-campaign-selector, & .ba-campaign-adgroup-selector': {
    '& .ba-list-selector': {
      border: '1px solid #BBB',
      height: 300,
      display: 'flex',
      flexDirection: 'column',

      '& .list-selector-item': {
        position: 'relative',
      },

      '& .list-selector-content': {
        flex: 1,
        overflow: 'auto',
      },

      '& .list-selector-select-all-bar': {
        padding: '5px 10px',
        color: '#1470A5',
        cursor: 'pointer',

        '& .arrow-button.enabled': {
          marginLeft: 6,
        },

        '& .arrow-button.remove': {
          marginRight: 6,
        },

        '& .enabled.arrow-button:before, & .arrow-button.remove:before': {
          verticalAlign: 'middle',
        },
      },
    },

    '& .multi-selector': {
      '& .ba-entity-selector': {
        '& .ba-list-selector': {
          '& .list-selector-item': {
            padding: 0,
            '& .multi-item-container': {
              padding: '7px 8px 8px 8px',
            },
            '& .added-to-result-list': {
              backgroundColor: '#edebe9',
            },
          },
        },
      },
    },

  },
  '& .ba-campaign-adgroup-selector .selected-item, .ba-campaign-selector .selected-item': {
    maxWidth: 270,
  },
  '& .ba-campaign-selector, & .ba-campaign-adgroup-selector, & .disclaimer-selector, & .audience-planner-location-selector': {
    '& .dropdown-toggle': {
      lineHeight: 2,
      padding: '0 8px',
      verticalAlign: 'middle',
      fontWeight: 'normal',
      width: 320,
      textAlign: 'left !important',
      background: 'transparent !important',
      border: `1px solid ${palette.neutralSecondary}`,
      borderRadius: 2,
      outline: 'none',

      '&:hover': {
        borderColor: palette.neutralPrimary,
      },

      '&:focus, &:active': {
        borderColor: palette.themePrimary,
      },

      '& .selected-item': {
        textOverflow: 'ellipsis',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        verticalAlign: 'middle',
      },

      '& .icon-right': {
        float: 'right',
      },

      '& .glyphicon-triangle-bottom, .glyphicon-pencil': {
        marginTop: 5,
        fontFamily: iconFontFamily,
        fontSize: 16,
        verticalAlign: 'middle',
        color: palette.neutralSecondary,
      },

      '& .glyphicon-triangle-bottom:before': {
        content: '"\\E70D"',
      },

      '& .glyphicon.glyphicon-pencil::before': {
        content: '"\\E70F"',
      },
    },

    '& .dropdown-toggle:focus:after, .dropdown-toggle:active:after': {
      border: 0,
    },

    '& .dropdown-menu': {
      top: 'auto',
      left: 'auto',
      minWidth: 320,
      padding: 8,
      boxShadow: '0px 0.6px 1.8px rgba(0, 0, 0, 0.108), 0px 3.2px 7.2px rgba(0, 0, 0, 0.132)',
      borderRadius: '0px 0px 2px 2px',

      '& .ba-search-box ': {
        borderTop: `1px solid ${palette.neutralSecondary}`,
        borderLeft: `1px solid ${palette.neutralSecondary}`,
        borderRight: `1px solid ${palette.neutralSecondary}`,
        padding: 1,
        position: 'relative',

        '& .search-box__input': {
          height: 48,
          width: 'calc(100% - 2px)',
          border: 0,
          borderBottom: `1px solid ${palette.neutralLight}`,
          paddingLeft: 48,
          marginLeft: 1,
        },

        '& .search-box__icon': {
          fontFamily: iconFontFamily,
          fontSize: 16,
          verticalAlign: 'middle',
          position: 'absolute',
          left: 16,
          top: 16,
        },

        '& .search-box__icon:before': {
          content: '"\\E721"',
          color: palette.themePrimary,
        },
      },

      '& .ba-list-selector': {
        borderBottom: `1px solid ${palette.neutralSecondary}`,
        borderLeft: `1px solid ${palette.neutralSecondary}`,
        borderRight: `1px solid ${palette.neutralSecondary}`,

        '& .list-selector-header': {
          '& .panel-heading': {
            color: palette.neutralPrimary,
            fontSize: 14,
            padding: '7px 8px',

            '& .panel-title': {
              fontSize: 14,
              marginTop: 0,
            },
          },
        },

        '& .list-selector-content': {
          height: 380,

          '& .list-selector-item': {
            padding: '7px 8px 8px 8px',
            margin: 0,
            minHeight: 36,

            '& > div, & .item-content': {
              maxWidth: 225,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
              paddingRight: '20px',
              '& .statusIcon': {
                verticalAlign: 'middle',
                height: '19px',
              },

              '& .text': {
                marginLeft: 10,
                verticalAlign: 'middle',
              },
            },
          },

          '& .selected': {
            background: palette.neutralLight,
          },
        },

        '& .list-selector-item:hover': {
          background: palette.neutralLight,
        },

        '& .list-selector-item:focus': {
          outline: 'none',
          border: `1px solid ${palette.neutralSecondary}`,
        },
      },

      '& .list-selector-empty': {
        marginLeft: 10,
      },

      '& .ba-cascader-selector': {
        border: 0,
        padding: 0,
      },

      '& .ba-cascader-selector>div': {
        width: '100% !important',
        marginBottom: 0,
      },

      '& .ba-cascader-selector>div:nth-child(2)>div:nth-child(2)': {
        '& .ba-list-selector': {
          borderLeft: '0 !important',
        },
      },

      '& .pagination-container': {
        '& .ba-pagination': {
          height: 36,
          padding: '6px 0',
          paddingBottom: 0,
        },
      },
    },
  },

  '& .ba-campaign-adgroup-selector, & .ba-campaign-selector': {
    '& .dropdown-toggle .dropdown-edit-mode-parent-text': {
      color: '#605e5c',
    },

    '& .item-content-parent-info': {
      color: '#605e5c',
    },
  },
});

