export const statusStyle = ({ palette = {} }) => ({
  '& .statusDraft_icon': {
    display: 'inline-block',
    fontFamily: '"MsAds MDL2 Assets"',
    fontSize: 14,
    fontStyle: 'normal',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    fontWeight: 'normal',
  },

  '& .statusDraft_icon::before': {
    content: '"\\F6B0"',
    color: palette.neutralPrimary,
  },

  '& .statusDeleted_icon': {
    display: 'inline-block',
    fontFamily: '"MsAds MDL2 Assets"',
    fontSize: 14,
    fontStyle: 'normal',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    fontWeight: 'normal',
  },

  '& .statusDeleted_icon::before': {
    content: '"\\E738"',
    color: palette.redOrange10,
  },

  '& .statusEnabled_icon': {
    display: 'inline-block',
    fontFamily: '"MsAds MDL2 Assets"',
    fontSize: 14,
    fontStyle: 'normal',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    fontWeight: 'normal',
  },

  '& .statusEnabled_icon::before': {
    content: '"\\ECCC"',
    color: palette.green,
  },

  '& .statusExcluded_icon': {
    display: 'inline-block',
    fontFamily: '"MsAds MDL2 Assets"',
    fontSize: 14,
    fontStyle: 'normal',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    fontWeight: 'normal',
  },

  '& .statusExcluded_icon::before': {
    content: '"\\E733"',
    color: '#A80000',
  },

  '& .statusPaused_icon': {
    display: 'inline-block',
    fontFamily: '"MsAds MDL2 Assets"',
    fontSize: 14,
    fontStyle: 'normal',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    fontWeight: 'normal',
  },

  '& .statusPaused_icon::before': {
    content: '"\\F6B0"',
    color: palette.neutralPrimary,
  },

  '& .statushypendisplay': {
    display: 'inline-block',
    fontFamily: '"MsAds MDL2 Assets"',
    fontSize: 14,
    fontStyle: 'normal',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    fontWeight: 'normal',
  },

  '& .statushypendisplay::before': {
    content: '"\\E738"',
    color: palette.neutralPrimary,
  },

});
