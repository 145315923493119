import $ from 'jquery';
import _ from 'underscore';
import { AjaxEventHandler } from './ajax-event-handler';

const defaultStubOptions = {
  objectsToStub: [$],
  serverErrorCodes: [-1],
  detectError(respData, serverErrorCodes) {
    const result = {
      pass: true,
    };

    if (respData &&
      respData.Errors &&
      respData.Errors[0] &&
      respData.Errors[0].Code &&
      _.isArray(serverErrorCodes) &&
      _.contains(serverErrorCodes, respData.Errors[0].Code)) {
      result.pass = false;
      result.message = respData.Errors[0].Message;
      result.impactUser = true;
    }
    return result;
  },
  getEntityCount(respData) {
    if (respData && respData.Records) {
      return respData.Records.RowCount;
    }
    return -1;
  },
  getPageSize(requestData) {
    const pageSizeParam = '.PageSize';
    if (_.isObject(requestData) && requestData.BiDateRange) {
      const keys = _.keys(requestData);
      const pageSizeProp = _.find(keys, key => key.length > pageSizeParam.length &&
        key.substr(key.length - pageSizeParam.length) === pageSizeParam);
      return requestData[pageSizeProp];
    }
    return -1;
  },
};

// default ajax event handler
const ajaxEventHandler = new AjaxEventHandler();

export const defaultConfig = {
  stubOptions: defaultStubOptions,
  eventHandler: ajaxEventHandler,
};

