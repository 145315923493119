import React, { useContext, useMemo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { PerfMarkerFactory } from '@bingads-webui/performance-marker';

import { PerformanceMarkerContext } from './perf-marker-context';

export const PerformanceMarkerProvider = (props) => {
  const perfMarker =
    useMemo(
      () => {
        PerfMarkerFactory.init(props.instrumentation);
        const appPerfMarker = PerfMarkerFactory.createMarker(props.name);
        appPerfMarker.willInit();
        return appPerfMarker;
      },
      [props.instrumentation, props.name]
    );

  useLayoutEffect(() => {
    if (props.immediateDone) {
      perfMarker.done();
    }
  }, [perfMarker, props.immediateDone]);

  perfMarker.willRender();

  return (
    <PerformanceMarkerContext.Provider value={perfMarker}>
      {props.children}
    </PerformanceMarkerContext.Provider>
  );
};

PerformanceMarkerProvider.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  instrumentation: PropTypes.shape({}).isRequired,
  immediateDone: PropTypes.bool,
};

PerformanceMarkerProvider.defaultProps = {
  immediateDone: true,
};

export const PerformanceMarkerChildProvider = (props) => {
  const perfMarkerParent = useContext(PerformanceMarkerContext);
  const perfMarkerChild = useMemo(
    () => {
      const child = perfMarkerParent.createChild(props.name, { autoEnd: true });
      child.willInit();
      return child;
    },
    [perfMarkerParent, props.name]
  );

  perfMarkerChild.willRender();

  return (
    <PerformanceMarkerContext.Provider value={perfMarkerChild}>
      {props.children}
    </PerformanceMarkerContext.Provider>
  );
};

PerformanceMarkerChildProvider.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
};
