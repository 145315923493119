/* eslint-disable no-bitwise */

function numberToRgb(rgbInteger: number) {
  return {
    red: (rgbInteger & 0xff0000) >> 16,
    green: (rgbInteger & 0x00ff00) >> 8,
    blue: rgbInteger & 0x0000ff,
  };
}

/**
 * Converts an RGB color to its grayscale equivalent. Useful for determining if a color should be
 * contrasted against white or black.
 * @see {@link https://www.w3.org/TR/AERT/#color-contrast}
 * @param {Number} rgbInteger - Number that represents an RGB color, e.g. 15792383.
 * @returns {Number} Grayscale value of the number, e.g. 246.
 */
export function colorToGrayscale(rgbInteger: number): number {
  const rgb = numberToRgb(rgbInteger);

  // calculate grayscale value based on how humans perceive luminance in RBG
  return Math.round((0.299 * rgb.red) + (0.587 * rgb.green) + (0.114 * rgb.blue));
}

/**
 * Returns black if the grayscale equivalent of given color is <50% gray, otherwise returns white.
 * @param {Number} rgbInteger - Number that represents an RGB color, e.g. 15792383.
 * @returns {Number} Number that represents RGB black or RGB white.
 */
export function contrastColor(rgbInteger: number): number {
  const grayscale = colorToGrayscale(rgbInteger);

  return grayscale >= 256 / 2 ? 0 : 0xffffff;
}

/**
 * Convert a RGB hexadecimal color value into decimal.
 * @param {String} color - RGB 6-digit hexademical color, e.g. '#ff0000'
 * @returns {Number} Decimal representation, e.g. 16711680.
 */
export function toDec(color: string): number {
  return parseInt(color.substr(1), 16);
}

/**
 * Convert a decimal number into 6-digit hexademical.
 * @param {Number} color - Number value of an RGB color, e.g. 15792383
 * @returns {String} 6-digital hexademical representation, e.g. 'f0f9ff'
 */
export function toHex(color: number): string {
  return `000000${color.toString(16)}`.substr(-6);
}
