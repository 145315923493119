// specify odata error codes that represent user errors
const odataErrorCodes = [
  'ACCOUNTIDCOLUMNNOTFOUND',
  'ACCOUNTIDINVALID',
  'ACCOUNTNOTINPILOTFORPORTFOLIOBIDSTRATEGY',
  'ACCOUNTNUMBERSMAXLIMITEXCEEDED',
  'ADDESTINATIONURLINVALID',
  'ADEXTENSIONASSOCIATIONSLIMITEXCEEDEDPERENTITYTYPE',
  'ADEXTENSIONCITYNAMENULLOREMPTY',
  'ADEXTENSIONPHONENUMBERINVALID',
  'ADEXTENSIONPHONENUMBERTOOLONG',
  'ADGROUPCRITERIONIDINVALID',
  'ADGROUPCRITERIONSENTITYLIMITEXCEEDED',
  'ADGROUPEXPIRED',
  'ADGROUPIDINVALID',
  'ADGROUPINEXPIREDSTATECANNOTBEUPDATED',
  'ADGROUPISDELETED',
  'ADIDINVALID',
  'ADTEXTINVALID',
  'ADTITLEINVALID',
  'ADTYPEINVALIDFORCUSTOMER',
  'ADTYPENOTSUPPORTEDFORDISCLAIMERCAMPAIGN', // Added from legacy error file
  'ADULTADVERTISERERR',
  'ALREADYHASPENDINGFEED',
  'APPADEXTENSIONAPPSTOREIDINVALID',
  'APPADEXTENSIONDESTINATIONURLINVALID',
  'APPEALQUOTAEXCEEDED',
  'ASSETDATAENCODINGINVALID',
  'ASSETDATAINVALID',
  'ASSETDATAINVALIDASPECT',
  'ASSETDATAINVALIDHEIGHT',
  'ASSETDATAINVALIDWIDTH',
  'ASSETISALREADYDELETED',
  'ASSETISUSED',
  'ASSOCIATIONSLIMITEXCEEDEDPERADEXTENSIONTYPE',
  'ASSOCIATIONSLIMITEXCEEDEDPERADEXTENSIONTYPEFORENTITY',
  'ATTRIBUTENAMELENGTHEXCEEDED',
  'ATTRIBUTEVALUELENGTHEXCEEDED',
  'ATTRIBUTEVALUESTRINGTOOLONG',
  'AUDIENCESHOULDNOTBEACCOUNTLEVEL',
  'BASECAMPAIGNBUDGETTYPEINVALID',
  'BOTHDESTINATIONURLANDFINALURLNOTALLOWED',
  'BUDGETAMOUNTISABOVELIMIT',
  'BUDGETAMOUNTISBELOWLIMIT',
  'BUDGETIDINVALID',
  'BULKFILEEXPIRED',
  'BULKFILENOTPRESENT',
  'BULKFILESCANNOTBEMULTIPLE',
  'CAMPAIGNADGROUPNOTMATCHED',
  'CAMPAIGNOREXPERIMENTWITHNAMEALREADYEXISTS',
  'CAMPAIGNSERVICECANNOTADDCRITERIONTOCAMPAIGNWITHOUTDYNAMICSEARCHSETTINGS',
  'CAMPAIGNSERVICEEXPANDEDTEXTADDEFAULTTEXTREQUIREDFORKEYWORD',
  'CAMPAIGNSERVICEEXPERIMENTDOESNOTSUPPORTMIXEDMODECAMPAIGN',
  'CAMPAIGNSERVICERESPONSIVESEARCHADDESCRIPTIONSLESSTHANMINREQUIRED',
  'CAMPAIGNSERVICERESPONSIVESEARCHADHEADLINESLESSTHANMINREQUIRED',
  'CAMPAIGNSERVICERESPONSIVESEARCHADPATH1INVALID',
  'CAMPAIGNSERVICERESPONSIVESEARCHADPATH2INVALID',
  'CAMPAIGNWITHNAMEALREADYEXISTS',
  'CANNOTUPDATEACCOUNTPROPERTYBYCUSTOMERIDFORTHISPROPERTYNAME',
  'CANNOTUSESTANDARDFEEDATTRIBUTENAME',
  'CANNOTUSETARGETINGFEEDATTRIBUTENAME',
  'COOPINVALIDCOOPERR',
  'COUNTDOWNDAYSBEFOREOUTOFRANGE',
  'COUNTDOWNINVALIDDATETIME',
  'COUNTDOWNINVALIDDAYSBEFORE',
  'COUNTDOWNINVALIDDEFAULTTEXT',
  'COUNTDOWNINVALIDLANGUAGECODE',
  'COUNTDOWNINVALIDPARAMETERS',
  'COUNTDOWNPASTDATETIME',
  'CREATESTOREWITHFRAUDDOMAINERR',
  'CUSTOMERNOTELIGIBLEFORDYNAMICDATAFEED',
  'CUSTOMERUNDERMIGRATION',
  'CUSTOMIDATTRIBUTESHOULDBEOFSTRINGTYPE',
  'DEFAULTHOTELGROUPUPDATENOTALLOWED',
  'DESTINATIONGOALURLSTRINGHASINVALIDCHARACTERS',
  'DESTINATIONGOALURLSTRINGTOOLONG',
  'DESTINATIONURLPROTOCOLINVALID',
  'DOWNLOADFILTEREXPRESSIONSCOUNTEXCEEDED',
  'DUPLICATEASSETSUNDERFOLDER',
  'DUPLICATEAUDIENCEID', // when trying to create audience with the same ID
  'DUPLICATEAUDIENCENAME',
  'DUPLICATEDOMAINNAMEERR',
  'DUPLICATEENTITY', // duplicate folder created (user error)
  'DUPLICATEFEEDATTRIBUTENAME',
  'DUPLICATEFEEDFILENAMEERR',
  'DUPLICATEFEEDNAME',
  'DUPLICATEFEEDNAMEERR',
  'DUPLICATEFOLDERNAME',
  'DUPLICATEIMAGE',
  'DUPLICATELISTITEMINLIST',
  'DUPLICATEPRODUCTAUDIENCE', // when trying to create audience with same audience setting(tag, type)
  'DUPLICATESHAREDLISTNAME',
  'DUPLICATESOURCEPULLURLERR',
  'DUPLICATESTORENAMEERR',
  'DUPLICATETAGID', // when creating tag for the audience with same ID
  'DUPLICATEVALUES', // when creating hotel center length of stay with same value in different stay length
  'DYNAMICSEARCHADPATH1INVALID', // Added from legacy error file
  'DYNAMICSEARCHADTEXTPART2INVALID',
  'EDITORIALADDESTINATIONURLBLANKACROSSALLASSOCIATIONS',
  'EDITORIALADTEXTBLANKACROSSALLASSOCIATIONS',
  'EDITORIALADTITLEBLANKACROSSALLASSOCIATIONS',
  'EDITORIALAPPEALCREATIONQUOTAEXCEEDED',
  'EDITORIALAPPEALCREATIONQUOTAEXCEEDEDFORLAST24HOURS',
  'EDITORIALAPPEALENTITYALREADYAPPEALED',
  'EDITORIALERROR',
  'EDITORIALSYNTAXERRORINTITLE',
  'EDITORIALTOOMUCHADTEXTINDESTINATIONURL',
  'EDITORIALTOOMUCHADTEXTINTEXT',
  'EDITORIALTOOMUCHADTEXTINTEXTACROSSALLASSOCIATIONS',
  'EDITORIALTOOMUCHADTEXTINTITLE',
  'EDITORIALTOOMUCHADTEXTINTITLEACROSSALLASSOCIATIONS',
  'ENDEDEXPERIMENTCANNOTBECHANGED',
  'ENTITYALREADYEXISTS',
  'EXPANDEDTEXTADCOMBINEDTITLETOOLONG',
  'EXPANDEDTEXTADCOUNTDOWNDAYSBEFOREOUTOFRANGE',
  'EXPANDEDTEXTADCOUNTDOWNINVALIDDATETIME',
  'EXPANDEDTEXTADCOUNTDOWNINVALIDDAYSBEFORE',
  'EXPANDEDTEXTADCOUNTDOWNINVALIDDEFAULTTEXT',
  'EXPANDEDTEXTADCOUNTDOWNINVALIDLANGUAGECODE',
  'EXPANDEDTEXTADCOUNTDOWNINVALIDPARAMETERS',
  'EXPANDEDTEXTADCOUNTDOWNPASTDATETIME',
  'EXPANDEDTEXTADDEFAULTTEXTREQUIREDFORKEYWORDPARAMETER',
  'EXPANDEDTEXTADDISPLAYURLDOMAINEXTRACTIONFAILED',
  'EXPANDEDTEXTADDISPLAYURLDOMAININVALID',
  'EXPANDEDTEXTADFINALURLDOMAINTOOLONG',
  'EXPANDEDTEXTADINVALIDFUNCTIONFORMAT',
  'EXPANDEDTEXTADMISSINGDELIMITERBETWEENFUNCTIONS',
  'EXPANDEDTEXTADPATH1INVALID',
  'EXPANDEDTEXTADPATH2INVALID',
  'EXPANDEDTEXTADTEXTPART1INVALID',
  'EXPANDEDTEXTADTEXTPART1TOOLONG',
  'EXPANDEDTEXTADTEXTPART2INVALID',
  'EXPANDEDTEXTADTEXTPART2TOOLONG',
  'EXPANDEDTEXTADTITLEPART1INVALID',
  'EXPANDEDTEXTADTITLEPART1TOOLONG',
  'EXPANDEDTEXTADTITLEPART2INVALID',
  'EXPANDEDTEXTADTITLEPART2TOOLONG',
  'EXPANDEDTEXTADTITLEPART3INVALID',
  'EXPANDEDTEXTADTITLEPART3TOOLONG',
  'EXPANDEDTEXTADUNKNOWNFUNCTION',
  'EXPERIMENTCAMPAIGNCANNOTBEUPDATED',
  'EXPERIMENTENDDATELESSTHANTODAY',
  'EXPERIMENTIDINVALID',
  'EXPERIMENTNAMEISSAMEASBASECAMPAIGNNAME',
  'EXPERIMENTNOTSUPPORTEDFORDISCLAIMERCAMPAIGN',
  'EXPERIMENTTIMEPERIODOVERLAPPING',
  'EXPERIMENTTRAFFICSPLITPERCENTCANNOTBECHANGED',
  'FEEDASSOCIATIONLIMITATIONREACHED',
  'FEEDITEMMAXLIMITREACHED',
  'FEEDNOTFOUNDERR',
  'FEEDUPLOADSCHEDULEINVALIDURL',
  'FEEDUPLOADSCHEDULEUNABLETOFETCHFILE',
  'FEEDUPLOADSCHEDULEUSERNAMEREQUIREDFORPASSWORD',
  'FILEFETCHAUTHENTICATIONERROR',
  'FILEFETCHCONNECTIONERROR',
  'FILEFETCHFILEPATHNOTFOUND',
  'FILEFETCHPERMISSIONLEVELERROR',
  'FILEFORMATNOTSUPPORTED',
  'FILESIZEEXCEEDED',
  'FINALURLREQUIREDWHENUSINGTRACKINGURLTEMPLATEORURLCUSTOMPARAMETER',
  'FOLDERHASASSETS',
  'FOLDERHASSUBFOLDERS',
  'FORMATVERSIONNOTSUPPORTED',
  'FORMATVERSIONREQUIRED',
  'FTPSETTINGSUSERNAMEINVALIDCHARACTERS',
  'FTPSETTINGSUSERNAMETOOLONG',
  'FTPSETTINGSUSERNAMETOOSHORT',
  'FTPSETTINGUSERNAMEALREADYUSED',
  'GOALWITHSAMENAMEALREADYEXISTSUNDERTAG',
  'GOOGLEIMPORTCANNOTRETRIEVEREFRESHTOKEN',
  'GOOGLEIMPORTCONTENTAPIINSUFFICIENTAUTHSCOPE',
  'GOOGLESYNCACCOUNTNOTSETUP',
  'GOOGLESYNCNOTADWORDSUSER',
  'GOOGLESYNCOAUTHTOKENREVOKED',
  'GOOGLESYNCTWOSTEPVERIFICATIONNOTENROLLED',
  'GOOGLESYNCUSERISNOTAUTHORIZED',
  'GOOGLESYNCUSERPERMISSIONDENIED',
  'IFFUNCTIONAUDIENCESEXCEEDSMAXFIELDLENGTH',
  'IFFUNCTIONCUSTOMERNOTINPILOT',
  'IFFUNCTIONDUPLICATEAUDIENCES',
  'IFFUNCTIONINVALIDAUDIENCE',
  'IFFUNCTIONINVALIDSYNTAX',
  'IFFUNCTIONNESTINGNOTALLOWED',
  'IFFUNCTIONNUMAUDIENCESEXCEEDSMAXFORAD',
  'IFFUNCTIONSOMEHAVEDEFAULTVALUEBUTNOTALL',
  'IFFUNCTIONSPECIALCHARACTERSARENOTESCAPED',
  'IFFUNCTIONSPECIALCHARACTERSNOTALLOWED',
  'IMAGESLIMITEXCEEDEDPERACCOUNT',
  'IMAGEDOESNTFITASPECTRATIO',
  'IMPORTEXCEEDGOOGLESTORECOUNTLIMIT',
  'IMPORTFEEDTYPENOTSUPPORTED',
  'IMPORTINVALIDEXCELFILEFORMATHAVINGMULTIPLEWORKSHEETS',
  'IMPORTINVALIDFILEFORMAT',
  'IMPORTMARKETALREADYEXISTS',
  'IMPORTSTATUSINVALID',
  'INVALIDADEXTENSIONSTATUS',
  'INVALIDAUDIENCEDESCRIPTION',
  'INVALIDAUDIENCEID',
  'INVALIDAUDIENCELOOKBACKWINDOW',
  'INVALIDAUDIENCENAME',
  'INVALIDCATEGORYFORGOALTYPE',
  'INVALIDCHARACTERSINKEY',
  'INVALIDCHARACTERSINVALUE',
  'INVALIDDATETIMEFEEDITEMATTRIBUTEVALUE',
  'INVALIDDURATION',
  'INVALIDEMAILADDRESSERR',
  'INVALIDENDDATEATTRIBUTEVALUE',
  'INVALIDFEEDATTRIBUTE',
  'INVALIDFEEDATTRIBUTETYPEINCOUNTDOWN',
  'INVALIDFEEDFILENAMEERR',
  'INVALIDFEEDITEMATTRIBUTEVALUE',
  'INVALIDFEEDNAME',
  'INVALIDFEEDNAMEERR',
  'INVALIDFEEDUPLOADFILE',
  'INVALIDFILTERLINKTEXTCHARACTER',
  'INVALIDFOLDERNAME',
  'INVALIDFUNCTIONFORMAT',
  'INVALIDGEOGRAPHICALLOCATIONSEARCHSTRING',
  'INVALIDGOALID',
  'INVALIDGRIDDATAPARAMETERS',
  'INVALIDID',
  'INVALIDINT64FEEDITEMATTRIBUTEVALUE',
  'INVALIDPAGEFEEDLABEL',
  'INVALIDPAGEFEEDURL',
  'INVALIDPRICEFEEDITEMATTRIBUTEVALUE',
  'INVALIDPULLUSERNAMEERR',
  'INVALIDSTARTDATEATTRIBUTEVALUE',
  'INVALIDSTOREDESCRIPTIONERR',
  'INVALIDTAG',
  'INVALIDTAGDESCRIPTION',
  'INVALIDTAGNAME',
  'INVALIDTARGETADGROUPATTRIBUTEVALUE',
  'INVALIDTARGETAUDIENCEIDATTRIBUTEVALUE',
  'INVALIDTARGETCAMPAIGNATTRIBUTEVALUE',
  'INVALIDTARGETKEYWORDTEXTATTRIBUTEVALUE',
  'INVALIDURL',
  'INVALIDURLFEEDITEMATTRIBUTEVALUE',
  'INVALIDVALUE',
  'KEYFEEDITEMATTRIBUTEVALUECONFLICTION',
  'KEYWORDANDMATCHTYPECOMBINATIONALREADYEXISTS',
  'KEYWORDIDINVALID',
  'KEYWORDSUBSTITUTIONNOTSUPPORTED',
  'LABELNAMEDUPLICATE',
  'LANGUAGEMISMATCHUSERAD',
  'LISTINGADGROUPNOTMATCHED',
  'LOCATIONFUNCTIONINVALIDPARAMETERS',
  'MAXADSLIMITEXCEEDED',
  'MAXAUDIENCECRITERIONSPERACCOUNTLIMITREACHED',
  'MAXAUDIENCESPERACCOUNTLIMITREACHED',
  'MAXAUDIENCESPERCUSTOMERLIMITREACHED',
  'MAXFEEDLIMITREACHEDERR',
  'MAXINMARKETAUDIENCEEXCLUSIONPERACCOUNTLIMITREACHED',
  'MAXLISTITEMLIMITEXCEEDEDFORLIST',
  'MAXMMAADSLIMITREACHEDINADGROUP',
  'MAXRSAADSLIMITREACHEDINADGROUP',
  'MEDIAFORMATNOTSUPPORTED',
  'MISSINGDELIMITERBETWEENFUNCTIONS',
  'MISSINGLANDINGPAGEURLTAG',
  'MOBILEFINALURLNOTALLOWEDWITHMOBILEDEVICEPREFERENCE',
  'MULTIACCOUNTBULKUPLOADACCOUNTCOUNTEXCEEDED',
  'MULTIACCOUNTUPLOADMISSINGFILENAME',
  'MULTIACCOUNTUPLOADUNSUPPORTEDFILETYPE',
  'NAMECOLLISION',
  'NEGATIVEKEYWORDDUPLICATE',
  'NEGATIVEKEYWORDHASINVALIDCHARS',
  'NEGATIVEKEYWORDTOOLONG',
  'NEGATIVESITECANNOTBEOWNEDOROPERATEDSITE',
  'NEGATIVESITEURLEXCEEDMAXSUBDIRECTORIES',
  'NEGATIVESITEURLEXCEEDMAXSUBDOMAINS',
  'NEGATIVESITEURLINVALID',
  'NEGATIVESITEURLTOOLONG',
  'NESTEDPARAMETERINCUSTOMATTRIBUTENOTSUPPORTED',
  'NOENOUGHCONVERSIONFORMAXCONVERSIONSBIDDINGSCHEME',
  'NOENOUGHCONVERSIONFORTARGETCPABIDDINGSCHEME',
  'OFFLINECONVERSIONADDITIONCOLUMNSNOTEXPECTEDINHEADER',
  'OFFLINECONVERSIONADJUSTMENTCOLUMNSNOTEXPECTEDINHEADER',
  'OFFLINECONVERSIONCOLUMNCONVERSIONCURRENCYNOTFOUND',
  'OFFLINECONVERSIONCOLUMNCONVERSIONNAMENOTFOUND',
  'OFFLINECONVERSIONCOLUMNCONVERSIONTIMENOTFOUND',
  'OFFLINECONVERSIONCOLUMNCONVERSIONVALUENOTFOUND',
  'OFFLINECONVERSIONCOLUMNMICROSOFTCLICKIDNOTFOUND',
  'OFFLINECONVERSIONFILEFORMATINVALID',
  'OFFLINECONVERSIONHEADERSROWNOTFOUND',
  'OFFLINECONVERSIONTIMEZONEINVALID',
  'OFFLINECONVERSIONUPLOADACCOUNTIDCOLUMNNOTFOUND',
  'OFFLINECONVERSIONUPLOADFILESIZEEXCEEDED',
  'OFFLINECONVERSIONUPLOADMULTIPLEFILESINZIPFILE',
  'OFFLINECONVERSIONUPLOADZIPFILEEMPTY',
  'ONLYONEACTIVENODEISALLOWED',
  'PAGEFEEDLABELTOOLONG',
  'PORTFOLIOBIDSTRATEGYIDINVALID',
  'PORTFOLIOBIDSTRATEGYISASSOCIATEDWITHACTIVECAMPAIGNS',
  'PROMOTIONCANNOTBEUPDATEDATCURRENTSTATE',
  'PROMOTIONIDALREADYUSED',
  'REQUESTFAILED',
  'REQUESTIDINVALID',
  'RESPONSIVESEARCHADDEFAULTTEXTREQUIREDFORKEYWORDPARAMETER', // Added from legacy error file
  'RESPONSIVESEARCHADDESCRIPTIONSLESSTHANMINREQUIRED',
  'RESPONSIVESEARCHADFINALURLDOMAINTOOLONG',
  'RESPONSIVESEARCHADHEADLINESLESSTHANMINREQUIRED',
  'RESPONSIVESEARCHADPATH1INVALID',
  'RESPONSIVESEARCHADSBOTHCOUNTDOWNANDGLOBALCOUNTDOWN',
  'RESPONSIVESEARCHADSDESCRIPTIONSLESSTHANMINREQUIRED',
  'RESPONSIVESEARCHADSDUPLICATEHEADLINES',
  'RESPONSIVESEARCHADSHEADLINESLESSTHANMINREQUIRED',
  'RETURNPOLICYINVALIDURLERR',
  'ROWCOUNTEXCEEDED',
  'RSAADCUSTOMIZERATTRIBUTECOUNTMORETHANLIMIT',
  'RSAADCUSTOMIZERATTRIBUTETYPECHANGEDINUPDATE',
  'SCHEDULEDAYSNOTINDATERANGE',
  'SHAREDENTITIESWITHACTIVEASSOCIATIONSCANNOTBEDELETED',
  'SHAREDENTITYASSOCIATIONDOESNOTEXIST',
  'SHAREDENTITYASSOCIATIONSBATCHLIMITEXCEEDED',
  'SHAREDENTITYLIMITEXCEEDED',
  'SHAREDENTITYNAMEINVALID',
  'SHAREDLISTIDINVALID',
  'SHAREDLISTITEMBATCHLIMITEXCEEDED',
  'SHAREDLISTITEMIDSLIMITEXCEEDED',
  'SHAREDLISTITEMNOTINLIST',
  'SHIPPINGSETTINGSDUPLICATELABELNAMEUSED',
  'SITELINKDESCRIPTION1INVALID',
  'SITELINKDESCRIPTION1TOOLONG',
  'SITELINKDESCRIPTION2INVALID',
  'SITELINKDESCRIPTION2TOOLONG',
  'SITELINKDESTINATIONURLNULLOREMPTY',
  'SITELINKDISPLAYTEXTINVALID',
  'SITELINKDISPLAYTEXTTOOLONG',
  'SMARTPAGECONCURRENTUPDATINGISNOTALLOWED',
  'SMARTPAGEGETFBPAGEOAUTHERROR',
  'SMARTPAGEGETFBPAGEUSERERROR',
  'SMARTPAGEINVALIDSTATUS',
  'SMARTPAGEINVALIDSUBDOMAIN',
  'SMARTPAGESUBDOMAINISNOTAVAILABLE',
  'SMARTPAGEURLISREJECTEDBYEDITORIAL',
  'STARTDATECOMESAFTERENDDATE',
  'STOCKIMAGELIMITEXCEEDEDPERCUSTOMER', // Added from legacy error file
  'STORENOTCOMPLIANTERR',
  'STORENOTFOUNDERR',
  'TAGWITHSAMENAMEALREADYEXISTSUNDERCUSTOMER',
  'TARGETFEEDSTATUSINVALID',
  'TASKSCHEDULINGINVALID',
  'TOKENEXPIRED',
  'TOOMANAYCUSTOMBIDSINADGROUP',
  'TOOMANYADEXTENSIONSPERACCOUNT',
  'TOOMANYCUSTOMBIDSINADGROUP',
  'TOOMANYPAGEFEEDLABELS',
  'TOOMANYVALUES',
  'TOOMUCHDATAFORINLINEDOWNLOAD',
  'TOOMUCHDATATODOWNLOAD',
  'TOOMUCHEXCELDATATODOWNLOAD',
  'UNKNOWNFUNCTION',
  'URLNOTACCESSIBLE',
  'VALUETOOLONG',
  'VIDEOEXTENSIONINVALIDASPECTRATIO',
  'VIDEOEXTENSIONINVALIDIMAGEFORMAT',
  'VIDEOEXTENSIONINVALIDVIDEODURATION',
  'VIDEOEXTENSIONINVALIDVIDEOID',
  'VIDEOEXTENSIONTHUMBNAILTOOSMALL',
  'VIDEOEXTENSIONVIDEOPROCESSINGFAILED',
  'VIDEOEXTENSIONVIDEOTOOSMALL', // Added from legacy error file
  'VIDEOOVERWEIGHT',
  'WEBPAGECRITERIONALREADYEXISTS',
  'GOALLEVELCANNOTBEDOWNGRADED',
  'CUSTOMERNOTELIGIBLEFORENHANCEDCONVERSIONS',
  'CONVERSIONEMAILADDRESSISNOTHASHED', // The conversion email address value is not hashed.
  'CONVERSIONPHONENUMBERISNOTHASHED', // The conversion phone number value is not hashed.
  'ONLINECONVERSIONHASHEDEMAILADDRESSNOTEXPECTED', // Invalid column HashedEmailAddress in online conversion file
  'ONLINECONVERSIONHASHEDPHONENUMBERNOTEXPECTED', // Invalid column HashedPhoneNumber in online conversion file
  'GOOGLEMYBUSINESSIMPORTUSERERROR',
  'SHAREDLIBRARYUSERPERMISSIONDENIED',
  'LOGOEXTENSIONLOGONOTSQUARE',
  'IMAGEADEXTENSIONALTERNATIVETEXTINVALID',
];

// specify campaign api error codes that represent user errors
const campaignApiErrorCodes = [
  276, // EditorialError
  9028, // AssetIsUsed
  2512, // AdGroupIdInvalid
  9017, // AssetDataInvalid
  9021, // AssetDataInvalidWidth
  9022, // AssetDataInvalidHeight
  9023, // AssetDataInvalidAspect
  9045, // InvalidDuration
  9046, // VideoOverweight
  35305, // ImageAdExtensionAlternativeTextInvalid
  35307, // ImageAdExtensionDestinationUrlInvalid
  35310, // InvalidImageExtensionDescription
];

// specify unsupported domains that result in failed requests
const invalidDomains = [
  'www.googletagmanager.com',
  'cdn.optimizely.com',
  'tracker.marinsm.com',
  'lptag.liveperson.net',
];

export { odataErrorCodes, campaignApiErrorCodes, invalidDomains };

