import { getSharedBodyStyles } from './body';
import { getSharedColGroupStyles } from './col-group';
import { getSharedColumnsStyles } from './columns';
import { getSharedFooterStyles } from './footer';
import { getSharedGlobalStyles } from './global';
import { getSharedHeaderStyles } from './header';
import { getSharedTableStyles } from './table';
import * as variables from './variables';

export const GridSharedStyles = {
  getSharedBodyStyles,
  getSharedColGroupStyles,
  getSharedColumnsStyles,
  getSharedFooterStyles,
  getSharedGlobalStyles,
  getSharedHeaderStyles,
  getSharedTableStyles,
  variables,
};
