import { useContext, useMemo, useEffect, useLayoutEffect } from 'react';

import { PerformanceMarkerContext } from './perf-marker-context';

export const useCreateChildPerfMarker = (
  name,
  {
    immediateInit = true,
  } = {}
) => {
  const perfMarkerParent = useContext(PerformanceMarkerContext);
  const perfMarkerChild = useMemo(
    () => {
      const child = perfMarkerParent.createChild(name, { autoEnd: true });
      if (immediateInit) child.willInit();
      return child;
    },
    [immediateInit, name, perfMarkerParent]
  );
  return perfMarkerChild;
};

export const useUpdateChildPerfMarker = (perfMarkerChild, isLoading) => {
  // Every time the hook renders, call "willRender", so don't memoize this hook.
  perfMarkerChild.willRender();

  useEffect(() => {
    if (isLoading) {
      perfMarkerChild.willFetchData();
    }
  }, [isLoading, perfMarkerChild]);

  useLayoutEffect(() => {
    if (!isLoading) {
      // done after DOM has completed updating (via useLayoutEffect) and isLoading is false
      perfMarkerChild.done();
    }
  }, [isLoading, perfMarkerChild]);

  return perfMarkerChild;
};
