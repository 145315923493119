export const btnInteractStyle = ({ palette = {} }) => ({
  '&:hover': {
    background: palette.neutralLighter,
  },
  '&:focus': {
    background: palette.neutralLight,
    border: `1px solid ${palette.neutralSecondaryAlt}`,
  },
});

export const btnStyle = ({ palette = {} }) => {
  const primary = {
    height: 32,
    minWidth: 80,
    background: palette.themePrimary,
    borderRadius: 2,
    textAlign: 'center',
    color: palette.white,
    boxSizing: 'border-box',

    '&:hover': {
      background: palette.themeDarkAlt,
    },

    '&:focus': {
      background: palette.themePrimary,
      outline: 'none',
    },

    '&:active': {
      background: palette.themeDark,
    },

    '&:disabled': {
      background: palette.neutralLighter,
      color: palette.neutralTertiary,
    },
  };

  return {
    '.btn-primary': primary,
    '.btn-hero': primary,

    '.btn-default': {
      height: 32,
      minWidth: 80,
      background: palette.white,
      borderRadius: 2,
      textAlign: 'center',
      color: palette.neutralPrimary,
      border: `1px solid ${palette.neutralSecondaryAlt}`,

      ...btnInteractStyle({ palette }),

      '&:disabled': {
        background: palette.neutralLighter,
        color: palette.neutralTertiary,
      },
    },
  };
};
