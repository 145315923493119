import { include } from 'underscore.string';
import { Listener } from '@bingads-webui/listener';

/**
 * Console listener class
 */
export class ConsoleListener extends Listener {
  constructor(options = {}) {
    super(options);

    this.formatters = options.formatters || [];
    this.filters = options.filters || [];
  }

  init() {
    this.initialized = true;
  }

  /* eslint-disable no-console */
  write(logMessage) {
    if (!this.initialized || this.filterLogs(logMessage)) {
      return;
    }

    const formattedMsg = this.formatLogs(logMessage);

    const category = logMessage.logCategory || '';
    if (include(category.toLowerCase(), 'error')) {
      console.error(formattedMsg);
    } else {
      console.log(formattedMsg);
    }

    // TODO: use console.timeStamp for activity perf marker
  }
}
