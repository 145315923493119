// For the z-index defined in external place, we use leading "_" in key name to indicate it,
// and put them here ONLY FOR REFERENCE.

// We only focus on z-index of components in app-layout-container and direct children of <body>.
// That's to say, it doesn't matter that z-index of element A inside Content is greater than
// element B inside Header: A will still shows behind B, since Content z-index < Header z-index.
export const ZIndexShellComponents = {
  _HelpContainer: 1100, // defined in apex style file
  _BootstrapPopover: 1060, // defined in bootstrap of theme-2018
  _BootstrapModal: 1050, // defined in bootstrap of theme-2018
  _BootstrapModalBackdrop: 1040, // defined in bootstrap of theme-2018
  ModalBackDrop: 1040,
  Header: 1031,
  Loader: 1030, // should be less than Header
  Panel: 1000,
  TopBanner: 250,
  PromotionBanner: 250,
  Nav: 200,
  Status: 150,
  Menu: 100,
  Tab: 75,
  SubTab: 50,
  Content: 0,
};

// This is the common place for elements inside ".app-shell-content"
export const ZIndexContentComponents = {
  // add new elements here in descending order
  GridToolbar: 20,
  GridHeader: 20,
  StatusPopup: 10,
};
