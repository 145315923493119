import _ from 'underscore';
import { Mode } from './mode';

// these components have logic themselves to dynamically change layout
const dynamicComponents = {
  topBanner: {
    mode: Mode.Collapsed,
  },
  promotionBanner: {
    mode: Mode.Collapsed,
  },
};

const staticComponents = {
  header: {
    mode: Mode.Collapsed,
  },
  panelContainer: {
    mode: Mode.Collapsed,
  },
  nav: {
    mode: Mode.Expanded,
  },
  menu: {
    mode: Mode.Expanded,
  },
  status: {
    mode: Mode.Expanded,
  },
  tab: {
    mode: Mode.Collapsed,
  },
  subTab: {
    mode: Mode.Hidden,
  },
  content: {
    mode: Mode.Expanded,
  },
};

const base = { ...staticComponents, ...dynamicComponents };

const immersive = _.defaults({
  nav: {
    mode: Mode.Collapsed,
  },
}, staticComponents);

const fullScreen = _.defaults({
  nav: {
    mode: Mode.Hidden,
  },
  menu: {
    mode: Mode.Hidden,
  },
  status: {
    mode: Mode.Hidden,
  },
  tab: {
    mode: Mode.Hidden,
  },
}, staticComponents);

const withoutDate = {
  tab: {
    mode: Mode.Collapsed,
    showDatePicker: false,
  },
};

const withDisabledDate = {
  tab: {
    mode: Mode.Collapsed,
    showDisabledDatePicker: true,
    showDatePicker: false,
  },
};

const withHistoryButton = {
  tab: {
    mode: Mode.Collapsed,
    showDatePicker: false,
    showHistoryButton: true,
  },
};

const dateWithoutCompare = {
  tab: {
    mode: Mode.Collapsed,
    disableCompare: true,
  },
};

const fullScreenWithSummaryBar = _.defaults({
  nav: {
    mode: Mode.Hidden,
  },
  menu: {
    mode: Mode.Hidden,
  },
  tab: {
    mode: Mode.Hidden,
  },
}, staticComponents);

const fullScreenWithSummaryBarAndTab = _.defaults({
  nav: {
    mode: Mode.Hidden,
  },
  menu: {
    mode: Mode.Hidden,
  },
}, staticComponents);

const withoutNav = _.defaults({
  nav: {
    mode: Mode.Hidden,
  },
}, staticComponents);

const accountSummary = {
  nav: {
    mode: Mode.Hidden,
  },
};

const accountSummaryWithoutDate = {
  ...accountSummary,
  ...withoutDate,
};

const withMenuOnly = {
  nav: {
    mode: Mode.Hidden,
  },
  tab: {
    mode: Mode.Hidden,
  },
};

const withoutMenu = {
  menu: {
    mode: Mode.Hidden,
  },
};

const withSingleTab = {
  nav: {
    mode: Mode.Hidden,
  },
  tab: {
    singleTabMode: true,
  },
};

const withCollapsedMenuOnly = _.defaults({
  nav: {
    // Main menu
    mode: Mode.Collapsed,
  },
  menu: {
    // Page menu
    mode: Mode.Hidden,
  },
  status: {
    // Top bar containing 'Back' button and page title
    mode: Mode.Hidden,
  },
  tab: {
    // Horizontal tabs on top of page bearing page titles
    mode: Mode.Hidden,
    showDatePicker: false,
  },
}, staticComponents);

const withCollapsedMenuAndNav = _.defaults({
  nav: {
    // Main menu
    mode: Mode.Collapsed,
  },
  menu: {
    // Page menu
    mode: Mode.Expanded,
  },
  status: {
    // Top bar containing 'Back' button and page title
    mode: Mode.Expanded,
  },
  tab: {
    // Horizontal tabs on top of page bearing page titles
    mode: Mode.Hidden,
    showDatePicker: false,
  },
}, staticComponents);

const withTabAndSubTab = {
  tab: {
    mode: Mode.Collapsed,
  },
  subTab: {
    mode: Mode.Expanded,
  },
};

const signupScreen = _.defaults({
  header: {
    mode: Mode.Expanded,
  },
  nav: {
    mode: Mode.Hidden,
  },
  menu: {
    mode: Mode.Hidden,
  },
  status: {
    mode: Mode.Hidden,
  },
  tab: {
    mode: Mode.Hidden,
  },
}, base);

export const layoutConfigs = {
  default: base,
  immersive,
  fullScreen,
  withoutDate,
  withDisabledDate,
  withoutNav,
  withSingleTab,
  fullScreenWithSummaryBar,
  fullScreenWithSummaryBarAndTab,
  accountSummary,
  accountSummaryWithoutDate,
  withMenuOnly,
  withCollapsedMenuOnly,
  withCollapsedMenuAndNav,
  withoutMenu,
  dateWithoutCompare,
  withTabAndSubTab,
  withHistoryButton,
  signupScreen,
};
