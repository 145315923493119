import { getUtcTime } from '@bingads-webui-universal/primitive-utilities';
import { constants } from './../constants';
import { Logger } from './logger';

/* eslint-disable max-len */

/**
 * Default Error Logger
 */
export class ErrorLogger extends Logger {
  /**
   * error - logs passed in args as error logs
   * @param {object} scenario - scenario of activity that invoked log method
   * @param {object} activity - activity that invoked the log method
   * @param {object} parentScenario - parent of the scenario
   * @param {object} args - args passed in to the error log method
   * @returns {undefined} - no returns
   */
  error(scenario, activity, parentScenario, [message, api, requestId, impactUser, httpMethod, logLevel]) {
    super.log({
      api,
      message,
      requestId,
      impactUser: impactUser || constants.IMPACTUSER.NA,
      httpMethod,
      timeStamp: getUtcTime(),
      logCategory: constants.LOGCATEGORY.ERROR,
      logLevel: logLevel || constants.LOGLEVEL.INFO,
      entryType: constants.ENTRYTYPE.MESSAGE,
      activity,
      scenario,
      parentScenario,
    });
  }

  getLogMethods() {
    return ['error'];
  }
}
