export const toggleButtonStyle = ({ palette = {} }) => ({

  '& .toggle': {
    display: 'inline-block',
    textDecoration: 'none',

    '& .toggle-button': {
      display: 'inline-block',
      boxsizing: 'border-box',
      width: 40,
      height: 20,
      padding: 4,
      lineHeight: '10px',
      border: '1px solid',
      borderRadius: 30,
      'transition-duration': '0.2s',
      '-webkit-transition-duration': '0.2s',
    },

    '& .toggle-handle': {
      display: 'inline-block',
      boxsizing: 'border-box',
      width: 10,
      height: 10,
      borderRadius: 5,
      borderWidth: 5,
      borderStyle: 'solid',
      verticalAlign: 'top',
      'transition-duration': '0.2s',
      '-webkit-transition-duration': '0.2s',
    },
  },

  '& .toggle-on': {
    '& .toggle-button': {
      backgroundColor: palette.themePrimary,
      borderColor: palette.themePrimary,
    },

    '& .toggle-handle': {
      marginLeft: 20,
      borderColor: palette.white,
    },
  },

  '& .toggle-off': {
    '& .toggle-button': {
      backgroundColor: palette.white,
      borderColor: '#333333',
    },

    '& .toggle-handle': {
      marginRight: 20,
      borderColor: '#333333',
    },
  },
});
