import { internalLinkStyle } from '../internal-link-style';
import { underlineLinkStyle } from '../underline-link-style';

// Styles for panel itself
export const getTopGridPanelCommonStyle = ({ palette }) => ({
  '& .panel': {
    // All top of grid panel have same margin left and top
    marginTop: '24px',
    marginLeft: '32px',
    marginBottom: '0px',
    padding: 0,
    backgroundColor: 'white',

    // Learn more button styles.
    ...underlineLinkStyle({ palette, elementLocator: 'a.HelpTopic' }),

    '& .alert-info .alert-info-inline': {
      ...internalLinkStyle({ palette, elementLocator: '> a', isGreyBackground: true }),
    },

    '& .panel-heading': {
      backgroundColor: 'white',
      padding: '0px',
      fontWeight: 600,

      '& .panel-title': {
        fontSize: '20px',
        lineHeight: '27px',
        fontWeight: 600,
      },
    },

    '& .panel-body': {
      lineHeight: '19px',
      paddingLeft: '0px',
      paddingBottom: '0px',
      marginBottom: '18px', // leave space between body and the footer buttons

      '& hr': {
        margin: 0,
        borderTop: '0px',
      },
    },

    '& .panel-footer': {
      borderTop: `1px solid ${palette.neutralLight}`,
      paddingTop: '16px',
      paddingBottom: '16px',
      paddingLeft: '0px',
      marginTop: 0,
      backgroundColor: 'white',

      '& input.btn, & button.btn': {
        marginRight: '8px',
      },
      '& input.btn:focus': {
        outline: `1px solid ${palette.neutralSecondary}`,
        outlineOffset: '-3px',
      },
      '& input.btn-primary:focus': {
        outline: '1px solid #fff',
      },
      '& .previewTable': {
        margin: '5px 0 !important',
        maxHeight: '250px',
        display: 'inline-block',
        minWidth: '500px',
        width: 'auto !important',
      },
    },
  },
});
