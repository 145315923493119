import Promise from 'bluebird';

const GLOBAL_PAGE = '';

export default class PageReady {
  /**
   * Constructor
  */
  constructor() {
    this.p$pagesReady = {};
    this.resolvePageReadyCbs = {};
  }

  /**
   * Mark the page as ready. This will resolve the promise
   * and initialize all the delayed components.
   * @param {String} pageName page name, default is global/any page
   * @returns {void}
  */
  pageIsReady(pageName = GLOBAL_PAGE) {
    this.lazyInitializePromise(pageName);
    this.resolvePageReadyCbs[pageName]();

    // Also mark the global page as ready if any page is ready
    if (pageName !== GLOBAL_PAGE && this.resolvePageReadyCbs[GLOBAL_PAGE]) {
      this.resolvePageReadyCbs[GLOBAL_PAGE]();
    }
  }

  /**
   * Returns a promise that will resolve when the page is ready.
   * Listeners can use this function to delay execution until the page is ready.
   * @param {String} pageName page name, default is global/any page
   * @returns {Promise} - Promise that is resolved when the page is marked as ready.
   */
  afterPageReady(pageName = GLOBAL_PAGE) {
    this.lazyInitializePromise(pageName);
    return this.p$pagesReady[pageName];
  }

  /**
 * Lazily create the promise if it doesn't already exist
 * @private
 * @param {String} pageName page name, default is global/any page
 * @returns {void}
 */
  lazyInitializePromise(pageName = GLOBAL_PAGE) {
    if (this.p$pagesReady[pageName]) {
      return;
    }
    this.p$pagesReady[pageName] = new Promise((res) => {
      this.resolvePageReadyCbs[pageName] = res;
    });

    // Also initialize the global promise since it's tied to any page
    if (pageName !== GLOBAL_PAGE && !this.p$pagesReady[GLOBAL_PAGE]) {
      this.p$pagesReady[GLOBAL_PAGE] = new Promise((res) => {
        this.resolvePageReadyCbs[GLOBAL_PAGE] = res;
      });
    }
  }
}
