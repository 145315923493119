export const searchboxStyle = ({ palette = {}, iconFontFamily = 'MsAds MDL2 Assets' }) => ({
  '& .searchbox': {
    padding: 0,
  },

  '& .searchbox .form-horizontal': {
    width: '100%',
  },

  '& .searchbox-input': {
    paddingLeft: 40,
  },

  '& .searchbox .form-control': {
    border: 0,
    borderBottom: `1px solid ${palette.neutralLighter}`,
  },

  '& .filter_search_icon_small': {
    fontFamily: iconFontFamily,
    color: palette.themePrimary,
    fontSize: 14,
  },

  '& .filter_search_icon_small:before': {
    content: '"\\E721"',
  },
});
