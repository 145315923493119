import React from 'react';
import { I18nContext } from './i18n-context';

export function withI18nConsumer(Component) {
  return props => (
    <I18nContext.Consumer>
      {i18n => (
        <Component i18n={i18n} {...props} />
      )}
    </I18nContext.Consumer>
  );
}

// withI18nProvider is exported only for testing
export function withI18nProvider(Component, i18n) {
  return props => (
    <I18nContext.Provider value={i18n}>
      <Component {...props} />
    </I18nContext.Provider>
  );
}

export function withI18n(Component, i18n) {
  return withI18nProvider(withI18nConsumer(Component), i18n);
}
