import _ from 'underscore';

const event = {
  BEFORESEND: 'beforeSend', SUCCESS: 'success', COMPLETE: 'complete', ERROR: 'error',
};

/**
 * AjaxInterceptor - Intercepts ajax events (beforeSend, success, error, complete)
 */
export class AjaxEventInterceptor {
  /**
   * @param {object} options - options from ajax call
   * @param {object} requestId - request id
   * @param {object} stubOptions - stub options to be used in ajax logging
   * @param {object} eventHandler - handler for ajax events
   * @param {object} activity - activity to be used by the ajax logging calls
   */
  constructor(options, requestId, stubOptions, eventHandler, activity) {
    this.options = options;
    this.requestId = requestId;
    this.stubOptions = stubOptions;
    this.eventHandler = eventHandler;
    this.activity = activity;
  }

  triggerEventInterceptors() {
    const pageTrackingId = _.result(this.stubOptions, 'pageTrackingId');
    const ajaxContext = {
      stubOptions: this.stubOptions,
      url: this.options.url,
      requestId: this.requestId,
      pageTrackingId,
      requestResult: true,
      httpMethod: this.options.type,
      error: '',
      ajaxStartTime: 0,
      ajaxRenderStartTime: 0,
      entityCount: this.stubOptions.getPageSize(this.options.data),
      activity: this.activity,
    };

    if (this.eventHandler.beforeSendHandler) this.interceptBeforeSend(this.options, ajaxContext);
    if (this.eventHandler.successHandler) this.interceptSuccess(this.options, ajaxContext);
    if (this.eventHandler.errorHandler) this.interceptError(this.options, ajaxContext);
    if (this.eventHandler.completeHandler) this.interceptComplete(this.options, ajaxContext);
  }

  /* eslint-disable no-param-reassign */
  interceptBeforeSend(ajaxOptions, ajaxContext) {
    const context = this;

    if (_.isUndefined(ajaxOptions[event.BEFORESEND])) {
      ajaxOptions[event.BEFORESEND] = (...args) => {
        context.eventHandler.beforeSendHandler(ajaxContext, args);
      };
    } else if (_.isFunction(ajaxOptions[event.BEFORESEND])) {
      const originalEventHandler = ajaxOptions[event.BEFORESEND];
      ajaxOptions[event.BEFORESEND] = function onBeforeSend(...args) {
        const wrappedFunc = context.constructor.handleException(
          originalEventHandler,
          context.options.url,
          context.requestId,
          context.activity
        );
        const originalRet = wrappedFunc.apply(this, args);
        context.eventHandler.beforeSendHandler(ajaxContext, args);
        if (originalRet && _.isFunction(originalRet.onBeforeSendDone)) {
          return originalRet.onBeforeSendDone(ajaxContext, args);
        }
        return originalRet;
      };
    }
  }

  interceptSuccess(ajaxOptions, ajaxContext) {
    const context = this;

    if (_.isUndefined(ajaxOptions[event.SUCCESS])) {
      ajaxOptions[event.SUCCESS] = (...args) => {
        context.eventHandler.successHandler(ajaxContext, args);
        if (context.eventHandler.successRenderHandler) {
          context.eventHandler.successRenderHandler(ajaxContext, args);
        }
      };
    } else if (_.isFunction(ajaxOptions[event.SUCCESS])) {
      const originalEventHandler = ajaxOptions[event.SUCCESS];
      ajaxOptions[event.SUCCESS] = function onSuccess(...args) {
        context.eventHandler.successHandler(ajaxContext, args);
        const wrappedFunc = context.constructor.handleException(
          originalEventHandler,
          context.options.url,
          context.requestId,
          context.activity
        );
        const originalRet = wrappedFunc.apply(this, args);
        if (context.eventHandler.successRenderHandler) {
          context.eventHandler.successRenderHandler(ajaxContext, args);
        }
        return originalRet;
      };
    }
  }

  interceptError(ajaxOptions, ajaxContext) {
    const context = this;

    if (_.isUndefined(ajaxOptions[event.ERROR])) {
      ajaxOptions[event.ERROR] = (...args) => {
        context.eventHandler.errorHandler(ajaxContext, args);
      };
    } else if (_.isFunction(ajaxOptions[event.ERROR])) {
      const originalEventHandler = ajaxOptions[event.ERROR];
      ajaxOptions[event.ERROR] = function onError(...args) {
        context.eventHandler.errorHandler(ajaxContext, args);
        const wrappedFunc = context.constructor.handleException(
          originalEventHandler,
          context.options.url,
          context.requestId,
          context.activity
        );
        const originalRet = wrappedFunc.apply(this, args);
        return originalRet;
      };
    }
  }

  interceptComplete(ajaxOptions, ajaxContext) {
    const context = this;

    if (_.isUndefined(ajaxOptions[event.COMPLETE])) {
      ajaxOptions[event.COMPLETE] = (...args) => {
        context.eventHandler.completeHandler(ajaxContext, args);
      };
    } else if (_.isFunction(ajaxOptions[event.COMPLETE])) {
      const originalEventHandler = ajaxOptions[event.COMPLETE];
      ajaxOptions[event.COMPLETE] = function onComplete(...args) {
        const wrappedFunc = context.constructor.handleException(
          originalEventHandler,
          context.options.url,
          context.requestId,
          context.activity
        );
        const originalRet = wrappedFunc.apply(this, args);
        context.eventHandler.completeHandler(ajaxContext, args);
        return originalRet;
      };
    }
  }

  static handleException(func, api, requestId, activity, message) {
    return function wrappedFunc(...args) {
      const context = this;
      let error = !_.isUndefined(message) ? `${message}: ` : '';
      try {
        return func.apply(context, args);
      } catch (ex) {
        if (!ex.message) {
          error += ex.toString();
        } else if (!ex.stack) {
          error += ex.message;
        } else {
          error += `${ex.message}\tStackTrace: ${ex.stack}`;
        }
        activity.error(error, api, requestId);

        throw ex;
      }
    };
  }
}
