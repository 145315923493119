export const inlineErrorStyle = ({ palette = {}, iconFontFamily = 'MsAds MDL2 Assets' }) => ({
  '.inline-editor-errors': {
    '& .error-messages': {
      listStyleType: 'none',
      '& .grid-error': {
        '&.spritedimage': {
          fontFamily: iconFontFamily,
          padding: '10px',
          fontSize: '10pt',
        },
        '&.spritedimage:before': {
          content: '"\\EA39"',
          color: palette.red,
        },
      },
      '& .error-message': {
        fontSize: '14px',
      },
    },
  },
});
