export const underlineLinkStyle = ({ palette = {}, elementLocator = 'a', isGreyBackground = false }) => ({
  [`& ${elementLocator}`]: {
    textDecoration: 'underline',
    color: isGreyBackground ? palette.themeDarkAlt : palette.themePrimary,
    '&:focus': {
      textDecoration: 'underline',
      outline: `1px solid ${palette.neutralSecondary}`,
    },
    '&:hover': {
      textDecoration: 'underline',
      color: palette.themeDarker,
    },
  },
});
