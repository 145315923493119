import _ from 'underscore';

/**
 * OdataErrorMapper - Maps ajax errors from odata calls to known user errors
 */
export class OdataErrorMapper {
  /**
   * @param {object} errorCodes - odata user error codes
   */
  constructor(errorCodes) {
    this.errorCodes = errorCodes || [];
  }

  /**
   * Checks if an ajax error is a known user error
   * @param {object} ajaxData - data related to the current ajax call
   * @returns {bool} - if the error maps to a known error or not
   */
  check(ajaxData) {
    const errorString = ajaxData.error;

    return _.any(this.errorCodes, errorCode => errorString.toUpperCase().indexOf(errorCode) >= 0);
  }
}
