import { AjaxStub, ErrorStub, AjaxEventHandler } from '@bingads-webui/instrumentation-stubs';
import { isInternalUrl } from '@bingads-webui/url-util';
import _ from 'underscore';

export function setStub({
  instrumentation,
  pageTrackingId,
  errorMappers,
  objectsToStub,
  ajaxStubOptions,
  lcid,
}) {
  // If the application pulls in jQuery, stub it for ajax call.
  const filteredObjectsToStub = _.compact(objectsToStub);
  if (!_.isEmpty(filteredObjectsToStub)) {
    const shouldAddHeaders = ajaxContext => isInternalUrl(ajaxContext.url);
    const eventHandler = new AjaxEventHandler({ errorMappers, shouldAddHeaders });
    const ajaxStub = new AjaxStub({
      stubOptions: {
        filteredObjectsToStub,
        pageTrackingId,
        lcid,
        ajaxStubOptions,
      },
      eventHandler,
      instrumentation,
    });

    ajaxStub.init();
  }

  const errorStub = new ErrorStub({
    instrumentation,
  });
  errorStub.init();
}
