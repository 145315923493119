import _ from 'underscore';
import { useContext, useEffect } from 'react';
import { LayoutContext } from './layout-context';

export const useChangeLayout = (layout) => {
  const { change } = useContext(LayoutContext);
  useEffect(() => {
    if (!_.isEmpty(layout))change(layout);
  }, [change, layout]);
};
