import _ from 'underscore';

export class ContextProcessor {
  constructor(options) {
    this.pageContext = _.defaults(options.pageContext || {}, {
      CurrentCustomer: { Id: -1 },
      CurrentAccount: { Id: -1 },
      CurrentUser: {
        Id: -1, Locale: '', Market: '', UserRole: '', ManagedBy: '', LocaleID: '',
      },
    });
  }

  get CustomerId() {
    return this.pageContext.CurrentCustomer.Id;
  }

  get AccountId() {
    return _.result(this.pageContext.CurrentAccount, 'Id', -1);
  }

  get UserId() {
    return this.pageContext.CurrentUser.Id;
  }

  get Lcid() {
    return this.pageContext.CurrentUser.Locale || this.pageContext.CurrentUser.LocaleID;
  }

  get CustomerMarket() {
    return this.pageContext.CurrentUser.Market;
  }

  get UserRole() {
    const role = (this.pageContext &&
      this.pageContext.Permissions &&
      this.pageContext.Permissions.IsInternalUser) ? '(Internal)' : '';
    return `${this.pageContext.CurrentUser.UserRole}${role}`;
  }

  get CustomerManagedBy() {
    return this.pageContext.CurrentUser.ManagedBy;
  }

  get SessionId() {
    return (this.pageContext.Omniture || {}).SessionId || '';
  }

  get PageTrackingId() {
    return this.pageContext.PageTrackingId;
  }

  get PageVersion() {
    return this.pageContext.Version;
  }

  get Pilots() {
    return 'grid';
  }

  process(rawLogs) {
    return _.extend(rawLogs, {
      customerId: this.CustomerId,
      accountId: this.AccountId,
      userId: this.UserId,
      lcid: this.Lcid,
      sessionId: this.SessionId,
      pageTrackingId: this.PageTrackingId,
      pageVersion: this.PageVersion,
      market: this.CustomerMarket,
      userRole: this.UserRole,
      managedBy: this.CustomerManagedBy,
      pilots: this.Pilots,
    });
  }
}
