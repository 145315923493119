import _ from 'underscore';
import { Formatter } from '@bingads-webui/listener';

// Values from window.performance.timing and firstPaintTime
const pageNavigationColumns = ['navigationStart', 'unloadEventStart', 'unloadEventEnd', 'redirectStart', 'redirectEnd',
  'fetchStart', 'domainLookupStart', 'domainLookupEnd', 'connectStart', 'connectEnd', 'secureConnectionStart', 'requestStart',
  'responseStart', 'responseEnd', 'domLoading', 'domInteractive', 'domContentLoadedEventStart', 'domContentLoadedEventEnd',
  'domComplete', 'loadEventStart', 'loadEventEnd', 'navigationStart', 'unloadEventStart', 'unloadEventEnd', 'redirectStart',
  'redirectEnd', 'redirectCount', 'fetchStart', 'firstPaintTime', 'domainLookupStart', 'domainLookupEnd', 'connectStart', 'connectEnd', 'secureConnectionStart',
  'requestStart', 'responseStart', 'responseEnd', 'domLoading', 'domInteractive', 'domContentLoadedEventStart', 'domContentLoadedEventEnd',
  'domComplete', 'loadEventStart', 'loadEventEnd', 'landingPg', 'inactiveTime'];

// Values from window.perfromance.getEntries('resource')
const resourceTimingColumns = ['unloadEventStart', 'unloadEventEnd', 'domInteractive',
  'domContentLoadedEventStart', 'domContentLoadedEventEnd', 'domComplete', 'loadEventStart',
  'loadEventEnd', 'type', 'redirectCount', 'initiatorType', 'workerStart', 'redirectStart',
  'redirectEnd', 'fetchStart', 'domainLookupStart', 'domainLookupEnd', 'connectStart', 'connectEnd',
  'secureConnectionStart', 'requestStart', 'responseStart', 'responseEnd', 'transferSize', 'encodedBodySize',
  'decodedBodySize', 'name', 'entryType', 'startTime', 'duration'];

export class PerformanceLogsFormatter extends Formatter {
  format(log) {
    const columns = log.isPageNavigationLog ? pageNavigationColumns : resourceTimingColumns;

    let formattedLogs = _.pick(log, columns);

    formattedLogs = _.extend(formattedLogs, {
      uid: log.userId,
      aid: log.accountId,
      cid: log.customerId,
      mkt: log.market,
      href: log.href,
      referrer: log.referrer,
      dbgMode: log.isDebugMode,
      pgId: log.pageTrackingId || null,
      pgVer: log.pageVersion || null,
    });

    return formattedLogs;
  }
}
