import { constants } from '@bingads-webui/instrumentation';
import { Filter } from '@bingads-webui/listener';

export class LogLevelFilter extends Filter {
  check(logMessage) {
    const appLogLevel = constants.LOGLEVEL.INFO;

    if (logMessage.logLevel) {
      return logMessage.logLevel.value > appLogLevel.value;
    }

    return false;
  }
}
