import _ from 'underscore';
import { ScenarioFactory } from './scenario-factory';

/**
 * LoggingService class responsible for setting up scenarioFactories
 */
export class LoggingService {
  /**
   * @param {object} options - contains the loggers/debugMode to be used
   */
  constructor(options) {
    this.loggers = options.loggers;
    this.isDebugMode = options.isDebugMode;
    // Record (scenario, activity) map for duplication check in debug mode
    this.registry = this.isDebugMode ? {} : undefined;
  }

  checkSchemaDuplication(scenarios) {
    /* eslint-disable no-restricted-syntax */
    if (this.isDebugMode && this.registry !== undefined) {
      for (const scenario of scenarios) {
        const { name: scenarioName, activity: activities } = scenario;
        // eslint-disable-next-line no-undef
        this.registry[scenarioName] = this.registry[scenarioName] || new Set();

        for (const { name: activityName } of activities) {
          if (this.registry[scenarioName].has(activityName)) {
            throw new Error(`[Debug] Scenario: ${scenarioName} Activity: ${activityName} already registered.`);
          }
          this.registry[scenarioName].add(activityName);
        }
      }
    }
    /* eslint-enable no-restricted-syntax */
  }

  parseSchema(scenarioSchema) {
    const scenarios = scenarioSchema.scenario;
    this.checkSchemaDuplication(scenarios);
    const scenarioFactories = {};
    _.each(scenarios, (scenario) => {
      scenarioFactories[scenario.name] = new ScenarioFactory(scenario, this.loggers);
    });
    return scenarioFactories;
  }
}
