import { constants } from '@bingads-webui/instrumentation';

export default {
  logTableNames: {
    [constants.LOGCATEGORY.ERROR]: {
      name: 'OpportunityEvent',
      priority: 1,
    },
    [constants.LOGCATEGORY.PERFORMANCE]: {
      name: 'OpportunityEvent',
      priority: 2,
    },
    [constants.LOGCATEGORY.TRACE]: {
      name: 'OpportunityEvent',
      priority: 3,
    },
  },
  defaultLogCategory: constants.LOGCATEGORY.TRACE,
};
