import _ from 'underscore';
import { getDefaultConfig } from './default-config';
import { LoggingService } from './logging-service';
import { LogTransmitter } from './log-transmitter';

/**
 * Class representing Instrumentation
 */
export class Instrumentation {
  /**
   * Create an instance of Instrumentation
   * @param {object} config - should contain a config of loggers/processors/listeners:
   * loggers - specify custom loggers to override or add to the default trace, perf, error loggers.
   * processors - specify custom log processors to modify or enrich the logs.
   * listeners - specify listeners to write logs to.
   * isDebugMode - specify if it's in debug mode or not. Default to false. It will throw error
   * if (scenario, activity) pair has already been registered before in debug mode
   */
  constructor(config = {}) {
    this.setup(config);

    // scenarioContext maintains the current scenario in use.
    this.scenarioContext = {};

    this.initTransmissionService(this.scenarioContext);

    this.initLoggingService();
  }

  setup(config) {
    const defaultConfig = getDefaultConfig();
    this.loggers = _.defaults(config.loggers || {}, defaultConfig.loggers);
    this.processors = _.defaults(config.processors || {}, defaultConfig.processors);
    this.listeners = _.defaults(config.listeners || {}, defaultConfig.listeners);
    this.isDebugMode = config.isDebugMode || defaultConfig.isDebugMode;

    window.addEventListener('unload', () => this.stop());
  }

  stop() {
    this.beforeStop();
    this.logTransmitter.stop();
  }

  initTransmissionService(scenarioContext) {
    this.logTransmitter = new LogTransmitter({
      scenarioContext,
      processors: this.processors,
      listeners: this.listeners,
    });
    this.writeRawLog = rawLogs => this.logTransmitter.transmit(rawLogs);
  }

  initLoggingService() {
    _.each(this.loggers, (logger) => {
      logger.logTransmitter = this.logTransmitter; // eslint-disable-line no-param-reassign
    });
    this.loggingService = new LoggingService({
      loggers: this.loggers,
      isDebugMode: this.isDebugMode,
    });
  }

  /**
   * adds specified scenarios on to the instrumentation instance
   * @param {object} scenarioSchema - schema that specifies the scenarios to be added
   * @returns {Instrumentation} - return self for chaining
   */
  addScenario(scenarioSchema) {
    _.extend(this, this.loggingService.parseSchema(scenarioSchema));
    return this;
  }

  /**
   * add simple scenario and activity for one time use
   * @param {object} scenarioContext - scenario context such as name, parentScenario
   * @returns {object} - activity instance ready to call logger methods
   * usage would be: instrumentation.createScenarioAndActivity("scenario", "activity")
   * DO NOT use this if the scenario is created multiple times, in which case
   * the scenarioId would be different
   *  .signal("something happened")
   */
  createScenarioAndActivity(scenarioContext) {
    const { parentScenario, scenarioName, activityName } = scenarioContext;

    // build the scenario schema with activity
    const schema = {
      scenario: [
        {
          name: scenarioName,
          value: scenarioName,
          activity: activityName ? [
            activityName,
          ] : [],
        },
      ],
    };
    this.addScenario(schema);
    const scenario = this[scenarioName].create(parentScenario);
    const activity = activityName ? scenario[activityName].create() : undefined;
    return {
      scenario,
      activity,
    };
  }

  /**
   * add simple scenario and activity for one time use
   * @param {object} scenarioContext - scenario context such as name, parentScenario
   * @returns {object} - scenario instance
   */
  createScenario(scenarioContext) {
    return this.createScenarioAndActivity(scenarioContext).scenario;
  }

  /**
   * method for custom logic to be run before the instrumentation service exits
   * can be overridden
   * @returns {undefined} - no returns
   */
  beforeStop() {
    _.noop();
  }

  get ScenarioContext() {
    return this.scenarioContext;
  }
}
