// Styles for legacy filter bar to make it looks like New UI, no need to use it for new filter bar
export const getLegacyFilterBarStyle = ({ palette }) => ({
  '& .filter-bar': {
    '& .panel-component': {
      backgroundColor: palette.neutralLighter,
      marginBottom: '0 !important',
      minHeight: 56,
      minWidth: 1000,
      lineHeight: '24px',
      padding: '15px 10px',

      '& .panel-body': {
        padding: 0,

        '& .filter_title_collapsed': {
          marginRight: 35,
        },

        '& .filter_row_readonly .humanized': {
          '& .column, & .filter_operator': {
            marginRight: 5,
          },

          '& .filterValue': {
            fontWeight: 600,
          },

          '& .filterand': {
            display: 'inline-block',
            width: 20,
          },
        },

        '& .filter_links': {
          marginLeft: 40,

          '& a': {
            display: 'inline-block',
            textDecoration: 'none',

            '&:nth-child(2n)': {
              fontWeight: 600,
              marginRight: 30,
              color: palette.neutralPrimary,
            },

            '&:last-child': {
              marginRight: 0,
            },
          },
        },

        '& .filter_title_collapsed, & .filter_row_readonly, & .filter_links': {
          display: 'inline-block',
        },
      },
    },
  },
});
