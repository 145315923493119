export const controlStyle = ({ palette = {} }) => ({
  '.form-control': {
    '-webkit-box-shadow': 'none',
    backgroundColor: palette.white,
    backgroundImage: 'none',
    border: `1px solid ${palette.neutralSecondary}`,
    borderRadius: 2,
    boxShadow: 'none',
    color: palette.neutralPrimary,
    display: 'block',
    fontSize: 14,
    height: 32,
    lineHeight: 1.5,
    padding: '6px 12px',
    transition: 'border-color .15s ease-in-out, box-shadow .15s ease-in-out',

    '&:active': {
      borderColor: palette.themePrimary,
    },

    '&:hover': {
      borderColor: palette.neutralPrimary,
    },

    '&:focus': {
      '-webkit-box-shadow': 'none',
      borderColor: palette.themePrimary,
      boxShadow: 'none',
      outline: 0,
    },
  },
});
