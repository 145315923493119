import { getUtcTime } from '@bingads-webui-universal/primitive-utilities';
import { constants } from './../constants';
import { Logger } from './logger';

/**
 * Ajax Logger - internal logger that can used by for logging Ajax calls
 */
export class AjaxLogger extends Logger {
  /**
   * ajax - logs passed in args as ajax logs
   * @param {object} scenario - scenario of activity that invoked log method
   * @param {object} activity - activity that invoked the log method
   * @param {object} parentScenario - parent of the scenario
   * @param {object} args - args passed in to the ajax log method
   * @returns {undefined} - no returns
   */
  ajax(scenario, activity, parentScenario, [requestId, api,
    isMethodEnter, httpMethod, timeTaken, pass, message, entityCount, includeRendering]) {
    super.log({
      requestId,
      api,
      isMethodEnter,
      httpMethod,
      timeTaken,
      pass,
      message,
      entityCount,
      entryType: includeRendering === true ?
        constants.ENTRYTYPE.AJAXRENDER :
        constants.ENTRYTYPE.AJAX,
      timeStamp: getUtcTime(),
      logLevel: constants.LOGLEVEL.INFO,
      logCategory: constants.LOGCATEGORY.PERFORMANCE,
      activity,
      scenario,
      parentScenario,
    });
  }

  getLogMethods() {
    return ['ajax'];
  }
}
