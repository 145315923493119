import _ from 'underscore';
/**
 * Sink that is compatible with SessionStorage.
 * Can be passed in to the listener to receive logs.
 */
export class SessionStorageSink {
  /**
   * Creates an instance of SessionStorageSink.
   * @param {Object} options - Options to initialize
   * @param {Number} [options.maxSize=2000] - Maximum size of logs array.
   * @param {String} options.storageKey - Key to use in session storage
   * Pass in -1 to have unbounded array.
   */
  constructor(options = {}) {
    this.logs = [];
    this.maxSize = options.maxSize || 2000;

    if (!options.storageKey) {
      throw new Error('Cannot create sink without storageKey');
    }

    if (SessionStorageSink.keyCache[options.storageKey]) {
      throw new Error('Storage key is already initialized on page');
    }
    SessionStorageSink.keyCache[options.storageKey] = true;

    this.storageKey = options.storageKey;
    const existingItemsStr = window.sessionStorage.getItem(this.storageKey);
    const existingItems = _.isEmpty(existingItemsStr) ? [] : JSON.parse(existingItemsStr);
    existingItems.forEach(formattedMsg => this.write(formattedMsg));

    window.addEventListener('beforeunload', () => {
      this.setItemsOnUnload();
    });
  }

  /**
   * Write log to the sink
   * @param {Object} log - Log object to write
   * @returns {undefined} - no returns
   */
  write(log) {
    this.logs.push(log);

    while (this.maxSize > -1 && this.logs.length > this.maxSize) {
      this.logs.shift();
    }
  }

  /**
   * Getter for logs written to sink. Returns the
   * logs array.
   * @readonly
  */
  get items() {
    return this.logs;
  }

  setItemsOnUnload() {
    window.sessionStorage.setItem(this.storageKey, JSON.stringify(this.logs));
  }

  static keyCache = {};
}
