import _ from 'underscore';
import { generateGuid } from '@bingads-webui-universal/primitive-utilities';

/**
 * Activity class to create an instance of an Activity
 */
class Activity {
  constructor(scenario, activitySchema, loggers, parentScenario) {
    const simpleActivity = (typeof activitySchema === 'string');
    this.name = simpleActivity ? activitySchema : activitySchema.name;
    this.value = simpleActivity ? activitySchema : activitySchema.value;
    this.setting = simpleActivity ? undefined : activitySchema.setting;
    this.id = generateGuid();

    // Add logger methods on the activity
    _.each(loggers, (logger) => {
      const logMethods = logger.getLogMethods();
      _.each(logMethods, (logMethod) => {
        this[logMethod] = (...args) => logger[logMethod](scenario, this, parentScenario, args);
      });
    });
  }
}

/**
 * ActivityFactory class responsible for creating Activity
 */
export class ActivityFactory {
  /**
   * @param {object} scenario - scenario the activity belongs to
   * @param {object} activitySchema - schema of scenario to be created
   * @param {object} loggers - available loggers
   * @param {object} parentScenario - parentScenario of the scenario
   */
  constructor(scenario, activitySchema, loggers, parentScenario) {
    this.scenario = scenario;
    this.activitySchema = activitySchema;
    this.parentScenario = parentScenario;
    this.loggers = loggers;
  }

  create() {
    return new Activity(this.scenario, this.activitySchema, this.loggers, this.parentScenario);
  }
}
