export const columnChooserStyle = {
  '& .column-chooser .option': {
    padding: '6px 10px',
  },
  '& .column-chooser .option:hover': {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  '& .column-chooser input[type="checkbox"]': {
    margin: '0 5px 0 0',
  },
  '& .column-chooser-instructions': {
    marginBottom: '35px',
  },
  '& .column-chooser-categories': {
    position: 'relative',
    float: 'left',
    width: '180px',
    left: '1px',
    zIndex: '1',
  },
  '& .column-chooser-list': {
    position: 'relative',
    border: '1px solid #bbb',
    background: 'white',
    boxSizing: 'border-box',
    height: '300px',
    width: '340px',
    float: 'left',
    overflow: 'hidden',
  },
  '& .column-chooser-list+.column-chooser-list': {
    marginLeft: '8px',
  },
  '& .column-chooser-list.right': {
    marginLeft: '10px',
  },
  '& .column-chooser-option-header': {
    padding: '10px',
    backgroundColor: '#f4f4f4',
    width: '340px',
  },
  '& .column-chooser-bulk-control': {
    padding: '10px',
  },
  '& .column-chooser-category': {
    padding: '10px',
    cursor: 'pointer',
    borderTop: '0',
  },
  '& .column-chooser-category.active': {
    backgroundColor: 'white',
    border: '1px solid #bbb',
    borderRightColor: 'white',
    borderRight: '0',
  },
  '& .column-chooser-category:focus, & .column-chooser-category.k-state-focused': {
    outline: '1px dashed #333 !important',
  },
  '& .column-chooser-options': {
    listStyle: 'none',
    verticalAlign: 'top',
    overflowX: 'visible',
    overflowY: 'auto',
    padding: '0 10px',
    height: '230px',
  },
  '& .column-chooser-options.selected': {
    padding: '10px',
    height: '290px',
  },
  '& .column-chooser-options.available': {
    height: '250px',
  },
  '& .column-chooser-option': {
    border: '1px solid white',
    borderLeftWidth: '0',
    borderRightWidth: '0',
    padding: '6px 10px',
  },
  '& .column-chooser-option:hover': {
    backgroundColor: '#e5e5e5',
    borderColor: '#e5e5e5',
  },
  '& .column-chooser-message': {
    marginBottom: '15px',
    fontSize: '12px',
  },
  '& .column-chooser-option-selected': {
    cursor: 'move',
    padding: '5px',
    marginBottom: '5px',
    backgroundColor: '#e7f0f6',
    border: '1px solid #1570a6',
    height: '28px !important',
  },
  '& .column-chooser-option-selected:hover': {
    backgroundColor: '#f4f4f4',
    borderColor: '#606060',
  },
  '& .column-chooser-option-selected .remove': {
    paddingTop: '2px',
  },
  '& .column-chooser-option-fixed': {
    cursor: 'not-allowed',
  },
  '& .column-chooser-option-unmovable': {
    backgroundColor: '#e5e5e5',
    borderColor: 'gray',
    cursor: 'not-allowed',
    padding: '7px',
  },
  '& .column-chooser-option-placeholder': {
    border: '1px solid #1570a6',
    opacity: '& .5',
    margin: '6px 10px',
    height: '20px',
  },
  '& .column-chooser': {
    display: 'block !important',
    lineHeight: '15px',
    fontWeight: 'normal',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area': {
    display: 'inline-block',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-option': {
    float: 'left',
    padding: '0',
    backgroundColor: '#f4f4f4',
    border: 'none',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-option .column-chooser-option-header': {
    padding: '10px',
    width: '340px',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-option .column-chooser-search-box': {
    border: '1px solid #F3F2F1',
    position: 'relative',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-option .search-input': {
    border: 'none',
    height: '48px',
    paddingLeft: '48px',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-option .glyphicon-search': {
    top: '16px',
    left: '16px',
    color: '#0078d4',
    position: 'absolute',
    fontSize: '16px',
    fontFamily: 'MsAds MDL2 Assets',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-option .glyphicon-search:before': {
    content: '"\\E721"',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-option .category-tabs': {
    position: 'relative',
    left: '1px',
    zIndex: '1',
    marginBottom: '8.5px',
    marginTop: '0',
    paddingLeft: '0',
    float: 'left',
    width: '180px',
    listStyle: 'none',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-option .category-tabs>li': {
    padding: '10px',
    cursor: 'pointer',
    borderTop: '0',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-option .category-tabs>li.active': {
    backgroundColor: 'white',
    border: '1px solid #BBB',
    borderRightColor: 'white',
    borderRight: '0',
    fontWeight: 'bold',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-option .options-view': {
    float: 'left',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-option .options-view .column-chooser-option-list': {
    position: 'relative',
    border: '1px solid #BBB',
    background: 'white',
    boxSizing: 'border-box',
    height: '300px',
    width: '340px',
    overflow: 'hidden',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-option .options-view .column-chooser-option-list .column-chooser-bulk-control': {
    padding: '10px',
    textAlign: 'right',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-option .options-view .column-chooser-option-list ul.column-chooser-options': {
    listStyle: 'none',
    verticalAlign: 'top',
    overflowX: 'visible',
    overflowY: 'auto',
    padding: '0 10px',
    height: '250px',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-option .options-view .column-chooser-option-list ul.column-chooser-options span.option-not-selected': {
    fontWeight: 'bold',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-option .options-view .column-chooser-option-list ul.column-chooser-options span.option-status': {
    float: 'right',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-option .options-view .column-chooser-option-list ul.column-chooser-options span.option-status.disabled': {
    color: '#ccc',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-option .options-view .column-chooser-option-list ul.column-chooser-options a.add-column-link': {
    float: 'right',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-selected': {
    float: 'left',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-selected .column-chooser-selected-header': {
    padding: '10px',
    backgroundColor: '#f4f4f4',
    width: '340px',
    marginLeft: '0',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-selected .draggable-list-view': {
    position: 'relative',
    border: '1px solid #BBB',
    background: 'white',
    boxSizing: 'border-box',
    height: '300px',
    width: '340px',
    overflow: 'hidden',
    marginLeft: '10px',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-selected .draggable-list-view ul.draggable-list': {
    padding: '10px',
    height: '290px',
    overflowX: 'visible',
    overflowY: 'auto',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-selected .draggable-list-view ul.draggable-list li.draggable-list-item.draggable-list__item-locked': {
    backgroundColor: '#E5E5E5',
    marginBottom: '5px',
    border: '1px solid gray',
    cursor: 'not-allowed',
    padding: '7px',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-selected .draggable-list-view ul.draggable-list li.draggable-list-item.draggable-list__item-draggable': {
    cursor: 'move',
    padding: '5px',
    marginBottom: '5px',
    backgroundColor: '#e7f0f6',
    border: '1px solid #008272',
    height: '28px',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-selected .draggable-list-view ul.draggable-list li.draggable-list-item.draggable-list__item-draggable.draggable-list__item-preview': {
    opacity: '& .5',
    marginLeft: '10px',
    marginRight: '10px',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-selected .draggable-list-view ul.draggable-list li.draggable-list-item.draggable-list__item-draggable span.remove': {
    float: 'right',
  },
  '& .column-chooser .panel .panel-body .column-chooser-selection-area .column-chooser-selected .draggable-list-view ul.draggable-list li.draggable-list-item .selected-list-item span': {
    M: 'none',
    W: 'none',
    fallbacks: [
      {
        M: 'none',
      },
    ],
    userSelect: 'none',
  },
  '& .column-chooser .panel .panel-body .save-column-view': {
    display: 'inline-block',
  },
  '& .column-chooser .panel .panel-body .save-column-view .save-checkbox': {
    height: '25px',
    verticalAlign: 'middle',
  },
  '& .column-chooser .panel .panel-body .save-column-view .save-checkbox .column-chooser-save-column': {
    fontWeight: 'normal',
  },
  '& .column-chooser .panel .panel-body .save-column-view .save-checkbox input.column-chooser-saved-name': {
    border: '1px solid #BBB',
    padding: '3px 5px',
    fontSize: '12px',
    lineHeight: '1.45833333',
  },
  '& .column-chooser .panel .panel-footer': {
    marginTop: '5px',
  },
  // From @bingads-webui/theme-2016/style/css/bootstrap/less/override/panels.less
  // scoped under .column-chooser
  '& .column-chooser .panel-component': {
    backgroundColor: '#E7F0F6',
  },
  '& .column-chooser .panel-component .panel-heading': {
    backgroundColor: '#E7F0F6',
  },
  '& .column-chooser .panel-component .panel-title': {
    fontColor: '#333',
  },
  '& .column-chooser .panel-component .panel-body': {
    padding: '5px 15px',
  },
  '& .column-chooser .panel-form': {
    backgroundColor: 'white',
    border: '1px solid #BBB',
    padding: '5px',
  },
  '& .column-chooser .panel-form.noPadding': {
    padding: '0',
  },
  '& .column-chooser .panel-form .panel-heading': {
    marginBottom: '0',
  },
  '& .column-chooser .panel-form .section': {
    padding: '0 0 15px 0',
  },
  '& .column-chooser .panel-form .panel-body.noPadding': {
    padding: '0',
  },
  '& .column-chooser .panel-form-footer': {
    padding: '3px 0',
  },
  '& .column-chooser .panel.panel-white': {
    backgroundColor: 'white',
    W: 'none',
    boxShadow: 'none',
  },
  '& .column-chooser .panel-body': {
    padding: '0 15px 0 15px',
  },
  '& .column-chooser .panel-body:before, & .column-chooser .panel-body:after': {
    content: '" "',
    display: 'table',
  },
  '& .column-chooser .panel-body:after': {
    clear: 'both',
  },
  '& .column-chooser .panel-heading': {
    backgroundColor: '#E5E5E5',
    padding: '0 15px',
    marginBottom: '15px',
  },
  '& .column-chooser .panel-heading>.dropdown .dropdown-toggle': {
    color: 'inherit',
  },
  '& .column-chooser .panel-title': {
    fontWeight: 'bold',
    marginTop: '0',
    marginBottom: '0',
    lineHeight: '30px',
    fontSize: 14,
    display: 'inline',
  },
  '& .column-chooser .panel-title>a': {
    color: 'inherit',
  },
  '& .column-chooser .panel-footer': {
    padding: '0 15px 0 15px',
    borderTop: '0px',
  },
  '& .column-chooser hr': {
    borderTop: '1px solid #A2A2A2',
  },
  '& .column-chooser .save-column-checkbox': {
    float: 'left',
  },
};
