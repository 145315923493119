/* eslint-disable max-len */
import _ from 'underscore';
import { jss } from 'react-jss';
import { components, names } from './components';

const calcQueue = [
  names.header,
  names.panelContainer,
  names.topBanner,
  names.promotionBanner,
  names.nav,
  names.status,
  names.menu,
  names.tab,
  names.subTab,
  names.content,
];

const getSelection = config => _.mapObject(config, ({ mode, ...advancedOptions }, key) => {
  let result = _.result(components[key], mode, {});

  // i.e. advancedOptions = { showExperimentScoreCard: true }
  _.each(advancedOptions, (advancedOptionValue, advancedOptionName) => {
    const advancedProcessor = components[key][advancedOptionName];
    if (advancedOptionValue && _.isFunction(advancedProcessor)) {
      result = advancedProcessor(result);
    }
  });

  return result;
});

export class StylesContainer {
  constructor(config) {
    const selected = getSelection(config);
    this.styles = _.mapObject(selected, (item, key) =>
      jss.createStyleSheet({ [key]: style => style }, { link: true }).attach());
    this.update(config);
  }

  update(config) {
    const selected = getSelection(config);
    const startPos = { top: 0, left: 0 };

    _.each(calcQueue, (key) => {
      const component = selected[key];
      this.styles[key].update(_.extend(component, startPos));
      startPos.top += _.result(component, 'height', 0);
      startPos.left += _.result(component, 'width', 0);
    });
  }
}
