import React from 'react';
import { StyledErrorPage } from './pages/error-page/error-page';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    // Bind the resetError function to this instance
    this.resetError = this.resetError.bind(this);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    // logErrorToMyService(error, info.componentStack);
  }

  resetError() {
    this.setState({ hasError: false });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <StyledErrorPage resetError={this.resetError} />;
      // return this.props.fallback(this.resetError); // Pass resetError function to fallback
    }

    return React.Children.map(this.props.children, child => {
      // If this child is a react element, clone it and pass the resetError function
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { resetErrorBoundary: this.resetError });
      }
      return child;
    });
  }
}

export default ErrorBoundary;
