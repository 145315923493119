import _ from 'underscore';

/**
 * Base listener class - defines the interface for custom listeners.
 * This can be extended by custom listeners
 */
export class Listener {
  constructor() {
    this.initialized = false;
    this.logs = [];
  }

  filterLogs(logMessage) {
    return _.any(this.filters, filter => filter.check(logMessage));
  }

  formatLogs(logMessage) {
    let formattedMsg = _.clone(logMessage);
    _.each(this.formatters, (formatter) => {
      formattedMsg = formatter.format(formattedMsg);
    });
    return formattedMsg;
  }

  write() {
    throw new Error('Not Implemented');
  }

  teardown() {
    _.noop();
  }
}
