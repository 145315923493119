import _ from 'underscore';

const logKeyPrefix = 'localStorageListener';

/**
 * Retrieves logs stored in local storage using the LocalStorageListener
 * @param {Object} options - options for log retrieval
 * @param {bool} [options.deleteAfterRetrieval = false] - True to delete
 *  the log after it is retrieved
 * @returns {Object[]} - Array of log objects stored in local storage
 */
export function retrieveLocalStorageLogs({ deleteAfterRetrieval = false }) {
  const storage = window.localStorage;

  if (!storage) {
    return [];
  }

  return _.keys(storage).reduce((retrievedLogs, key) => {
    if (key.indexOf(logKeyPrefix) === 0) {
      try {
        const parsedLog = JSON.parse(storage.getItem(key));

        retrievedLogs.push(parsedLog);
        if (deleteAfterRetrieval) {
          storage.removeItem(key);
        }
      } catch (e) {
        console.error(e); // eslint-disable-line no-console
      }
    }
    return retrievedLogs;
  }, []);
}
