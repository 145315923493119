import { getUtcTime } from '@bingads-webui-universal/primitive-utilities';
import { constants } from './../constants';
import { Logger } from './logger';

/**
 * Default Trace Logger
 */
export class TraceLogger extends Logger {
  /**
   * trace - logs passed in args as trace logs
   * @param {object} scenario - scenario of activity that invoked log method
   * @param {object} activity - activity that invoked the log method
   * @param {object} parentScenario - parent of the scenario
   * @param {object} args - args passed in to the trace log method
   * @returns {undefined} - no returns
   */
  trace(scenario, activity, parentScenario, [message, api, requestId, httpMethod, logLevel]) {
    super.log({
      api,
      message,
      requestId,
      httpMethod,
      timeStamp: getUtcTime(),
      logCategory: constants.LOGCATEGORY.TRACE,
      logLevel: logLevel || constants.LOGLEVEL.INFO,
      entryType: constants.ENTRYTYPE.MESSAGE,
      activity,
      scenario,
      parentScenario,
    });
  }

  getLogMethods() {
    return ['trace'];
  }
}
