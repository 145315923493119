/* eslint-disable no-param-reassign */
import { Formatter } from '@bingads-webui/listener';

export class UetTagHelperFormatter extends Formatter {
  format(log) {
    if (log.As === 'campaign' && log.Api.indexOf('campaignext/uettagassistant/') > -1) {
      log.As = 'campaignExt';
      log.Asub = 'UetTagAssistant';
    }
    return log;
  }
}
