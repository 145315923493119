/**
 * CampaignApiErrorMapper - Maps ajax errors from campaign/api to known user errors
 */
export class CampaignApiErrorMapper {
  /**
   * @param {object} errorCodes - campaign api user error codes
   */
  constructor(errorCodes) {
    this.knownUserErrorCodes = errorCodes || [];
  }

  /**
   * Checks if an ajax error is a known user error
   * @param {object} ajaxData - data related to the current ajax call
   * @param {object} jqXHR - jqXHR of the ajax call
   * @returns {bool} - if the error maps to a known error or not
   */
  check(ajaxData, jqXHR) {
    const urlString = ajaxData.url;
    const { responseText } = jqXHR;

    if (!urlString) {
      return false;
    }

    if (urlString.toUpperCase().indexOf('/CAMPAIGN/API/') >= 0) {
      try {
        const errorCode = JSON.parse(responseText).Errors[0].Code;

        if (this.knownUserErrorCodes.indexOf(errorCode) >= 0) {
          return true;
        }
      } catch (e) {
        // ignore any parse exceptions
      }
    }

    return false;
  }
}
