import Promise from 'bluebird';

/**
 * A helper to handle set/get global locale asynchronously.
 * @private
 */
export class LocaleManager {
  constructor() {
    this.p$global = new Promise((resolve) => {
      this.resolveGlobalLocale = resolve;
    });
  }

  /**
   * Get global locale promise.
   * @returns {Promise.<string>} promise of global locale
   * @private
   */
  p$globalLocale() {
    return this.p$global;
  }

  /**
   * Set global locale.
   * @param {string} locale - global locale to set.
   * @returns {Promise.<string>} promise of global locale
   * @private
   */
  setGlobalLocale(locale) {
    if (this.p$global.isFulfilled()) {
      // has been inited
      this.p$global = Promise.resolve(locale);
    } else {
      // first init
      this.resolveGlobalLocale(locale);
    }

    return this.p$globalLocale();
  }
}
