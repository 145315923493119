export const filterEditorStyle = {
  '& .predicate-row': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .filter-editor .panel': {
    backgroundColor: 'white',
    paddingBottom: '15px',
    border: '1px solid transparent',
    borderRadius: 0,
    boxShadow: '0 1px 1px rgba(0,0,0,0.05)',
    marginBottom: '15px',
  },
  '& .filter-body.filter-editor .save-form input[type="checkbox"]': {
    marginRight: '5px',
    marginTop: 0,
  },
  '& .filter-body.filter-editor .save-form .btn + .btn': {
    marginLeft: '10px',
  },
  '& .filter-body.filter-editor .save-form .btn + .checkbox': {
    marginLeft: '15px',
  },
  '& .filter-body.filter-editor .save-form .checkbox label + input': {
    marginLeft: '10px',
  },
  '& .predicate-list li': {
    marginBottom: '5px',
  },
  '& .predicate-list li .error-row': {
    marginTop: '5px',
  },
  '& .predicate-list li .error-row label': {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#a80000',
  },
  '& .predicate-list li .predicate-row .filter-predicate-property': {
    position: 'relative',
    display: 'inline-block',
  },
  '& .predicate-list li .predicate-row .filter-predicate-property > .btn.btn-default': {
    textAlign: 'right',
    minWidth: '205px',
  },
  '& .predicate-list li .predicate-row .filter-predicate-property > .btn.btn-default > .selected': {
    float: 'left',
  },
  '& .predicate-list li .predicate-row .filter-predicate-property + .predicate-operator': {
    marginLeft: '10px',
  },
  '& .predicate-list li .predicate-row .form-control': {
    display: 'inline-block',
  },
  '& .predicate-list li .predicate-row > * + *': {
    marginLeft: '4px',
  },
  '& .predicate-list li .predicate-row .predicate-property': {
    width: '15em',
  },
  '& .predicate-list li .predicate-row .predicate-operator': {
    width: '15em',
  },
  '& .predicate-list li .predicate-row .predicate-operator + .predicate-value': {
    marginLeft: '10px',
    '& .checkbox-inline, & .checkbox-inline + .checkbox-inline, & .radio-inline, & .radio-inline + .radio-inline': {
      marginLeft: 0,
      marginRight: '10px',
    },
    '& .checkbox-inline:last-child, & .radio-inline:last-child': {
      marginRight: 0,
    },
  },
  '& .predicate-list li .predicate-row .predicate-value': {
    display: 'inline-block',
  },
  '& .predicate-list li .predicate-row .predicate-value > input': {
    width: 'inherit',
  },
  '& .predicate-list li .predicate-row .predicate-value + .remove': {
    marginLeft: '16px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
  },
  '& .predicate-list li .predicate-row .predicate-value button.selector-dropdown-button': {
    backgroundColor: '#fff',
    border: '0',
  },
  '& .predicate-list li .predicate-row .predicate-value .selector.dropdown-menu': {
    marginLeft: '-1px',
    border: '1px solid #ccc',
  },
  '& .predicate-list li .predicate-row .single-operator-column, & .predicate-list li .predicate-row .single-property-column': {
    display: 'inline-block',
    marginTop: '4px',
  },
  '& .predicate-list li .predicate-row .dropdown-submenu': {
    minWidth: '150px',
    position: 'relative',
  },
  '& .predicate-list li .predicate-row .dropdown-submenu li': {
    marginBottom: '0',
  },
  '& .predicate-list li .predicate-row .dropdown-submenu > span': {
    display: 'inline-block',
  },
  '& .predicate-list li .predicate-row .dropdown-submenu:hover > .dropdown-menu': {
    display: 'block',
    visibility: 'visible',
  },
  '& .predicate-list li .predicate-row .dropdown-submenu .spritedimage': {
    marginTop: '2px',
  },
  '& .predicate-list li .predicate-row .btn .caret': {
    marginLeft: '5px',
  },
  '& .filter-header': {
    display: 'inline-block',
  },
  '& .filter-header .filter-button': {
    padding: '7px 10px',
    display: 'inline-block',
  },
  '& .filter-header .filter-button:hover': {
    backgroundColor: '#e5e5e5',
    cursor: 'pointer',
  },
  '& .filter-header .glyphicon-filter': {
    marginRight: '6px',
  },
  '& .filter-header .filterMenu': {
    border: '1px solid #CCCCCC',
    minWidth: '250px',
    padding: '2px',
  },
  '& .filter-header .filterMenu .saved': {
    marginBottom: '2px',
  },
  '& .filter-header .filterMenu .new, & .filter-header .filterMenu .apply': {
    display: 'block',
    color: '#404040',
    padding: '4px 0 4px 20px',
  },
  '& .filter-header .filterMenu .new:hover, & .filter-header .filterMenu .apply:hover, & .filter-header .filterMenu .saved>li:hover': {
    textDecoration: 'none',
    backgroundColor: '#EAECEE',
  },
  '& .filter-header .filterMenu .delete': {
    color: '#404040',
    padding: '4px 4px 4px',
    float: 'right',
    clear: 'right',
  },
  '& .filter-header .filterMenu .apply-header': {
    color: '#929fad',
    padding: '6px 0 4px 9px',
  },
  '& .filter-header .quick': {
    minWidth: '151px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#ccc',
    backgroundColor: '#FFFFFF',
    display: 'inline-block',
  },
  '& .filter-header .quick .quick-text': {
    padding: '3px 5px',
    border: 'none',
  },
  '& .filter-header .quick .quick-text:active': {
    border: 'none',
  },
  '& .filter-body .save-form': {
    marginTop: '25px',
  },
  '& .filter-body .save-form h5.checkbox': {
    marginLeft: '15px',
  },
  '& .filter-body .save-form .name': {
    width: '300px',
  },
  '& .grid-toolbar-bar': {
    fontSize: 12,
  },
  '& .predicate-list>ul': {
    marginBottom: '10px',
  },
  '& .predicate-list>ul>li.row': {
    marginBottom: '4px',
  },
  '& .predicate-list>ul>li.row>div': {
    paddingLeft: '2px',
    paddingRight: '2px',
  },
  '& .predicate-list>ul>li.row .operator-single': {
    lineHeight: '25px',
    marginLeft: '10px',
    marginBottom: '0',
  },
  '& .predicate-list>ul>li.row .dropdown .dropdown-control .glyphicon-triangle-bottom': {
    float: 'right',
    marginTop: '1px',
  },
  '& .predicate-list>ul>li.row .dropdown .dropdown-menu': {
    border: '1px solid #BBB',
    padding: '3px 5px',
  },
  '& .predicate-list>ul>li.row span.datepicker': {
    padding: '0',
    margin: '0',
    border: '0',
  },
  '& .predicate-list>ul>li.row ul.enum-options>li': {
    whiteSpace: 'nowrap',
  },
  '& .predicate-list>ul>li.row ul.enum-options>li input[type="checkbox"]': {
    marginTop: '0',
  },
  '& .predicate-list a.remove, & .predicate-list a.new': {
    lineHeight: '20px',
    textDecoration: 'none',
    color: '#323130',
    fontSize: 12,
  },
  '& ol, & ul': {
    listStyle: 'none',
    paddingLeft: 0,
  },
};
