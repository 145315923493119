import { Listener } from '@bingads-webui/listener';
import _ from 'underscore';

/**
 * In memory listener class. This logs to an in-memory object that is passed
 * in. It expects a sink object with write method on it.
 */

export class InMemoryListener extends Listener {
  /**
   * Creates an instance of InMemoryListener.
   * @param {any} [options={}] - The options for in-memory listener
   * @param {Object} options.sink - Sink to push logs to.
   * Should have write method on it
   * @param {Object} options.formatters - Object where values are formatters
   * to use for the listener
   * @param {Object} options.filters - Object where values are filters to
   * use for the listener
   */
  constructor(options = {}) {
    super(options);

    this.formatters = options.formatters || {};
    this.filters = options.filters || {};
    this.sink = options.sink;
  }

  /**
 * Init in memory listener if needed options passed in
 * @returns {undefined} - no returns
 */
  init() {
    if (this.sink && _.isFunction(this.sink.write)) {
      this.initialized = true;
    }
  }

  /**
   * writes logs to the listener
   * @param {object} logMessage - logs to be written
   * @returns {undefined} - no returns
  */
  write(logMessage) {
    if (!this.initialized || this.filterLogs(logMessage)) {
      return;
    }

    const formattedMsg = this.formatLogs(logMessage);

    this.sink.write(formattedMsg);
  }
}

