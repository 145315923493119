import { constants } from '@bingads-webui/instrumentation';

export default {
  logTableNames: {
    [constants.LOGCATEGORY.ERROR]: {
      name: 'clienterr',
      priority: 1,
    },
    [constants.LOGCATEGORY.PERFORMANCE]: {
      name: 'clientperf',
      priority: 2,
    },
    [constants.LOGCATEGORY.TRACE]: {
      name: 'clienttrace',
      priority: 3,
    },
    [constants.LOGCATEGORY.SUBSTRATE]: {
      name: 'substrate1',
      priority: 3,
    },
  },
  defaultLogCategory: constants.LOGCATEGORY.TRACE,
};
