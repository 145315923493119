import { totalRowBackgroundColor, tableBorder } from './variables';

export const getSharedBodyStyles = (components, { palette }) => ({
  '& tr.segment-row': {
    fontSize: '12px',

    // Need this so values in primary key will stay 12px
    '& .segment-value': {
      fontSize: '12px',
    },
  },
  // The total rows at the bottom of the table
  '& tr.aggregate-row': {
    '& td': {
      fontSize: '12px !important',
    },
  },
  '& tr.aggregated-row': {
    '& td': {
      fontSize: '12px !important',
      backgroundColor: totalRowBackgroundColor,
    },
    '&:hover': { // No hover color changes for aggregated rows.
      '& td': {
        fontSize: '12px !important',
        borderRight: tableBorder,
      },
    },
  },
  '& tr': {
    backgroundColor: '#FFFFFF',
    color: palette.neutralPrimary,

    // hide extra border between header and body, the first filler tr in grid need to be excluded
    '&[data-key]:first-child, &:not([data-key]) + tr[data-key]': {
      '& td': {
        borderTop: 'none',
      },
    },

    '&[data-key]:hover, &.row-selected[data-key], &.row-selected[data-key]:hover': {
      '& td': {
        borderBottom: '1px solid #FFFFFF',
      },
    },

    '&:hover': {
      backgroundColor: palette.neutralLighter,
    },

    '&.row-selected': {
      backgroundColor: palette.neutralLight,
      '& td a': {
        color: palette.themeDarker,
        fontWeight: 600,
      },
      '&:hover': {
        backgroundColor: palette.neutralQuaternaryAlt,
      },
    },

    '&:hover, &.row-selected, &.row-selected:hover': {
      '& td': {
        borderRight: '1px solid #FFFFFF',
      },
    },

    '&.grid-error-row': {
      '& td': {
        '& ul.grid-error-list': {
          listStyle: 'none',
          paddingLeft: 0,
          margin: '0.5em 0',
          color: '#A80000',
          '& .grid-error': {
            paddingRight: 5,
          },
        },
      },
    },
  },
});
