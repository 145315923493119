import React from 'react';
import injectSheet, { ThemeProvider } from 'react-jss';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { getDisplayName } from '@bingads-webui-react/hoc-utils';

const emptyTheme = {};

export function withDefaultStyles(WrappedComponent, getStyles = () => ({})) {
  const StyledComponent = injectSheet(({ components = {}, core = {} }) =>
    getStyles(components, core))(WrappedComponent);

  const HOC = props => (
    <ThemeProvider theme={emptyTheme}>
      <StyledComponent {...props} />
    </ThemeProvider>
  );

  HOC.displayName = `withDefaultStyles(${getDisplayName(WrappedComponent)})`;

  hoistNonReactStatics(HOC, WrappedComponent);

  return HOC;
}
