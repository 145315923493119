import _ from 'underscore';
import { PageReady } from '@bingads-webui/page-ready';
import { PerfMarkerFactory } from './index';

let isPageReadyFulfilled = false;

PageReady.afterPageReady().then(() => {
  isPageReadyFulfilled = true;
});

function markTime() {
  return {
    time: performance.now(),
    activeTime: _.result(performance, 'activeTime', NaN),
  };
}

function isMarkerValid({ time, activeTime } = {}) {
  return _.isNumber(time) && time >= 0 &&
    _.isNumber(activeTime) && activeTime >= 0;
}

function getUrl() {
  return (window.location.pathname || '') + (window.location.search || '') + (window.location.hash || '');
}

/**
 * Do not access PerfMarker class directly, use PerfMarker Factory
 */
export class PerfMarker {
  constructor(name, markerOptions, isFileFetched, activity, parentMarker) {
    this.markers = {};
    this.InitUrl = getUrl();
    this.isFileFetched = isFileFetched; // is js file already downloaded or not
    this.name = name;
    this.parentMarker = parentMarker;
    this.hierarchy = this.parentMarker.hierarchy ? `${this.parentMarker.hierarchy}.${this.name}` : this.name;
    this.activity = activity;
    this.isDone = false;
    this.autoEnd = markerOptions.autoEnd;
    this.isActiveChild = markerOptions.isActiveChild;
    this.activeChildren = [];
    this.data = markerOptions.data || {};
    this.doneCallback = null;
  }

  createChild(name, markerOptions = { autoEnd: false, isActiveChild: true }) {
    const childMarker = PerfMarkerFactory.createMarker(name, markerOptions, this.activity, this);

    if (childMarker && childMarker.isActiveChild !== false) {
      this.activeChildren.push(childMarker);
    }

    return childMarker;
  }

  willFetchFile() {
    this.markers.timeFetchFile = markTime();

    this.markConsole('willFetchFile');
  }

  willFetchData() {
    this.markers.timeFetchData = markTime();

    this.markConsole('willFetchData');
  }

  willInit() {
    this.markers.timeInit = markTime();

    this.markConsole('willInit');
  }

  willRender() {
    this.markers.timeRender = markTime();

    this.markConsole('willRender');
  }

  onChildDone(childMarker) {
    this.activeChildren = _.reject(this.activeChildren, activeChild => activeChild === childMarker);

    if (this.autoEnd &&
      !this.isDone &&
      _.isEmpty(this.activeChildren)) {
      this.done();
    }
  }

  done(additionalMessage = {}) {
    this.markers.timeDone = markTime();
    this.markConsole('done');

    this.additionalMessage = additionalMessage;

    // If marker already done, do not log again
    if (this.isDone) {
      return;
    }

    this.send();

    this.isDone = true;

    if (this.doneCallback && _.isFunction(this.doneCallback)) {
      this.doneCallback();
    }

    if (this.parentMarker.onChildDone) {
      this.parentMarker.onChildDone(this);
    }
  }

  markConsole(markerPoint) {
    if (console.timeStamp) {
      console.timeStamp(`PerfMarker ${this.name} ${markerPoint}`);
    }
  }

  send() {
    const message = this.parseMarkers();

    if (_.isEmpty(message)) {
      return;
    }

    this.activity.perf(JSON.stringify(message), this.hierarchy);
  }

  parseMarkers() {
    // Calling willFetchFile is optional. If not called, clear file fetching flag
    if (!isMarkerValid(this.markers.timeFetchFile)) {
      this.isFileFetched = true;
    }

    if (_.some([this.markers.timeInit, this.markers.timeDone], marker => !isMarkerValid(marker))) {
      return null; // init and done markers should be valid
    }

    const doneUrl = getUrl();
    const didUrlChange = this.InitUrl !== doneUrl;

    return {
      Version: 'perf-marker@0.2.0',
      Name: this.name,
      ParentName: this.parentMarker.name,
      Data: this.data,
      IsParentDone: this.parentMarker.isDone,
      IsPageReadyFulfilled: isPageReadyFulfilled,
      IsFileFetched: this.isFileFetched,
      FileFetchStartTime: _.result(this.markers.timeFetchFile, 'time', 0),
      FileFetchActiveStartTime: _.result(this.markers.timeFetchFile, 'activeTime', 0),
      DataFetchStartTime: _.result(this.markers.timeFetchData, 'time', 0),
      DataFetchActiveStartTime: _.result(this.markers.timeFetchData, 'activeTime', 0),
      RenderStartTime: _.result(this.markers.timeRender, 'time', 0),
      RenderActiveStartTime: _.result(this.markers.timeRender, 'activeTime', 0),
      InitStartTime: this.markers.timeInit.time,
      InitActiveStartTime: this.markers.timeInit.activeTime,
      DoneStartTime: this.markers.timeDone.time,
      DoneActiveStartTime: this.markers.timeDone.activeTime,
      DidUrlChange: didUrlChange,
      InitUrl: didUrlChange ? this.InitUrl : '',
      DoneUrl: didUrlChange ? doneUrl : '',
      AdditionalMessage: this.additionalMessage,
    };
  }
}
