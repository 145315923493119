import _ from 'underscore';
import { generateGuid } from '@bingads-webui-universal/primitive-utilities';
import { ActivityFactory } from './activity-factory';

/**
 * Scenario class to create an instance of a Scenario
 */
class Scenario {
  constructor(scenarioSchema, loggers, parentScenario) {
    this.value = scenarioSchema.value;
    this.name = scenarioSchema.name;
    this.id = generateGuid();
    this.setting = scenarioSchema.setting;
    this.loggers = loggers;
    this.parentScenario = parentScenario;

    const activities = scenarioSchema.activity;
    _.each(activities, (activity) => {
      this[(typeof activity === 'string') ? activity : activity.name] = new ActivityFactory(this, activity, loggers, parentScenario);
    });
  }

  createActivity(activityName) {
    return new ActivityFactory(this, activityName, this.loggers, this.parentScenario).create();
  }
}

/**
* ScenarioFactory class responsible for creating Scenario
*/
export class ScenarioFactory {
  /**
   * @param {object} scenarioSchema - schema of scenario to be created
   * @param {object} loggers - available loggers
   */
  constructor(scenarioSchema, loggers) {
    this.scenarioSchema = scenarioSchema;
    this.loggers = loggers;
  }

  create(parentScenario) {
    return new Scenario(this.scenarioSchema, this.loggers, parentScenario);
  }
}
