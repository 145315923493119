import _ from 'underscore';
import { Constants } from '@bingads-webui-theme-2018/theme-fluent';

const { ZIndexShellComponents } = Constants;

const shared = {
  position: 'absolute',
  display: 'block',
};

const hidden = _.defaults({
  display: 'none',
}, shared);

const header = {
  expanded: _.defaults({
    get height() {
      return window.surveyHeight + 60 || 60;
    },
    right: 0,
    zIndex: ZIndexShellComponents.Header,
  }, shared),
  collapsed: _.defaults({
    height: 60,
    right: 0,
    zIndex: ZIndexShellComponents.Header,
  }, shared),
  hidden,
};

const panelContainer = {
  expanded: _.defaults({
    right: 0,
    bottom: 0,
    zIndex: ZIndexShellComponents.Panel,
  }, shared),
  hidden,
  collapsed: hidden,
};

const topBanner = {
  expanded: _.defaults({
    height: 37,
    right: 0,
    display: 'block',
    zIndex: ZIndexShellComponents.TopBanner,
  }, shared),
  hidden,
  collapsed: hidden,
};

const promotionBanner = {
  expanded: _.defaults({
    height: 60,
    right: 0,
    zIndex: ZIndexShellComponents.PromotionBanner,
  }, shared),
  hidden,
  collapsed: hidden,
};

const nav = {
  expanded: _.defaults({
    width: 228,
    bottom: 0,
    zIndex: ZIndexShellComponents.Nav,
  }, shared),
  collapsed: _.defaults({
    width: 0,
    bottom: 0,
    zIndex: ZIndexShellComponents.Nav,
  }, shared),
  hidden,
};

const status = {
  expanded: _.defaults({
    height: 45,
    right: 0,
    zIndex: ZIndexShellComponents.Status,
  }, shared),
  hidden,
};

const menu = {
  expanded: _.defaults({
    width: 164,
    bottom: 0,
    zIndex: ZIndexShellComponents.Menu,
  }, shared),
  hidden,
  hotelCenterMenu: cfg => ({
    ...cfg,
    width: 180,
  }),
};

const tab = {
  optimizationScore: _.defaults({
    height: 139,
    right: 0,
    zIndex: ZIndexShellComponents.Tab,
  }, shared),
  expanded: _.defaults({
    height: 72,
    right: 0,
    zIndex: ZIndexShellComponents.Tab,
  }, shared),
  collapsed: _.defaults({
    height: 58,
    right: 0,
    zIndex: ZIndexShellComponents.Tab,
  }, shared),
  hidden,
  /*
   * advanced processor to handle config like below:
   * {
   *   tab: {
   *     mode: 'collapsed' or 'expanded',
   *     showExperimentScoreCard: true,
   *   },
   * }
   * For more details, see ./styles-container.js
   */
  showExperimentScoreCard: cfg => ({
    ...cfg,
    height: cfg.height + 176,
  }),
};

const subTab = {
  expanded: _.defaults({
    height: 58,
    right: 0,
    zIndex: ZIndexShellComponents.SubTab,
  }, shared),
  hidden,
};

const content = {
  expanded: _.defaults({
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    zIndex: ZIndexShellComponents.Content,
  }, shared),
};

export const components = {
  header,
  panelContainer,
  topBanner,
  promotionBanner,
  nav,
  status,
  menu,
  tab,
  subTab,
  content,
};

export const names = _.mapObject(components, (value, name) => name);
