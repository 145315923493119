import { curlyTemplate } from '@bingads-webui-universal/primitive-utilities';
import { checkKey, safeGetString } from './string-utils';

export class ComponentI18nShim {
  constructor(realI18n) {
    this.realI18n = realI18n;
  }

  getString(key, ...args) {
    if (checkKey(key)) return '';

    let res = safeGetString(this.realI18n, key) || '';

    if (res) {
      // eslint-disable-next-line
      for (const i in args) {
        const regex = new RegExp(`\\{${i}\\}`, 'gi');

        res = res.replace(regex, args[i]);
      }
    }

    return res || '';
  }

  get(key, model /* , options */) {
    if (checkKey(key)) return '';

    let ret = safeGetString(this.realI18n, key);

    if (ret) {
      if (model) {
        const compiled = curlyTemplate(ret);

        ret = compiled(model);
      }
    }

    return ret;
  }
}
