import _ from 'underscore';
import { Filter } from '@bingads-webui/listener';

export class AdInsightOpportunityApiFilter extends Filter {
  check(rawLog) {
    const allowedApiList = [
      'competitionpage',
      'recommendationpage',
      'Recommendations',
      'OverviewOpportunityTile',
      'MCCOverviewOpportunityTile',
      'incontextcard',
      'searchinsight',
    ];
    const logApi = _.result(rawLog, 'api');

    return !(
      rawLog.entryType === 'Message' &&
      _.isObject(rawLog.message) &&
      _.isString(_.chain(rawLog).result('message').result('type').value()) &&
      ((logApi === 'ViewInlineOpportunity' &&
        _.chain(rawLog).result('message').result('id').value() &&
        _.isNumber(_.chain(rawLog).result('message').result('campaignId').value())
      ) || _.contains(allowedApiList, logApi)));
  }
}
