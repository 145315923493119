
const prodEnvs = [
  // CMUI
  'ui.bingads.microsoft.com',
  'ui.beta.bingads.microsoft.com',
  'ui.ads.microsoft.com',
  'ui.beta.ads.microsoft.com',
  // CCUI
  'bingads.microsoft.com',
  'beta.bingads.microsoft.com',
  'ads.microsoft.com',
  'beta.ads.microsoft.com',
  // AdLibrary
  'adlibrary.ads.microsoft.com',
];

export function isTestEnvironment(envToCheck) {
  return prodEnvs.indexOf(envToCheck) === -1;
}
