import _ from 'underscore';
import { getUtcTime, getTimestamp } from '@bingads-webui-universal/primitive-utilities';
import { constants } from './../constants';
import { Logger } from './logger';

/**
 * Default Perf Logger
 */
export class PerfLogger extends Logger {
  /**
   * start - used to measure start of an activity
   * @param {object} scenario - scenario of activity that invoked log method
   * @param {object} activity - activity that invoked the log method
   * @param {object} parentScenario - parent of the scenario
   * @param {object} args - api of activity
   * @returns {undefined} - no returns
   */
  start(scenario, activity, parentScenario, [api]) {
    super.log({
      api,
      isMethodEnter: true,
      timeStamp: getUtcTime(),
      logCategory: constants.LOGCATEGORY.PERFORMANCE,
      logLevel: constants.LOGLEVEL.INFO,
      entryType: constants.ENTRYTYPE.ACTIVITY,
      activity,
      scenario,
      parentScenario,
    });
  }

  /**
   * stop - used to measure stop of an activity
   * @param {object} scenario - scenario of activity that invoked log method
   * @param {object} activity - activity that invoked the log method
   * @param {object} parentScenario - parent of the scenario
   * @param {object} args - result and api of activity
   * @returns {undefined} - no returns
   */
  stop(scenario, activity, parentScenario, [result, api]) {
    super.log({
      api,
      isMethodEnter: false,
      timeStamp: getUtcTime(),
      pass: _.isUndefined(result) ? true : result,
      logCategory: constants.LOGCATEGORY.PERFORMANCE,
      logLevel: constants.LOGLEVEL.INFO,
      entryType: constants.ENTRYTYPE.ACTIVITY,
      activity,
      scenario,
      parentScenario,
    });
  }

  /**
   * monitor - can be used to monitor and measure the duration of a function
   * @param {object} scenario - scenario of activity that invoked log method
   * @param {object} activity - activity that invoked the log method
   * @param {object} parentScenario - parent of the scenario
   * @param {object} args - function that is to be monitored and duration measured
   * @returns {undefined} - no returns
   */
  monitor(scenario, activity, parentScenario, [func, context]) {
    const originalFunc = context[func];

    if (!_.isFunction(originalFunc)) {
      return;
    }

    context[func] = (...args) => { // eslint-disable-line no-param-reassign
      const t0 = getTimestamp();

      const result = originalFunc.apply(context, args);

      const timeTaken = getTimestamp() - t0;

      super.log({
        api: originalFunc.name,
        timeTaken,
        timeStamp: getUtcTime(),
        logCategory: constants.LOGCATEGORY.PERFORMANCE,
        logLevel: constants.LOGLEVEL.INFO,
        entryType: constants.ENTRYTYPE.METHOD,
        activity,
        scenario,
        parentScenario,
      });

      return result;
    };
  }

  /**
   * monitorAsync - can be used to measure the duration of a promise
   * @param {object} scenario - scenario of activity that invoked log method
   * @param {object} activity - activity that invoked the log method
   * @param {object} parentScenario - parent of the scenario
   * @param {object} args - function that is async and returns a promise
   * @returns {undefined} - returns a promise with perf markers
   */
  monitorAsync(scenario, activity, parentScenario, [promise]) {
    const t0 = getTimestamp();

    // log monitor start
    super.log({
      isMethodEnter: true,
      timeStamp: getUtcTime(),
      logCategory: constants.LOGCATEGORY.PERFORMANCE,
      logLevel: constants.LOGLEVEL.INFO,
      entryType: constants.ENTRYTYPE.METHOD,
      activity,
      scenario,
      parentScenario,
    });

    const onPromiseDone = () => {
      const timeTaken = getTimestamp() - t0;

      // log monitor stop
      super.log({
        isMethodEnter: false,
        timeTaken,
        timeStamp: getUtcTime(),
        logCategory: constants.LOGCATEGORY.PERFORMANCE,
        logLevel: constants.LOGLEVEL.INFO,
        entryType: constants.ENTRYTYPE.METHOD,
        activity,
        scenario,
        parentScenario,
      });
    };

    promise.then(onPromiseDone, onPromiseDone);
    return promise;
  }

  /**
   * perf - logs passed in args as perf logs
   * @param {object} scenario - scenario of activity that invoked log method
   * @param {object} activity - activity that invoked the log method
   * @param {object} parentScenario - parent of the scenario
   * @param {object} args - args passed in to the perf log method
   * @returns {undefined} - no returns
   */
  perf(scenario, activity, parentScenario, [message, api]) {
    super.log({
      api,
      message,
      timeStamp: getUtcTime(),
      logCategory: constants.LOGCATEGORY.PERFORMANCE,
      logLevel: constants.LOGLEVEL.INFO,
      entryType: constants.ENTRYTYPE.MESSAGE,
      activity,
      scenario,
      parentScenario,
    });
  }

  getLogMethods() {
    return ['start', 'stop', 'monitor', 'monitorAsync', 'perf'];
  }
}
