import _ from 'underscore';
import core from '@bingads-webui-universal/i18n-cldr-data-modern/core.json';
import enLocale from '@bingads-webui-universal/i18n-cldr-data-modern/locales/en.json';
import timeZoneData from 'iana-tz-data/iana-tz-data.json';
import { I18nModel } from '@bingads-webui-universal/i18n-model';
import { curlyTemplate } from '@bingads-webui-universal/primitive-utilities';

I18nModel.setGlobalLocale('en-US');
I18nModel.loadCLDRData(core);
I18nModel.loadCLDRData(enLocale);
I18nModel.loadTimeZoneData(timeZoneData);

const staticI18nModelMock = new I18nModel({
  loadCLDRData: loc => loc,
  loadTranslationData: () => ({}),
  failFast: true,
});

staticI18nModelMock.ready();
staticI18nModelMock.getString = (key, model, { preprocess = _.identity } = {}) => {
  let ret = key;
  if (ret) {
    ret = preprocess(ret);

    if (model) {
      const compiled = curlyTemplate(ret);

      ret = compiled(model);
    }
  }

  return ret;
};

export { staticI18nModelMock };
