import _ from 'underscore';
import { AWTLogManager, AWT_NEAR_REAL_TIME, AWTSessionState } from '@aria/webjs-sdk';
import { Listener } from '@bingads-webui/listener';
import { defaultConfig } from './default-config';

const activeListeners = [];
let flushEventAttached = false;
function registerListener(listener) {
  activeListeners.push(listener);

  if (!flushEventAttached) {
    window.addEventListener('beforeunload', () => {
      activeListeners.forEach(l => l.flushLogs(false));
      AWTLogManager.flush();
    });

    flushEventAttached = true;
  }
}

/**
 * Create an instance of an Aria listener
 */
export class AriaListener extends Listener {
  constructor(options = {}) {
    super(options);
    this.options = _.defaults(options, {
      flushInterval: defaultConfig.flushInterval,
      batchSize: defaultConfig.batchSize,
      enableSessionTracking: defaultConfig.enableSessionTracking,
    });
    this.formatters = _.defaults(this.options.formatters || {}, defaultConfig.formatters);
    this.filters = this.options.filters || [];
    this.logTableNames = this.options.logTableNames;
    this.defaultLogCategory = this.options.defaultLogCategory;
  }

  /**
   * init aria listener
   * @returns {undefined} - no returns
   */
  init() {
    if (!this.options.tenantToken || this.initialized) {
      return;
    }

    this.logs = [];

    // Setup AriaTelemetry
    AWTLogManager.initialize(this.options.tenantToken);
    AWTLogManager.setTransmitProfile(AWT_NEAR_REAL_TIME);

    this.defaultLogger = AWTLogManager.getLogger(this.options.tenantToken);

    if (this.options.enableSessionTracking) {
      this.defaultLogger.logSession(AWTSessionState.Started);
    }

    if (!AWTLogManager.trackedListenerRefs) {
      AWTLogManager.trackedListenerRefs = { count: 0 };
    }

    setInterval(() => this.flushBatch(), this.options.flushInterval);
    registerListener(this);
    this.initialized = true;
  }

  /**
   * register instance for final flush of logs to Aria
   * @private
   * @returns {undefined} - no returns
   */
  registerForTeardown() {
    if (this.registeredForTeardown || !this.initialized) {
      return;
    }

    this.registeredForTeardown = true;
    AWTLogManager.trackedListenerRefs.count += 1;
  }

  /**
   * flushes logs to Aria and tears down the logger
   * @returns {undefined} - no returns
   */
  teardown() {
    if (!this.initialized) {
      return;
    }
    this.flushAll(false);
    this.initialized = false;

    if (this.options.enableSessionTracking) {
      this.defaultLogger.logSession(AWTSessionState.Ended);
    }

    if (this.registeredForTeardown) {
      AWTLogManager.trackedListenerRefs.count -= 1;
    }

    this.release();
  }

  /**
  * flushes logs to aria telemetry logger
  * @param {bool} batchLogs - specify if the logs should be flushed in batches
  * @private
  * @returns {undefined} - no returns
   */
  flushLogs(batchLogs) {
    const useBatching = batchLogs && (this.logs.length > this.options.batchSize);
    const batchSize = useBatching ? this.options.batchSize : this.logs.length;
    const logsToFlush = this.logs.splice(0, batchSize);
    _.each(logsToFlush, (log) => {
      this.defaultLogger.logEvent(log);
    });
  }

  /**
   * If there are no active listeners then tear down the underlying logger.
   * @private
   * @returns {undefined} - no returns
   */
  release() {
    if (AWTLogManager.trackedListenerRefs.count === 0) {
      AWTLogManager.flushAndTeardown();
    }
  }

  /**
   * writes logs to the listener
   * @param {object} logMessage - logs to be written
   * @returns {undefined} - no returns
   */
  write(logMessage) {
    if (!this.initialized || this.filterLogs(logMessage)) {
      return;
    }

    const categoryName = this.getCategoryName(logMessage.logCategory);
    const formattedMsg = this.formatLogs(logMessage);

    this.logs.push({
      name: this.logTableNames[categoryName].name,
      properties: formattedMsg,
    });
  }

  /**
   * @private
   * @returns {undefined} - no returns
   */
  flushAll() {
    this.flushLogs(false);
  }

  /**
   * @private
   * @returns {undefined} - no returns
   */
  flushBatch() {
    this.flushLogs(true);
  }

  /**
   * @private
   * @param {object} logCategory - logs to be written
   * @returns {undefined} - no returns
   */
  getCategoryName(logCategory) {
    const isValidLogCategory = _.has(this.logTableNames, logCategory);
    return isValidLogCategory ? logCategory : this.defaultLogCategory;
  }
}
