import _ from 'underscore';

const map = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_+';

/**
 * translate int to base-64 representation
 * @param {Number} integer None negative integer to translate
 * @returns {String} encoded base-64 string
 */
export function toStringBase64(integer: number): string {
  if (!_.isNumber(integer) || integer % 1 !== 0 || integer < 0) {
    throw new Error('unknown data type, none negative integer only');
  }

  if (integer === 0) {
    return map[0];
  }

  let result = '';
  let dividend = integer;

  while (dividend > 0) {
    const remainder = dividend % 64;

    result = map[remainder].concat(result);
    dividend = Math.floor(dividend / 64);
  }

  return result;
}

/**
 *
 * @param {String} code, string output of toStringBase64
 * @returns {Number} the original integer number
 */
export function parseIntBase64(code: string): number {
  let result = 0;
  let remain = code.slice();

  while (remain.length > 0) {
    result = (result * 64) + map.indexOf(remain[0]);
    remain = remain.slice(1);
  }

  return result;
}
