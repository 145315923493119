(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("underscore"), require("urijs"), require("jquery"));
	else if(typeof define === 'function' && define.amd)
		define(["underscore", "urijs", "jquery"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("underscore"), require("urijs"), require("jquery")) : factory(root["underscore"], root["urijs"], root["jquery"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__99__) {
return 