export const getBlockerStyle = (size, palette) => ({
  width: size,
  height: size,
  border: '2px solid',
  borderLeftColor: palette.themePrimary,
  borderRightColor: palette.themeLight,
  borderTopColor: palette.themeLight,
  borderBottomColor: palette.themeLight,
  borderRadius: '50%',
  animation: 'spinner 1s linear infinite',
  marginRight: 9,
});

export const spinnerKeyframe = {
  '@keyframes spinner': {
    to: { transform: 'rotate(360deg)' },
  },
};
